<template>
    <label :for="id">
        <input

                type="checkbox"

                :id="id"
                :name="name"
                :checked="shouldBeChecked"
                :value="value"
                @change="updateInput"
        >
        {{ label }}
        <i class="glyphicon glyphicon-asterisk required-icon" v-if="required">

        </i>
    </label>
</template>
<script>
    const uniq = require('uniqid')
    import _ from 'lodash'
    export default {
        model: {
            prop: 'modelValue',
            event: 'change'
        },
        props: {
            value: {
            },
            id: {
                default: () => uniq()
            },
            modelValue: {
                default: false
            },
            required: {
                default: false
            },
            name: {
                default: ''
            },
            label: {
                type: String,
            },
            indexKey: {
                default: null
            },
            // We set `true-value` and `false-value` to the default true and false so
            // we can always use them instead of checking whether or not they are set.
            // Also can use camelCase here, but hyphen-separating the attribute name
            // when using the component will still work
            trueValue: {
                default: true
            },
            falseValue: {
                default: false
            }
        },
        computed: {
            shouldBeChecked() {
                const value = this.getValue()

                if (this.modelValue instanceof Array) {


                    if (this.indexKey) {
                        return _.get(value, this.indexKey) == _.get(this.value, this.indexKey)
                    }

                    return this.modelValue.includes(this.value)
                }
                // Note that `true-value` and `false-value` are camelCase in the JS
                return value == this.trueValue
            }
        },
        methods: {
            updateInput(event) {
                let isChecked = event.target.checked

                if (this.modelValue instanceof Array) {
                    let newValue = [...this.modelValue]

                    if (isChecked) {
                        newValue.push(this.value)
                    } else {
                        let item = this.getValue()

                        newValue.splice(newValue.indexOf(item), 1)
                    }

                    this.$emit('change', newValue)
                } else {
                    this.$emit('change', isChecked ? this.trueValue : this.falseValue)
                }
            },

            getValue() {
                let item = this.value

                if (this.indexKey) {
                    let value = {};
                    value[this.indexKey] = _.get(this.value, this.indexKey)
                    item = _.find(this.modelValue, value)
                }

                return item
            }

        }
    }
</script>
