var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "pull-left" }, [
        _c("span", { staticStyle: { "line-height": "46px" } }, [
          _vm._v(
            "\n                Exibindo " +
              _vm._s(_vm.start) +
              " Até " +
              _vm._s(_vm.end) +
              " (Total: " +
              _vm._s(_vm.pagination.total) +
              ")\n            "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pull-right" }, [
        _c(
          "ul",
          { staticClass: "pagination" },
          [
            _vm.pagination.current_page > 1
              ? _c("li", { staticClass: "page-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.goTo(_vm.pagination.current_page - 1)
                        }
                      }
                    },
                    [_vm._v("Anterior")]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.displayedPages, function(i, k) {
              return _c(
                "li",
                {
                  key: k,
                  class: {
                    active: _vm.pagination.current_page == i
                  }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.goTo(i)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(i) +
                          "\n                    "
                      )
                    ]
                  )
                ]
              )
            }),
            _vm._v(" "),
            _vm.pagination.current_page < _vm.pagination.total_pages
              ? _c("li", { staticClass: "page-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.goTo(_vm.pagination.current_page + 1)
                        }
                      }
                    },
                    [_vm._v("Proxima")]
                  )
                ])
              : _vm._e()
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }