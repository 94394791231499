<template>
  <div class="form-group">
    <label :for="id" v-if="label !== false" class="control-label">{{label}}</label>
    <i class="glyphicon glyphicon-asterisk required-icon" v-if="required"></i>
    <div :class="{
            'input-group': inputGroup || icon
            }">
      <span v-if="icon" class="input-group-addon">
        <i :class="iconClass"></i>
      </span>
      <input
        class="form-control"
        ref="element"
        :id="id"
        :name="name"
        :value="value"
        :placeholder="placeholder"
        :required="required"
        :disabled="disabled"
        :readonly="readonly"
        @keyup.enter="$emit('submit')"
        @keyup="updateValue($event.target.value)"
        v-currency="{
          currency: 'BRL',
          autoDecimalMode:true
        }"
      />
      <slot></slot>
    </div>
    <small class="form-text text-muted" v-if="hint">{{hint}}</small>
  </div>
</template>

<script>
import { CurrencyDirective } from "vue-currency-input";
const uniq = require("uniqid");
export default {
  name: "UIMoney",

  directives: {
    currency: CurrencyDirective
  },
  props: {
    name: {
      default: ""
    },
    value: {
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ""
    },
    readonly: {
      default: false
    },
    id: {
      default: () => uniq()
    },
    label: {
      default: ""
    },
    disabled: {
      default: false
    },
    icon: {
      default: false
    },
    inputGroup: {
      default: false
    },
    mask: {
      default: false
    },
    placeholder: {
      default: ""
    }
  },
  computed: {
    iconClass() {
      if (this.icon === false) {
        return {};
      }

      let val = {};
      val[this.icon] = true;
      return val;
    }
  },
  methods: {
    updateValue(val) {
      this.$emit("input", val);
    }
  }
};
</script>