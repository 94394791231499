<template>
    <div class="form-group">
        <label
                v-if="label"
                :for="id"
        >{{label}}
        </label>
        <date-picker
                :id="id"
                :name="name"
                lang="pt-br"
                :value="properValue"
                @change="updateValue"
                :format="format"
                :disabled="disabled"
                :type="type"
                :time-picker-options="timeTicker"
                style="width: 100%"
                language="pt-br">

        </date-picker>
        <small class="form-text text-muted" v-if="hint">{{hint}}</small>
    </div>
</template>

<script>
    import DatePicker from 'vue2-datepicker'
    const uniqid = require('uniqid')
    const moment = require('moment-timezone')


    export default {
        name: "date-input",

        props: {
            value: {
                default: () => Date.now()
            },
            label: {
                default:""
            },
            hint: {
                default: ""
            },
            format: {
                default: 'dd/MM/yyyy'
            },
            placeholder: {
                default: ""
            },
            name: {
                default: ""
            },
            type: {
                default: 'date'
            },
            disabled: {
              default: false
            },
            id: {
                default: () => uniqid()
            }
        },
        data ()  {
            return {
                properValue : this.getValue(),
                timeTicker: this.useTime ? {start: '00:00',step: '00:30',end: '23:30'} : {}
            }
        },

        methods: {

            getValue() {
                if (typeof(this.value) === 'object') {

                    if (this.value !== null && this.value.date !== 'undefined') {
                        this.updateValue(moment(this.value.date))
                        return moment(this.value.date).toISOString()
                    }

                    return this.value
                }
                this.updateValue(moment(this.value))
                return moment(this.value).toISOString()
            },
            updateValue(date) {

                this.properValue = date

                let value = null

                if (date) {
                    value = moment(date.toISOString()).format('YYYY-MM-DD HH:mm:ss');
                }
                this.$emit('input', value)
            }

        },

        components: { DatePicker },
    }
</script>

<style>
    .form-control[readonly].date {
        background-color: #fff;
        cursor: pointer;
    }

    .vdp-datepicker__clear-button {
        position: absolute;
        top: 10px;
        left: 100px;
    }
</style>
