<script>
import { mapGetters, mapActions } from "vuex";
const $ = require("jquery");
require("bootstrap");

export default {
  name: "navigation",
  mounted() {},
  data() {
    return {
      ui: {
        search: {
          text: null
        }
      }
    };
  },

  computed: {
    ...mapGetters(["USER"])
  },

  methods: {
    ...mapActions(["DO_LOGOUT"]),

    search(event) {
      event.preventDefault();
      this.request()
        .get("filtrando", `/clients`, {
          query: this.ui.search,
          page: 0,
          limit: 50
        })
        .then(response => {
          if (response.data.length === 1) {
            this.$router.push({
              name: "clients.edit.index",
              params: { id: response.data[0].id },
              force: true
            });
            return;
          }

          this.$router.push({
            path: "/clients",
            query: { text: this.ui.search.text },
            force: true
          });
        });
    },

    logout() {
      this.$router.push({ name: "login" });
      this.DO_LOGOUT();
    }
  }
};
</script>
<template>
  <div class="page-header" style="margin: 0">
    <!-- BEGIN HEADER -->

    <div class="page-header-top">
      <div class="container-fluid">
        <div class="page-logo">
          <router-link :to="{name: 'dashboard'}">
            <img src="@assets/imgs/logo.png" alt="logo" class="logo-default" />
          </router-link>
        </div>

        <a href="javascript:;" class="menu-toggler"></a>

        <div class="top-menu">
          <ul class="nav navbar-nav pull-right">
            <li class="dropdown dropdown-user dropdown-dark">
              <a
                href="javascript:;"
                class="dropdown-toggle"
                data-toggle="dropdown"
                data-hover="dropdown"
                data-close-others="true"
              >
                <img alt class="img-circle" src="@assets/imgs/avatar.jpg" />
                <span class="username username-hide-mobile">Olá {{USER.name}}</span>
              </a>
              <ul class="dropdown-menu dropdown-menu-default">
                <li>
                  <router-link :to="{name: 'users.edit', params: { id: USER.id} }">
                    <i class="icon-user"></i> Meu Perfil
                  </router-link>
                </li>
                <li class="divider"></li>
                <li>
                  <a @click.prevent="logout">
                    <i class="icon-key"></i> Sair
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="page-header-menu">
      <div class="container-fluid">
        <div class="hor-menu">
          <div class="btn-group"></div>
          <ul class="nav navbar-nav dropdown">
            <li>
              <router-link :to="{name: 'dashboard'}">
                <i class="icon-home"></i>
              </router-link>
            </li>
            <li class="menu-dropdown classic-menu-dropdown" v-can="'view-client'">
              <a
                data-hover="megamenu-dropdown"
                data-close-others="true"
                data-toggle="dropdown"
                href="javascript:;"
              >
                <i class="fa fa-users" />
                Clientes
                <i class="fa fa-angle-down"></i>
              </a>
              <ul class="dropdown-menu pull-left">
                <li v-can="'view-client'">
                  <router-link :to="{name: 'clients.index'}">
                    <i class="fa fa-search"></i> Buscar
                  </router-link>
                </li>
                <li v-can="'create-client'">
                  <router-link :to="{name: 'clients.create'}">
                    <i class="fa fa-plus"></i> Cadastrar
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="menu-dropdown classic-menu-dropdown" v-can="'view-user'">
              <a
                data-hover="megamenu-dropdown"
                data-close-others="true"
                data-toggle="dropdown"
                href="javascript:;"
              >
                <i class="fa fa-user" />
                Usuários
                <i class="fa fa-angle-down"></i>
              </a>
              <ul class="dropdown-menu pull-left">
                <li v-can="'view-client'">
                  <router-link :to="{name: 'users.index'}">
                    <i class="fa fa-search"></i> Buscar
                  </router-link>
                </li>
                <li v-can="'view-client'">
                  <router-link :to="{name: 'users.create'}">
                    <i class="fa fa-plus"></i> Cadastrar
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="menu-dropdown classic-menu-dropdown" v-can="'manage-trigger'">
              <a
                data-hover="megamenu-dropdown"
                data-close-others="true"
                data-toggle="dropdown"
                href="javascript:;"
              >
                <i class="fa fa-random" />
                Triggers
                <i class="fa fa-angle-down"></i>
              </a>
              <ul class="dropdown-menu pull-left">
                <li v-can="'view-client'">
                  <router-link :to="{name: 'triggers.index'}">
                    <i class="fa fa-search"></i> Buscar
                  </router-link>
                </li>
                <li v-can="'create-client'">
                  <router-link :to="{name: 'triggers.create'}">
                    <i class="fa fa-plus"></i> Cadastrar
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="menu-dropdown classic-menu-dropdown">
              <a target="_blank" href="https://msicentral.freshdesk.com/a/">
                <i class="fa fa-headphones"></i>
                Freshdesk
              </a>
            </li>
            <li v-if="true === false" class="menu-dropdown mega-menu-dropdown">
              <a
                data-hover="megamenu-dropdown"
                data-close-others="true"
                data-toggle="dropdown"
                href="javascript:;"
                class="dropdown-toggle"
              >
                Ferramentas
                <i class="fa fa-angle-down"></i>
              </a>
              <ul class="dropdown-menu" style="min-width: 500px">
                <li>
                  <div class="mega-menu-content">
                    <div class="row">
                      <div class="col-md-6">
                        <ul class="mega-menu-submenu">
                          <li>
                            <h3>CEP</h3>
                          </li>
                          <li class>
                            <a href="(barra)(barra)route('cep.search')}}">
                              <i class="fa fa-map-marker"></i> Buscar/Adicionar Bairros
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <event class="to-right"></event>

        <div class="to-right">
          <form @submit="search">
            <ui-text
              :label="false"
              class="nav-center"
              placeholder="Filtre por: Nome ou Código"
              v-model="ui.search.text"
            ></ui-text>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.to-right {
  float: right;
}
.nav-center {
  margin-top: 10px;
}
</style>