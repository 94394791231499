import { render, staticRenderFns } from "./Notes.vue?vue&type=template&id=73555c04&"
import script from "./Notes.vue?vue&type=script&lang=js&"
export * from "./Notes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/buddy/central-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('73555c04')) {
      api.createRecord('73555c04', component.options)
    } else {
      api.reload('73555c04', component.options)
    }
    module.hot.accept("./Notes.vue?vue&type=template&id=73555c04&", function () {
      api.rerender('73555c04', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/routes/clients/component/_partials/Notes.vue"
export default component.exports