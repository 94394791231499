var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", [
    _vm.has_custom_message
      ? _c("span", [_vm._v(_vm._s(_vm.field.message(_vm.condition)))])
      : _vm._e(),
    _vm._v(" "),
    _vm.field && _vm.type
      ? _c("span", [
          _vm._v(
            " Quando o campo " +
              _vm._s(_vm.field.name) +
              " for " +
              _vm._s(_vm.type.name) +
              " que " +
              _vm._s(_vm.condition.value) +
              " " +
              _vm._s(_vm.condition.secondary) +
              " "
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }