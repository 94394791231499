var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-12" },
      [
        _c("h2", [_vm._v(_vm._s(_vm.plan.name))]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _vm._l(_vm.plan.configurations, function(item, key) {
          return _c(
            "div",
            { key: key, staticClass: "row" },
            [
              _c("div", { staticClass: "col-md-12" }, [
                _c("h4", [
                  _vm._v("Configurações: " + _vm._s(item.product_name))
                ])
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(_vm.requireComponent(item), {
                tag: "component",
                attrs: { "client-product": item }
              })
            ],
            1
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }