<template>
    <ui-loading :is-loading="ui.loading">

        <div class="portlet light">
            <div class="portlet-title">
                <div class="caption caption-md">
                    <i class="icon-bar-chart theme-font hide"></i>
                    <span class="caption-subject theme-font bold uppercase" v-if="trigger.id"><i
                            class="icon-directions"></i>Atualizando o Trigger</span>
                    <span class="caption-subject theme-font bold uppercase" v-else><i class="icon-directions"></i>Cadastrando o Trigger</span>
                </div>
            </div>

            <div class="portlet-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="col-md-8">
                            <form ref="form">
                                <ui-text v-model="trigger.name" label="Nome do Trigger" name="name"></ui-text>
                                <ui-select
                                        :options="events"
                                        label="Origem"
                                        :full-value="true"
                                        v-model="trigger.origin"
                                >
                                </ui-select>
                                <conditions @change="updateConditions" :conditions="trigger.conditions"
                                            :fields="fields"></conditions>
                                <div>

                                    <ui-select
                                            label="Ação"
                                            v-model="trigger.action"
                                            :options="actions"
                                            :disabled="actions.length"
                                    >
                                    </ui-select>

                                    <div v-if="trigger.action">
                                        <component :trigger="trigger" @input="i => trigger.parameters = i"
                                                   :is="trigger.action"></component>
                                    </div>

                                </div>
                                <div class="pull-right">
                                    <button type="button" @click="save" class="btn btn-primary">Salvar</button>
                                </div>
                            </form>
                        </div>

                        <div class="col-md-4">
                            <div v-if="trigger.origin">
                                <h1>{{trigger.origin.name}}</h1>
                                <p>
                                    {{trigger.origin.description}}
                                </p>
                            </div>
                            <h4 v-if="trigger.conditions.length">Condições</h4>

                            <ul v-for="condition in trigger.conditions">
                                <show-condition :trigger="trigger" :condition="condition"></show-condition>
                            </ul>
                            <h4 v-if="trigger.action">Irá {{displayAction}}</h4>
                            <ul v-for="(item, key) in trigger.parameters">
                                <li><strong>{{key}}</strong> {{item}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </ui-loading>
</template>

<script>
    import actionsComponents from './actions'
    import fields from './fields'
    import types from './types'
    import Conditions from './_partials/Conditions.vue';
    import ShowCondition from './_partials/ShowCondition.vue';

    export default {
        name: "Editor",

        components: {
            'conditions': Conditions,
            'showCondition': ShowCondition,
            ...actionsComponents
        },

        mounted() {
            this.$watch('trigger.origin', (value) => {
                this.actions = value.actions
                this.fields = fields[value.id]
                this.trigger.event_name = value.id
            })

            this.load()

        },

        methods: {

            updateConditions(conditions) {
                this.trigger.conditions = conditions
            },

            load() {
                this.ui.loading = true
                this.loadOptions()
                    .then(() => {
                        if (this.$route.params.id) {
                            this.fetchTrigger(this.$route.params.id)
                        }
                    })
                    .then(this.ui.loading = false)
            },

            loadOptions() {
                return this.request().get('Carregando lista de eventos', 'triggers/events').then(response => {
                    this.events = response.data
                })
            },

            fetchTrigger(id) {
                this.request().get('Carregando a Trigger', `triggers/${id}`).then(response => {
                    this.loadTrigger(response.data)
                })
            },


            loadTrigger(trigger) {
                this.trigger = trigger

                const origin = this.events.find(i => i.id === trigger.event_name);

                this.trigger.origin = origin
                this.actions = origin.actions
                this.trigger.action = trigger.action
            },

            save() {

                if (this.trigger.origin.id) {
                    this.trigger.event_name = this.trigger.origin.id
                }
                let request = null

                if (this.trigger.id) {
                    request = this.request().block(this.$refs.form).put('Atualizando o gatilho', `triggers/${this.trigger.id}`, this.trigger)
                } else {
                    request = this.request().block(this.$refs.form).post('Salvando o gatilho', 'triggers', this.trigger)
                }

                request.then(response => {
                    this.loadTrigger(response.data)
                })
            }
        },

        computed: {

            displayAction() {
                let item = this.actions.find(i => i.id === this.trigger.action)

                if (!item) {
                    return null
                }

                return item.name
            }
        },

        data() {
            return {
                ui: {
                    loading: true
                },
                events: [],
                fields: [],
                actions: [],
                trigger: {
                    origin: null,
                    action: null,
                    parameters: {},
                    conditions: [],
                }
            }
        }
    }
</script>