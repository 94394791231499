var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "table",
      { ref: "table", staticClass: "table table-striped table-hover" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.tickets, function(ticket) {
            return _c("tr", [
              _c("td", [_vm._v(_vm._s(ticket.id))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(ticket.requester.name))]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  "\n                " +
                    _vm._s(ticket.agent.name) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("td", [
                ticket.type
                  ? _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(ticket.type) +
                          "\n                "
                      )
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n                    Tipo não definido\n                "
                      )
                    ])
              ]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(ticket.statusInfo.name))]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  "\n                " +
                    _vm._s(ticket.priorityInfo.name) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(ticket.subject))]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm._f("date")(ticket.created_at)) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  "\n\n                " +
                    _vm._s(_vm._f("date")(ticket.first_response_due)) +
                    "\n                "
                ),
                _c("i", {
                  staticClass: "fa",
                  class: _vm.classesStatus(ticket),
                  attrs: { title: _vm.responseStatus(ticket) }
                })
              ]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm._f("date")(ticket.due_by)))]),
              _vm._v(" "),
              _c("th", [
                _c(
                  "a",
                  {
                    attrs: { target: "_blank", href: ticket.freshdesk_details }
                  },
                  [
                    _c("i", { staticClass: "fa fa-external-link" }),
                    _vm._v(" Detalhes\n            ")
                  ]
                )
              ])
            ])
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Id")]),
        _vm._v(" "),
        _c("th", [_vm._v("Solicitante")]),
        _vm._v(" "),
        _c("th", [_vm._v("Analista")]),
        _vm._v(" "),
        _c("th", [_vm._v("Tipo")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("Prioridade")]),
        _vm._v(" "),
        _c("th", [_vm._v("Título")]),
        _vm._v(" "),
        _c("th", [_vm._v("Data de criação")]),
        _vm._v(" "),
        _c("th", [_vm._v("SLA - 1º Resposta")]),
        _vm._v(" "),
        _c("th", [_vm._v("SLA - Resolução")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }