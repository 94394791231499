import Editor from './component/Editor.vue';
import List from './component/List.vue';
import User from './User.vue';

export default {
  path: "/users",
  component: User,

  children: [
    {
      path: "/",
      component: List,
      name: "users.index"
    },
    {
      path: "create",
      component: Editor,
      name: "users.create"
    },
    {
      path: ":id",
      component: Editor,
      name: "users.edit"
    }
  ]
};
