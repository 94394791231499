var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: _vm.envClass }, [
      _vm.LOGGED
        ? _c(
            "div",
            [
              _c("navigation"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container-fluid" },
                [
                  _c("ul", { staticClass: "page-breadcrumb breadcrumb" }),
                  _vm._v(" "),
                  _c("router-view")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col-md-12",
                  staticStyle: {
                    position: "fixed",
                    bottom: "15px",
                    right: "15px"
                  }
                },
                _vm._l(_vm.LOADING_MESSAGES, function(message) {
                  return _c(
                    "button",
                    {
                      staticClass: "btn btn-primary pull-right",
                      staticStyle: { "margin-left": "5px" }
                    },
                    [_vm._v(_vm._s(message.message) + "...")]
                  )
                }),
                0
              )
            ],
            1
          )
        : _c("div", [_c("router-view")], 1)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }