var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ul",
        _vm._l(_vm.fields, function(item) {
          return _c("li", [
            _vm._v(
              "\n            Para " +
                _vm._s(item.name) +
                " use " +
                _vm._s(item.alias) +
                "\n        "
            )
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("ui-select", {
        attrs: { options: _vm.typesWarnings, label: "Tipo do da Mensagem" },
        model: {
          value: _vm.trigger.parameters.type,
          callback: function($$v) {
            _vm.$set(_vm.trigger.parameters, "type", $$v)
          },
          expression: "trigger.parameters.type"
        }
      }),
      _vm._v(" "),
      _c("ui-check", {
        attrs: {
          label: "Alerta deverá ser global?",
          value: _vm.trigger.parameters.alert_global
        },
        model: {
          value: _vm.trigger.parameters.alert_global,
          callback: function($$v) {
            _vm.$set(_vm.trigger.parameters, "alert_global", $$v)
          },
          expression: "trigger.parameters.alert_global"
        }
      }),
      _vm._v(" "),
      _c("ui-textarea", {
        attrs: { label: "Mensagem a ser enviada" },
        on: {
          input: function(v) {
            return _vm.$emit("input", { message: v })
          }
        },
        model: {
          value: _vm.trigger.parameters.message,
          callback: function($$v) {
            _vm.$set(_vm.trigger.parameters, "message", $$v)
          },
          expression: "trigger.parameters.message"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }