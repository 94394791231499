import Request from '@/lib/requester'
import store from '@/store';
export default {
    methods: {
        request: () => {
            return new Request(store)
        },

    }
}
