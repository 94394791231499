import Vue from 'vue'
const moment = require('moment')

Vue.filter('date', function (value, format = 'DD/MM/YYYY') {

    if (!value) {
        return null;
    }

    if (value.date !== undefined) {
        return moment(value.date).format(format)
    }
    return moment(value).format(format);
})