import Requester from '@/lib/requester'

const requester = new Requester

export const state = {
    user: {
        name: null,
        id: null
    },
    token: localStorage.getItem('user.token'),
    remember_me: localStorage.getItem('user.remember_me'),
    expiration: localStorage.getItem('user.expiration')
}

export const getters = {
    USER: (state) => state.user,
    TOKEN: (state) => state.token,
    REMEMBER_ME: (state) => state.remember_me,
    LOGGED: (state) => state.user !== null,
    EXPIRATION: (state) => state.expiration
}

export const actions = {

    SET_USER: (context, user) => {
        context.commit('SET_USER', user)
    },

    DO_LOGIN: (context, login) => {
        return requester.post('Autenticando', `users/authenticate`, login)
            .then(response => {
                context.commit('SET_USER', response.data)
                return response
            })
    },

    DO_LOGOUT: (context) => {
        return context.commit('UNSET_USER')
    }
}

export const mutations = {

    SET_USER: (state, user) => {

        state.user = user
        state.token = user.token
        localStorage.setItem('user.token', state.token)

        state.remember_me = user.remember_me
        localStorage.setItem('user.remember_me', state.remember_me)

        state.expiration = user.expiration
        localStorage.setItem('user.expiration', state.expiration)

        window.App.token = state.token
        window.user = user
    },

    UNSET_USER: (state) => {
        state.user = null
        state.token = null
        state.remember_me = null

        localStorage.removeItem('user.token')
        localStorage.removeItem('user.remember_me')

        window.App.token = null
        window.user = null
    }
}