<template>
  <div>
    <ui-modal @close="$emit('close')">
      <div slot="header">Editar Zonas Dns</div>
      <div slot="body">
        <div id="hosted_zones" ref="domainForm" @submit.prevent="addDomain">
          <form id="domain-form" method="post">
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label for="domain" class="control-label">
                    Domínio
                    <small>(Ex: http://dominio.com.br)</small>
                  </label>
                  <input
                    type="text"
                    v-model="domain.url"
                    name="domain"
                    class="form-control"
                    id="domain"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for>&nbsp;</label>
                    <br />
                    <a href="/" class="btn btn-default" @click.prevent="addDomain">Adicionar</a>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="row">
            <div class="col-md-12">
              <h2>Lista de Dominios</h2>
              <div class="alert alert-warning">
                <p>
                  Os DNS's listados abaixo são os valores que devem ser informados ao gerenciador de
                  domínios. (Exemplo
                  <a
                    target="_blank"
                    href="http://registro.br"
                  >registro.br</a>).
                </p>
              </div>
            </div>
            <div class="col-md-5">
              <ui-text v-model="search.domain" placeholder="Filtrar"></ui-text>
            </div>
            <div class="col-md-12">
              <ui-loading :is-loading="ui.loading">
                <ui-pagination :pagination="pagination" @paginate="paginate"></ui-pagination>
                <table class="table-hover table-bordered table table-responsive">
                  <thead>
                    <tr>
                      <th>Domínio</th>
                      <th>Zona MX</th>
                      <th>DNS</th>
                      <th>I.P</th>
                      <th>Principal</th>
                      <th>Remover</th>
                    </tr>
                  </thead>
                  <tbody ref="toBlock" v-if="domains">
                    <tr
                      v-for="(domain, key) in domains"
                      :class="{'success': domain.main}"
                      :key="key"
                    >
                      <td>
                        {{domain.domain}}
                        <a :href="domain.domain" target="_blank">
                          <i class="fa fa-external-link-square"></i>
                        </a>
                      </td>
                      <td>
                        <set-mx-zone
                          :options="options.productOptions"
                          :domain="domain"
                          :blocked="blocked"
                          :client-product-id="clientProductId"
                          @updated="doSearch"
                        ></set-mx-zone>
                      </td>

                      <td>
                        <ul>
                          <li v-for="(dns, index) in getNs(domain)" :key="index">{{dns}}</li>
                        </ul>
                      </td>
                      <td>
                        {{domain.ip}}
                        <i
                          class="fa"
                          :class="{
                                                'fa-check text-success': domain.with_us,
                                                'fa-times text-danger': !domain.with_us
                                            }"
                        ></i>
                      </td>
                      <td>
                        <span v-if="domain.main">Domínio principal</span>
                        <button v-else class="btn btn-info" @click.prevent="setMain(domain)">
                          Tornar
                          Principal
                        </button>
                      </td>
                      <td>
                        <button
                          @click.prevent="openDomainremovalModal(domain)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>Remover
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="4" class="text-center">
                        <span>Nenhum dominio encontrado.</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ui-pagination :pagination="pagination" @paginate="doSearch"></ui-pagination>
              </ui-loading>
            </div>
          </div>
        </div>
      </div>
    </ui-modal>
    <ui-modal v-if="ui.showRemovalDomainModal" @close="closeDomainremovalModal">
      <div slot="header">
        <h4>Deseja realmente remover {{current.domain}}</h4>
      </div>

      <div slot="body">
        <p>Ao remover o domínio os DNS's apontados serão perdidos e não poderão ser recuperados.</p>
      </div>

      <div slot="footer">
        <button class="btn btn-default" @click.prevent="closeDomainremovalModal">Cancelar</button>
        <button class="btn btn-danger" @click.prevent="doRemoveDomain">
          <i class="fa fa-trash"></i> Remover
        </button>
      </div>
    </ui-modal>
  </div>
</template>

<script>
import SetMxZone from "./SetMxZone.vue";

export default {
  name: "DomainModal",
  data() {
    return {
      domain: {
        url: null
      },
      search: {
        domain: null
      },

      domains: [],

      current: null,
      ui: {
        domains: {},
        domain: {
          search: null,
          pagination: null
        },
        showRemovalDomainModal: false,
        dnsLoading: true,
        loading: false,
        page: 1
      },
      pagination: {
        per_page: 5,
        current_page: 1
      }
    };
  },

  mounted() {
    this.$watch(
      "search.domain",
      _.debounce(val => {
        this.doSearch();
        return val;
      }, 1000)
    );

    this.doSearch();
  },

  props: {
    options: {
      required: true
    },

    clientProductId: {
      type: Number,
      required: true
    },
    blocked: {}
  },

  components: {
    SetMxZone
  },

  methods: {
    changedMainDomain(domain) {
      let temp = {};

      _.map(this.domains, item => {
        item.main = item.id === domain.id;
        temp[item.id] = item;
      });

      this.$nextTick(() => {
        _.map(this.ui.domains, item => {
          item.main = item.id === domain.id;
        });
        this.$emit("change", domain.domain);
      });
    },
    openDomainremovalModal(domain) {
      this.current = domain;
      this.ui.showRemovalDomainModal = true;
    },
    closeDomainremovalModal() {
      this.ui.showRemovalDomainModal = false;
      this.current = null;
    },
    doRemoveDomain(domain) {
      this.request()
        .block(this.$refs.toBlock)
        .del(
          "Deletando domínio",
          `/client-products/${this.clientProductId}/domains/${this.current.id}`
        )
        .then(() => this.doSearch())
        .then(() => this.closeDomainremovalModal());
    },
    paginate(page) {
      this.ui.page = page;
      this.doSearch();
    },

    addDomain() {
      let domain = this.domain.url;

      if (domain.indexOf("http://") !== 0 && domain.indexOf("https://") !== 0) {
        domain = "http://" + domain;
      }

      this.request()
        .block(this.$refs.toBlock)

        .post(
          "Salvando Dominio",
          `/client-products/${this.clientProductId}/domains`,
          {
            domain: domain
          }
        )
        .then(response => {
          let domain = response.data;

          this.domains[domain.id] = domain;

          if (_.size(this.domains) === 1) {
            this.changedMainDomain(domain);
          }

          this.doSearch();
        });
    },
    getNs(domain) {
      let ns = _.filter(domain.entries, { type: "NS" });
      if (!ns.length) {
        return [];
      }
      console.log(ns[0].value.split(","));
      return ns[0].value.split(",");
    },

    setMain(domain) {
      this.request()
        .block(this.$refs.domainForm)
        .post(
          "Setando domínio principal",
          `client-products/${this.clientProductId}/domains/set-main`,
          {
            domain_id: domain.id
          }
        )
        .then(() => this.doSearch());
    },

    doSearch() {
      this.ui.loading = true;
      let query = {};

      _.forEach(this.search, (v, i) => {
        if (v !== null && v !== "") {
          query[i] = v;
        }
      });
      let toSend = {
        page: this.ui.page,
        per_page: this.pagination.per_page,
        query: query
      };

      this.request()
        .block(this.$refs.toBlock)
        .get(
          "Carregando Dominios",
          `client-products/${this.clientProductId}/domains`,
          toSend
        )
        .then(response => {
          this.domains = response.data;
          this.pagination = response.meta.pagination;
          this.ui.loading = false;
        })
        .then(() => (this.ui.dnsLoading = false));
    }
  }
};
</script>

<style scoped>
</style>