var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ui-select", {
            attrs: {
              options: _vm.options.payment_status,
              keyName: "value",
              label: "Status das cobranças"
            },
            model: {
              value: _vm.ui.filters.payment_status,
              callback: function($$v) {
                _vm.$set(_vm.ui.filters, "payment_status", $$v)
              },
              expression: "ui.filters.payment_status"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-1 vertical-checkbox" },
        [
          _c("ui-check", {
            attrs: { label: "Em atraso" },
            model: {
              value: _vm.ui.filters.in_arrears,
              callback: function($$v) {
                _vm.$set(_vm.ui.filters, "in_arrears", $$v)
              },
              expression: "ui.filters.in_arrears"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-2 vertical-checkbox" }, [
        _c("button", { staticClass: "btn blue", on: { click: _vm.search } }, [
          _vm._v("Filtrar")
        ])
      ])
    ]),
    _vm._v(" "),
    _c("table", { ref: "table", staticClass: "table" }, [
      _vm._m(0),
      _vm._v(" "),
      _vm.ui.items.length
        ? _c(
            "tbody",
            _vm._l(_vm.ui.items, function(item) {
              return _c(
                "tr",
                {
                  class: {
                    strikeout: item.payment_status === 2
                  }
                },
                [
                  _c("td", [
                    item.payed
                      ? _c("span", [
                          _vm._v(_vm._s(_vm._f("money")(item.payed)))
                        ])
                      : _c("span", [_vm._v(" - ")])
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm._f("money")(item.value)))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm._f("date")(item.due_to)))]),
                  _vm._v(" "),
                  item.payment_date
                    ? _c("td", [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm._f("date")(item.payment_date)) +
                            "\n                "
                        ),
                        item.days_past <= 0 && item.days_past !== null
                          ? _c(
                              "i",
                              { staticClass: "fa fa-check text-success" },
                              [_vm._v("Dentro do prazo")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.days_past > 0 && item.days_past !== null
                          ? _c(
                              "i",
                              { staticClass: "fa fa-check text-danger" },
                              [_vm._v("Atraso")]
                            )
                          : _vm._e()
                      ])
                    : _c("td", [_vm._v("  - ")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n\n                " +
                        _vm._s(item.payment_status_info.name) +
                        "\n\n                "
                    ),
                    _c("i", {
                      staticClass: "fa ",
                      class: {
                        "fa-clock-o text-warning": item.payment_status === 0,
                        "fa-check text-success": item.payment_status === 1,
                        "text-danger": item.in_arrears
                      }
                    }),
                    _vm._v(" "),
                    item.in_arrears
                      ? _c("label", { staticClass: "label label-danger" }, [
                          _vm._v("Em atraso")
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    item.payment_status === 0
                      ? _c(
                          "a",
                          { attrs: { href: item.link_2via, target: "_blank" } },
                          [_vm._v("2º Via")]
                        )
                      : _c(
                          "a",
                          {
                            staticStyle: { "text-decoration": "line-through" }
                          },
                          [_vm._v("2º Via")]
                        )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _c("a", { attrs: { href: "" } }, [
                      _c("i", {
                        staticClass: "fa fa-envelope",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.sendEmail(item.id)
                          }
                        }
                      })
                    ])
                  ])
                ]
              )
            }),
            0
          )
        : _c("tbody", [_vm._m(1)])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Valor Pago")]),
        _vm._v(" "),
        _c("th", [_vm._v("Valor")]),
        _vm._v(" "),
        _c("th", [_vm._v("Data de Vencimento")]),
        _vm._v(" "),
        _c("th", [_vm._v("Data do Pagamento")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("2º Via")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "150px" } }, [_vm._v("Enviar por E-Mail")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "4" } }, [
        _c("h2", [_vm._v("Nenhum resultado encontrado!")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }