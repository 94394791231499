import vuex from 'vuex'
import Requester from '../../lib/requester'
import toaster from '../../lib/toastr'
import _ from 'lodash'
const requester = new Requester

export const state = {
    events: [],
}

export const getters = {
    EVENTS: (state) => state.events
}

export const mutations = {
    ADD_EVENT(state, event) {
        state.events.unshift(event)
    },

    REMOVE_EVENT(state, id) {
        const toRemove = state.loadings.filter(i => i.id === id)
        if (toRemove.length > 0) {
            state.loadings.$remove(toRemove[0])
        }
    }
}

export const actions = {
    ADD_EVENT(context, event) {

        if (event === undefined) {
            return
        }

        if (event.type === undefined) {
            event.type = 'info'
        }

        context.commit('ADD_EVENT', event)
        toaster.showToastr(event.type, event.description, event.title)
    },

    REMOVE_EVENT(context, loadId) {
        context.commit("REMOVE_EVENT", loadId)
    },

    ADD_EVENTS(context, events) {
        _.forEach(events, item => context.commit('ADD_EVENT', item))
    }
}
