<template>
    <div class="row">
        <div class="col-md-12">
            <div class="portlet light ">
                <div class="portlet-body">
                    <component :is="'dashboard' + USER.dashboard_id"></component>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapGetters, mapActions} from 'vuex'

    import dashboards from './dashboards'

    export default {
        components: {
            ...dashboards
        },

        computed: {
            ...mapGetters(['USER'])
        }
    }
</script>