<template>
    <div class="form-group">
        <label v-if="label.length"
               :for="id"
               class="control-label"
        >{{label}}
            <i class="glyphicon glyphicon-asterisk required-icon" v-if="required"></i>
        </label>

        <select
                :required="required"
                :id="id"
                :name="name"
                class="form-control"
                @change="itemSelected($event.target.value)"
        >
            <option :value="null">{{placeholder}}</option>
            <option v-for="(item, key) in options"
                    :value="getKey(item, key)"
                    :selected="isSelected(value, item, key)"
                    :disabled="canSelect(item)"> {{item[labelName]}}
            </option>
        </select>
    </div>
</template>

<script>
    const uniqid = require('uniqid')
    export default {
        name: "select-input",
        props: {

            options: {
                default: () => []
            },
            label: {
                default: ''
            },
            keyName: {
                default: 'id'
            },

            labelName: {
                default: 'name'
            },

            required: {
                default: false
            },

            value: {
                default: null
            },

            id: {
                default: () => uniqid()
            },

            name: {
                default: ''
            },

            placeholder: {
                default: 'Selecione'
            },

            optionIsDisabled: {
                default: '',
            },
            fullValue: {
                default: false
            }

        },
        methods: {
            itemSelected(value) {
                if (this.fullValue) {
                    const  element = this.options.find( (i, key) => this.getKey(i, key) === value)
                    this.$emit('input', element)

                    return
                }
                this.$emit('input', value);
                this.$emit('change', value);
            },

            getKey(item, key) {
                return item[this.keyInUse(key)]
            },

            keyInUse(key) {
                return this.keyName === null ? key : this.keyName
            },

            _isSelectable() {
                return false
            },

            isSelected(value, item, key) {
                let keyInUse = this.keyInUse(key)

                let tempVal = value && value[keyInUse] !== undefined ? value[keyInUse] : value
                let current = this.getKey(item, key)
                return tempVal == current
            },

            canSelect(item) {
                if (typeof(this.optionIsDisabled) == "function") {
                    return this.optionIsDisabled(item)
                }

                return false
            }


        }
    }
</script>

<style scoped>

</style>