var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-6" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ui-select", {
            attrs: {
              label: "Periodicidade",
              options: _vm.ui.periodicity,
              placeholder: "Periodicidade",
              name: "status"
            },
            model: {
              value: _vm.current.periodicity,
              callback: function($$v) {
                _vm.$set(_vm.current, "periodicity", $$v)
              },
              expression: "current.periodicity"
            }
          }),
          _vm._v(" "),
          _c("ui-money", {
            attrs: { label: "Valor total" },
            model: {
              value: _vm.current.value,
              callback: function($$v) {
                _vm.$set(_vm.current, "value", $$v)
              },
              expression: "current.value"
            }
          }),
          _vm._v(" "),
          _c("ui-text", {
            attrs: { type: "number", label: "Número de parcelas" },
            model: {
              value: _vm.current.times,
              callback: function($$v) {
                _vm.$set(_vm.current, "times", $$v)
              },
              expression: "current.times"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v("\n        Recorrencia:\n        "),
          this.current.periodicity > 0
            ? _c("span", [_vm._v(_vm._s(_vm._f("money")(this.recurrency)))])
            : _c("span", [_vm._v("Não é uma recorrência")])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v("\n        ID:\n        "),
          _c("span", [_vm._v(_vm._s(this.current.id))])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "pull-right" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c(
            "button",
            { staticClass: "btn btn-info", on: { click: _vm.update } },
            [_vm._v("Salvar")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }