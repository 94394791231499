<template>
    <div class="login">
        <div class="logo">
            <a href="index.html">
                <img src="@assets/imgs/login-logo.png" alt=""/>
            </a>
        </div>

        <div class="content">

            <form method="POST" @submit.prevent="doLogin" class="login-form">

                <div class="form-title">
                    <span class="form-title">Bem vindo.</span>
                    <span class="form-subtitle">Por favor faça seu login.</span>
                </div>

                <div v-if="ui.message.text" class="alert display-hide"
                     :class='{
                            "alert-danger" : ui.message.type == "error",
                            "alert-success" : ui.message.type == "success"
                        }' style="display: block;">
                    <button class="close" data-close="alert"></button>
                    <span>{{ui.message.text}}</span>
                </div>

                <div class="alert alert-danger display-hide">
                    <button class="close" @click.prevent="ui.message.text = null"></button>
                    <span>Insira seu usuário e senha. </span>
                </div>
                <ui-text @submit="doLogin" v-model="login.email" placeholder="E-mail"></ui-text>
                <ui-text @submit="doLogin" type="password" v-model="login.password" placeholder="Senha"></ui-text>

                <div class="form-actions">
                    <button type="submit" class="btn btn-primary btn-block uppercase">Acessar</button>
                </div>
                <div>
                    <div class="pull-left">
                        <ui-check label="Lembrar-me" v-model="login.remember_me"></ui-check>
                    </div>
                    <div class="pull-right forget-password-block">
                        <a @click.prevent='recovery' class="forget-password">Esqueceu sua senha?</a>
                    </div>
                </div>
            </form>

        </div>
    </div>
</template>

<script>

    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: "Login",

        data() {
            return {
                ui: {
                    message: {
                        text: null,
                        type: null
                    }
                },
                login: {
                    email: null,
                    password: null,
                    remember_me: false
                }
            }
        },


        computed: {
            ...mapGetters([
                'USER',
                'LOGGED'
            ])
        },

        methods: {
            ...mapActions([
                'DO_LOGIN',
                'SET_USER',
            ]),

            doLogin() {
                this.request().post('Autenticando', `users/authenticate`, this.login)
                    .then(response => {
                        this.SET_USER(response.data)
                        this.$router.push({name: 'dashboard'})
                        return response
                    })
                    .catch(response => {
                        if (response.hasOwnProperty('data')) {
                            this.ui.message = response.data
                        }
                    })
            },

            recovery() {
                this.request().post('Recuperar senha', `users/recovery_password`, this.login)
                    .then(response => {
                        this.ui.message = response.data
                    })
            }
        }

    }
</script>

<style scoped>
    .pull-left > label {
        color: #ADD1B8 !important;
        margin-top: 8px;
    }
</style>