<template>
    <ui-loading :is-loading="ui.loading">
        <div>
            <div class="row">
                <div class="col-md-12">
                    <div class="title">
                        <h3>Dados gerais</h3>
                    </div>
                    <div class="col-md-3">
                        <span>Total Clients Ativos: <b>{{ui.active_clients}}</b></span>
                    </div>
                    <div class="col-md-3">
                        <span>Ticket Médio: <b>{{ui.medium_ticket | money}}</b></span>
                    </div>
                    <div class="col-md-3">
                        <span>Churning: <b>{{ui.churning}}%</b></span>
                    </div>
                    <div class="col-md-3">
                        <span>Inadimplência: <b>{{ui.late_payers}}%</b></span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="title">
                        <h3>Dados do mês</h3>
                    </div>
                    <div class="col-md-3">
                        <span>Ativados:
                            <b>
                            <router-link :to="{name: 'clients.index', query: {status_classification: 1}}">
                                    {{ui.activated}}
                                </router-link>
                            </b>
                        </span>
                    </div>
                    <div class="col-md-3">
                        <span>Resgatados:
                            <b>
                                <router-link :to="{name: 'clients.index', query: {status_classification: 2}}">
                                    {{ui.rescued}}
                                </router-link>

                            </b>
                        </span>
                    </div>
                    <div class="col-md-3">
                        <span>Cancelados:
                            <b>
                                <router-link :to="{name: 'clients.index', query: {status_classification: 3}}">
                                    {{ui.canceled}}
                                </router-link>

                            </b>
                        </span>
                    </div>
                    <div class="col-md-3">
                        <span>
                            <b>
                                <a href="https://desciclopedia.org/wiki/42" target="_blank" id="id42">42</a>
                            </b>
                        </span>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="title">
                        <h3>Últimos trials cadastrados</h3>
                    </div>
                    <table class="table" ref="listTrials" style="border-top: none">
                        <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nome</th>
                            <th>Telefone</th>
                            <th>E-mail</th>
                            <th>Data Entrada</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in ui.trials">
                            <td>
                                {{item.code}}
                                <a :href="`http://${item.code}.imob.online`" target="_blank">
                                    <i class="fa fa-external-link"/>
                                </a>
                            </td>
                            <td>{{item.name}}</td>
                            <td>{{item.phone1}}</td>
                            <td>{{item.email}}</td>
                            <td>{{item.created_at  | date}}</td>
                            <td>
                                <router-link :to="{name: 'clients.edit.index', params: {id: item.id}}">
                                    Mais detalhes
                                </router-link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </ui-loading>
</template>
<style scoped>
    .title {
        margin-top: 5px;
        border-bottom: 1px solid #365F42;
        margin-bottom: 10px
    }

    span {
        font-size: 14px;
    }

    #id42 {
        color: black;
    }

    #id42:hover {
        text-decoration: none;
        cursor: text;
    }
</style>
<script>
    export default  {
        name: 'DefaultDashboard',
        data() {
            return {
                ui: {
                    loading: true,
                    trials: [],
                    active_clients: 0,
                    medium_ticket: 0,
                    churning: 0,
                    activated: 0,
                    rescued: 0,
                    canceled: 0,
                    late_payers: 0
                }
            }
        },
        mounted() {
            this.request().get("Carregando informações", '/dashboard').then( r => {

                this.ui.churning = r.data.churning;
                this.ui.active_clients = r.data.totalClients;
                this.ui.medium_ticket = r.data.mmr;
                this.ui.late_payers = r.data.latePayers;
                this.ui.rescued = r.data.rescued;
                this.ui.activated = r.data.activated;
                this.ui.canceled = r.data.canceled;
            })
                .then(this.getTrials)
                .then(() => {
                    this.ui.loading = false;
                })

        },

        methods: {
            getTrials() {
                return this.request().get("Carregando algo", "/clients", {
                    query: {
                        is_trial: true
                    },
                    'order': 'created_at',
                    limit: 15,
                    'direction': 'desc',
                }).then(result => this.ui.trials = result.data)
            }
        }

    }
</script>