import Requester from "@/lib/requester";
import store from "@/store";
import Vue from "vue";
import Router from "vue-router";

import App from "./App.vue";
import Client from "./clients";
import Dashboards from "./index/index";
import Login from "./login";
import Trigger from "./triggers";
import Users from "./users";

Vue.use(Router);
console.log(process.env.env);
let router = new Router({
  mode: process.env.env === "production" ? "history" : "hash",
  routes: [
    {
      path: "/",
      component: App,
      children: [Dashboards, Users, Trigger, Client]
    },
    Login
  ]
});

router.beforeEach((to, from, next) => {
  let user = store.getters.USER;
  if (user.id && to.name !== "login") {
    next();
    return;
  }

  let token = store.getters.REMEMBER_ME;

  if (token && to.name !== "login") {
    let requester = new Requester(store);
    requester
      .post("Recuperando dados do usuário", `users/token`, {
        token: store.getters.REMEMBER_ME
      })
      .then(response => {
        store.commit("SET_USER", response.data);
        next();
      })
      .catch(() => {
        next("login");
      });
    return;
  }

  if (to.meta.auth_required === false) {
    next();
    return;
  }

  next("login");
});

export default router;
