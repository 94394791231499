<template>
    <div class="login">
        <div class="logo">
            <a href="index.html">
                <img src="/assets/imgs/login-logo.png" alt=""/>
            </a>
        </div>

        <div class="content">

            <form method="POST" ref="recovery_form" @submit.prevent="recovery" class="login-form">
                <div class="form-title">
                    <span class="form-title">Alterando a senha.</span>
                </div>
                <div v-if="ui.message.text" class="alert display-hide"
                     :class='{
                            "alert-danger" : ui.message.type == "fail",
                            "alert-success" : ui.message.type == "success"
                        }' style="display: block;">
                    <button class="close" data-close="alert"></button>
                    <span>{{ui.message.text}}</span>
                </div>

                <ui-text type="password" name="password" v-model="login.password" placeholder="Senha"></ui-text>
                <ui-text type="password" name='password_confirmation' v-model="login.password_confirmation"
                         placeholder="Senha"></ui-text>

                <div class="form-actions">
                    <button type="submit" class="btn btn-primary btn-block uppercase">Alterar Senha</button>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
    import {mapActions} from 'vuex'

    export default {
        name: "Recovery",
        data() {
            return {
                ui: {
                    message: {
                        text: null,
                        type: null
                    }
                },
                login: {
                    password: null,
                    password_confirmation: null
                }
            }
        },
        methods: {
            ...mapActions(['SET_USER']),
            recovery() {
                this.request().block(this.$refs.recovery_form).put('Alterando password', `users/recovery_password/${this.$route.params.token}`, this.login)
                    .then(response => {
                        this.SET_USER(response.data)
                            .then(() => this.$router.push({name:'dashboard'}))
                    })
                    .catch(response => {
                        //this.ui.message = response.data
                    })
            }
        }
    }
</script>

<style scoped>

</style>