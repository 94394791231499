<template>
    <div class="pull-right">
        <div class="hor-menu ">
            <ul class="nav navbar-nav">
                <li>
                    <a href="" style="position: relative" @click.prevent="ui.showEvents = ! ui.showEvents">
                        <i style="font-size: 25px" class="fa fa-comments"></i>
                    </a>
                    <div class="teste" v-if="ui.showEvents">

                        <div class="portlet light">
                            <div class="portlet-title">
                                <h1 style="color: #000;">Últimos eventos</h1>
                            </div>
                            <div class="portlet-body">
                                <ul class="list-group" style="height: 600px">
                                    <li class="list-group-item" v-for="event in EVENTS"

                                        :class="{
                                        'list-group-item-danger' : event.type === 'error',
                                        'list-group-item-success' : event.type === 'success',
                                       }"
                                        style="color: #000;"
                                    >
                                        <div>
                                            <span>{{event.title}}</span>
                                            <p>
                                                {{event.description}}
                                            </p>
                                            <small>
                                                <i class="fa fa-clock-o"></i>
                                                {{event.created | datetime}}
                                            </small>
                                            <br/>
                                            <a>Marcar como lida</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </li>
            </ul>
        </div>

    </div>

</template>

<script type='js'>

    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: "events",

        data() {
            return {
                ui: {
                    showEvents: false,
                }
            }
        },

        computed: {
            ...mapGetters([
                'EVENTS',
            ])
        },

        methods: {
            ...mapActions(['ADD_EVENTS']),
        },

        mounted() {
            this.request().get('Carregando Eventos', `/events`)
                .then(response => {

                    this.ADD_EVENTS(response.data);
                })
        }
    }
</script>

<style scoped>
    .teste {
        position: absolute;
        z-index: 999;
        right: 0;
        width: 300px;
        overflow-y: scroll;
    }
</style>