var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isLoading
      ? _c("div", { staticClass: "portlet light" }, [_vm._m(0)])
      : _c("div", [_vm._t("default")], 2)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "portlet-body" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12 text-center" }, [
          _c("h1", [_vm._v("Carregando...")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }