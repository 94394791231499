var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "min-height": "400px" } },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "pull-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.showCreateNotes()
                  }
                }
              },
              [_vm._v("\n                    Adicionar Nota\n                ")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("form", { staticClass: "form", attrs: { action: "" } }, [
        _c("p", [_vm._v("FILTRAR POR:")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-2" },
            [
              _c("ui-select", {
                attrs: {
                  placeholder: "Departamento",
                  options: _vm.ui.options.departments
                },
                model: {
                  value: _vm.ui.search.department_id,
                  callback: function($$v) {
                    _vm.$set(_vm.ui.search, "department_id", $$v)
                  },
                  expression: "ui.search.department_id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-2" },
            [
              _c("ui-select", {
                attrs: {
                  placeholder: "Usuário",
                  options: _vm.ui.options.users
                },
                model: {
                  value: _vm.ui.search.user_id,
                  callback: function($$v) {
                    _vm.$set(_vm.ui.search, "user_id", $$v)
                  },
                  expression: "ui.search.user_id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-2" },
            [
              _c("ui-select", {
                attrs: {
                  placeholder: "Atividade",
                  options: _vm.ui.options.activities
                },
                model: {
                  value: _vm.ui.search.activity_id,
                  callback: function($$v) {
                    _vm.$set(_vm.ui.search, "activity_id", $$v)
                  },
                  expression: "ui.search.activity_id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-2" },
            [
              _c(
                "ui-date",
                {
                  attrs: { placeholder: "Data Inicial" },
                  model: {
                    value: _vm.ui.search.created_at_gt,
                    callback: function($$v) {
                      _vm.$set(_vm.ui.search, "created_at_gt", $$v)
                    },
                    expression: "ui.search.created_at_gt"
                  }
                },
                [_c("option", [_vm._v("Data Inicial")])]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-2" },
            [
              _c(
                "ui-date",
                {
                  attrs: { placeholder: "Data Final" },
                  model: {
                    value: _vm.ui.search.created_at_lt,
                    callback: function($$v) {
                      _vm.$set(_vm.ui.search, "created_at_lt", $$v)
                    },
                    expression: "ui.search.created_at_lt"
                  }
                },
                [_c("option", [_vm._v("Data Final")])]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-1" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button" },
                on: { click: _vm.loadNotes }
              },
              [_vm._v("\n                    Buscar\n                ")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { ref: "notesList" }, [
        _vm.notes.length > 0
          ? _c(
              "div",
              _vm._l(_vm.notes, function(item) {
                return _c(
                  "div",
                  {
                    staticClass: "col-md-12 well",
                    staticStyle: { float: "none" }
                  },
                  [
                    _c("div", { staticClass: "pull-right" }, [
                      item.can_edit
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-default",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.showEditNote(item)
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-pencil" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item.can_delete
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-danger",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.showDeleteNote(item)
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-trash" })]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { attrs: { href: "#" } }, [
                      _c("h4", { staticStyle: { "margin-bottom": "0px" } }, [
                        _c("strong", [
                          _vm._v(
                            "[ " +
                              _vm._s(item.activity.name) +
                              " ] - " +
                              _vm._s(item.title) +
                              " "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("small", { staticClass: "text" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "void;" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [
                            _c("b", [
                              _vm._v(_vm._s(item.department.name) + " ")
                            ]),
                            _vm._v(
                              " -\n                            " +
                                _vm._s(item.user.name)
                            )
                          ]
                        ),
                        _vm._v(
                          "\n                        -\n                        "
                        ),
                        _c("strong", [_vm._v("Salvo:")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("date")(item.created_at)) +
                            " -\n                        "
                        ),
                        _c("strong", [_vm._v("Data de atividade:")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("date")(item.due_to)) +
                            "\n                        "
                        ),
                        item.type
                          ? _c("span", [
                              _c("strong", [_vm._v("Tipo:")]),
                              _vm._v(" " + _vm._s(item.type.name))
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("p", {
                        staticStyle: { "margin-top": "20px" },
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$options.filters.nl2br(item.description)
                          )
                        }
                      })
                    ])
                  ]
                )
              }),
              0
            )
          : _c("div", { ref: "", staticClass: "row text-center" }, [
              _c("h1", [_vm._v("Nenhuma Atividade encontrada.")])
            ])
      ]),
      _vm._v(" "),
      _vm.ui.modelNoteShow
        ? _c("ui-modal", { on: { close: _vm.hideNote } }, [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _vm.isNew
                ? _c("h1", [_vm._v(" Editando a nota ")])
                : _c("h1", [_vm._v(" Adicionando nota ")])
            ]),
            _vm._v(" "),
            _c("div", { attrs: { slot: "body" }, slot: "body" }, [
              _c("form", { ref: "notesForm", attrs: { action: "" } }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "div",
                        { attrs: { name: "activity_id" } },
                        _vm._l(_vm.ui.options.activities, function(item) {
                          return _c(
                            "button",
                            {
                              staticClass: "btn",
                              class: {
                                "btn-default":
                                  _vm.current.activity_id != item.id,
                                "btn-primary":
                                  _vm.current.activity_id == item.id
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  _vm.current.activity_id = item.id
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(item.name) +
                                  "\n                                "
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-4" },
                    [
                      _c("ui-text", {
                        attrs: { label: "Titulo", name: "title" },
                        model: {
                          value: _vm.current.title,
                          callback: function($$v) {
                            _vm.$set(_vm.current, "title", $$v)
                          },
                          expression: "current.title"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c("ui-date", {
                        attrs: {
                          type: "datetime",
                          format: "dd/MM/yyyy HH:mm:ss",
                          label: "Data de Execução",
                          name: "due_to"
                        },
                        model: {
                          value: _vm.current.due_to,
                          callback: function($$v) {
                            _vm.$set(_vm.current, "due_to", $$v)
                          },
                          expression: "current.due_to"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.currentTypes
                    ? _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c("ui-select", {
                            attrs: {
                              label: "Tipo",
                              placeholder: "Tipo da atividade",
                              options: _vm.currentTypes
                            },
                            model: {
                              value: _vm.current.type_id,
                              callback: function($$v) {
                                _vm.$set(_vm.current, "type_id", $$v)
                              },
                              expression: "current.type_id"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("ui-textarea", {
                        attrs: { label: "Descrição", name: "description" },
                        model: {
                          value: _vm.current.description,
                          callback: function($$v) {
                            _vm.$set(_vm.current, "description", $$v)
                          },
                          expression: "current.description"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn default",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.hideNote()
                    }
                  }
                },
                [_vm._v("\n                Fechar\n            ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("\n                Salvar\n            ")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.ui.modalDeleteNoteShow
        ? _c(
            "ui-modal",
            { attrs: { width: "20%" }, on: { close: _vm.hideDeleteNote } },
            [
              _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                _vm._v(
                  "\n            Deseja realmente deletar esta nota?\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    on: { click: _vm.hideDeleteNote }
                  },
                  [_vm._v("\n                Fechar\n            ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger",
                    on: { click: _vm.deleteNote }
                  },
                  [_vm._v("\n                Delete\n            ")]
                )
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("hr", { staticStyle: { "margin-bottom": "15px" } })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }