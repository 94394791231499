const toastr = require('toastr')

export default {
    showToastr(type, message, title = '') {
        switch (type) {
            case 'error':
                toastr.error(message, title)
                return;
            case 'success':
                toastr.success(message, title)
                return
            default:
                toastr.info(message, title)
        }
    }

}