var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-header", staticStyle: { margin: "0" } },
    [
      _c("div", { staticClass: "page-header-top" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c(
            "div",
            { staticClass: "page-logo" },
            [
              _c("router-link", { attrs: { to: { name: "dashboard" } } }, [
                _c("img", {
                  staticClass: "logo-default",
                  attrs: { src: require("@assets/imgs/logo.png"), alt: "logo" }
                })
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("a", {
            staticClass: "menu-toggler",
            attrs: { href: "javascript:;" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "top-menu" }, [
            _c("ul", { staticClass: "nav navbar-nav pull-right" }, [
              _c(
                "li",
                { staticClass: "dropdown dropdown-user dropdown-dark" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-toggle",
                      attrs: {
                        href: "javascript:;",
                        "data-toggle": "dropdown",
                        "data-hover": "dropdown",
                        "data-close-others": "true"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "img-circle",
                        attrs: {
                          alt: "",
                          src: require("@assets/imgs/avatar.jpg")
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "username username-hide-mobile" },
                        [_vm._v("Olá " + _vm._s(_vm.USER.name))]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "dropdown-menu dropdown-menu-default" },
                    [
                      _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "users.edit",
                                  params: { id: _vm.USER.id }
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "icon-user" }),
                              _vm._v(" Meu Perfil\n                ")
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("li", { staticClass: "divider" }),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.logout($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "icon-key" }),
                            _vm._v(" Sair\n                ")
                          ]
                        )
                      ])
                    ]
                  )
                ]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "page-header-menu" }, [
        _c(
          "div",
          { staticClass: "container-fluid" },
          [
            _c("div", { staticClass: "hor-menu" }, [
              _c("div", { staticClass: "btn-group" }),
              _vm._v(" "),
              _c("ul", { staticClass: "nav navbar-nav dropdown" }, [
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "dashboard" } } },
                      [_c("i", { staticClass: "icon-home" })]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can",
                        value: "view-client",
                        expression: "'view-client'"
                      }
                    ],
                    staticClass: "menu-dropdown classic-menu-dropdown"
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("ul", { staticClass: "dropdown-menu pull-left" }, [
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "can",
                              rawName: "v-can",
                              value: "view-client",
                              expression: "'view-client'"
                            }
                          ]
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "clients.index" } } },
                            [
                              _c("i", { staticClass: "fa fa-search" }),
                              _vm._v(" Buscar\n                ")
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "can",
                              rawName: "v-can",
                              value: "create-client",
                              expression: "'create-client'"
                            }
                          ]
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "clients.create" } } },
                            [
                              _c("i", { staticClass: "fa fa-plus" }),
                              _vm._v(" Cadastrar\n                ")
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can",
                        value: "view-user",
                        expression: "'view-user'"
                      }
                    ],
                    staticClass: "menu-dropdown classic-menu-dropdown"
                  },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("ul", { staticClass: "dropdown-menu pull-left" }, [
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "can",
                              rawName: "v-can",
                              value: "view-client",
                              expression: "'view-client'"
                            }
                          ]
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "users.index" } } },
                            [
                              _c("i", { staticClass: "fa fa-search" }),
                              _vm._v(" Buscar\n                ")
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "can",
                              rawName: "v-can",
                              value: "view-client",
                              expression: "'view-client'"
                            }
                          ]
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "users.create" } } },
                            [
                              _c("i", { staticClass: "fa fa-plus" }),
                              _vm._v(" Cadastrar\n                ")
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can",
                        value: "manage-trigger",
                        expression: "'manage-trigger'"
                      }
                    ],
                    staticClass: "menu-dropdown classic-menu-dropdown"
                  },
                  [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("ul", { staticClass: "dropdown-menu pull-left" }, [
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "can",
                              rawName: "v-can",
                              value: "view-client",
                              expression: "'view-client'"
                            }
                          ]
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "triggers.index" } } },
                            [
                              _c("i", { staticClass: "fa fa-search" }),
                              _vm._v(" Buscar\n                ")
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "can",
                              rawName: "v-can",
                              value: "create-client",
                              expression: "'create-client'"
                            }
                          ]
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "triggers.create" } } },
                            [
                              _c("i", { staticClass: "fa fa-plus" }),
                              _vm._v(" Cadastrar\n                ")
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                true === false
                  ? _c(
                      "li",
                      { staticClass: "menu-dropdown mega-menu-dropdown" },
                      [_vm._m(4), _vm._v(" "), _vm._m(5)]
                    )
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("event", { staticClass: "to-right" }),
            _vm._v(" "),
            _c("div", { staticClass: "to-right" }, [
              _c(
                "form",
                { on: { submit: _vm.search } },
                [
                  _c("ui-text", {
                    staticClass: "nav-center",
                    attrs: {
                      label: false,
                      placeholder: "Filtre por: Nome ou Código"
                    },
                    model: {
                      value: _vm.ui.search.text,
                      callback: function($$v) {
                        _vm.$set(_vm.ui.search, "text", $$v)
                      },
                      expression: "ui.search.text"
                    }
                  })
                ],
                1
              )
            ])
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        attrs: {
          "data-hover": "megamenu-dropdown",
          "data-close-others": "true",
          "data-toggle": "dropdown",
          href: "javascript:;"
        }
      },
      [
        _c("i", { staticClass: "fa fa-users" }),
        _vm._v("\n              Clientes\n              "),
        _c("i", { staticClass: "fa fa-angle-down" })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        attrs: {
          "data-hover": "megamenu-dropdown",
          "data-close-others": "true",
          "data-toggle": "dropdown",
          href: "javascript:;"
        }
      },
      [
        _c("i", { staticClass: "fa fa-user" }),
        _vm._v("\n              Usuários\n              "),
        _c("i", { staticClass: "fa fa-angle-down" })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        attrs: {
          "data-hover": "megamenu-dropdown",
          "data-close-others": "true",
          "data-toggle": "dropdown",
          href: "javascript:;"
        }
      },
      [
        _c("i", { staticClass: "fa fa-random" }),
        _vm._v("\n              Triggers\n              "),
        _c("i", { staticClass: "fa fa-angle-down" })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "menu-dropdown classic-menu-dropdown" }, [
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "https://msicentral.freshdesk.com/a/"
          }
        },
        [
          _c("i", { staticClass: "fa fa-headphones" }),
          _vm._v("\n              Freshdesk\n            ")
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "dropdown-toggle",
        attrs: {
          "data-hover": "megamenu-dropdown",
          "data-close-others": "true",
          "data-toggle": "dropdown",
          href: "javascript:;"
        }
      },
      [
        _vm._v("\n              Ferramentas\n              "),
        _c("i", { staticClass: "fa fa-angle-down" })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      { staticClass: "dropdown-menu", staticStyle: { "min-width": "500px" } },
      [
        _c("li", [
          _c("div", { staticClass: "mega-menu-content" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("ul", { staticClass: "mega-menu-submenu" }, [
                  _c("li", [_c("h3", [_vm._v("CEP")])]),
                  _vm._v(" "),
                  _c("li", {}, [
                    _c(
                      "a",
                      {
                        attrs: { href: "(barra)(barra)route('cep.search')}}" }
                      },
                      [
                        _c("i", { staticClass: "fa fa-map-marker" }),
                        _vm._v(
                          " Buscar/Adicionar Bairros\n                          "
                        )
                      ]
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }