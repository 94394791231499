<template>
    <div class="row">
        <div class="col-md-12">
            <div class="portlet light ">
                <div class="portlet-title">
                    <div class="caption caption-md">
                        <i class="icon-bar-chart theme-font hide"></i>
                        <span class="caption-subject font-blue-madison bold uppercase"><i class="icon-user"></i> Usuários Ativos</span>
                    </div>

                    <div class="actions btn-set">
                        <router-link
                                v-can="'create-user'"
                                :to="{name: 'users.create'}"
                                class="btn btn-success">
                            <i class="fa fa-plus"></i> Cadastrar Novo
                        </router-link>
                    </div>
                </div>
                <div class="portlet-body">

                    <div class="form">
                        <form action="" @submit.prevent="loadUsers">
                            <div class="row">
                                <div class="col-md-3">
                                    <ui-text v-model="search.name" label="Nome"></ui-text>
                                </div>
                                <div class="col-md-3">
                                    <ui-select
                                            :options="ui.options.status"
                                            label="Status"
                                            v-model="search.status"
                                    >

                                    </ui-select>
                                </div>
                                <div class="col-md-3">
                                    <ui-select :options="ui.options.departments" label="Departamento"
                                               v-model="search.department_id"></ui-select>
                                </div>
                                <div class="col-md-3">

                                    <button style="margin-top: 25px" type="button" @click="loadUsers"
                                            class="btn btn-primary">Buscar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <ui-pagination :pagination="ui.pagination" @paginate="toPage"></ui-pagination>
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table table-striped table-hover" id="client-index">
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nome</th>
                                    <th>Ativo</th>
                                    <th>Email</th>
                                    <th>Departamento</th>
                                    <th>Ocupação</th>
                                    <th>Ações</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="user in users">
                                    <td>{{user.id}}</td>
                                    <td>{{user.name}}</td>
                                    <td>{{user.statusInfo.name}}</td>
                                    <td>{{user.email}}</td>
                                    <td>{{user.department.name}}</td>
                                    <td>{{user.occupation.name}}</td>
                                    <td>
                                        <router-link :to="{name: 'users.edit', params: {id: user.id}} "
                                                     class="btn btn-default">
                                            <i class="fa fa-pencil"></i>
                                        </router-link>

                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <ui-pagination :pagination="ui.pagination" @paginate="toPage"></ui-pagination>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import _ from 'lodash'

    export default {
        name: "Index",

        data: () => {
            return {
                users: [],
                search: {
                    status: 1,
                    name: null,
                    department_id: null
                },
                ui: {
                    options: {
                        departaments: [],
                        status: [],
                    },
                    pagination: {
                        page: 0,
                    },
                }
            }
        },

        mounted() {
            this.loadUsers()
                .then(this.loadOptions)
        },

        methods: {
            loadUsers() {
                let filters = {}

                _.forEach(this.search, (item, key) => {
                    if (item == '' || item == null) {
                        return;
                    }

                    filters[key] = item
                })

                return this.request().get('Carregando usuários', `users`, {
                    page: this.ui.pagination.page,
                    query: filters
                }).then((response) => {
                    this.users = response.data
                    this.ui.pagination = response.meta.pagination
                })
            },

            toPage(page) {
                this.ui.pagination.page = page
                this.loadUsers()
            },

            loadOptions() {
                return this.request().get('Carregando options', `users/options`)
                    .then(response => {
                        this.ui.options = response.data
                    });
            }
        }
    }
</script>
