import WarnSci from './WarnSci.vue';
import SendEmail from './SendEmail.vue';
import CancelClient from './CancelClient.vue';
import SuspendClient from './SuspendClient.vue';
import ActiveClient from './ActiveClient.vue';

export default {
    'warn-sci': WarnSci,
    'send-email': SendEmail,
    'cancel-client': CancelClient,
    'suspend-client': SuspendClient,
    'active-client': ActiveClient
}