<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {

        name: 'login-root',

        mounted() {
            document.getElementsByTagName('body')[0].className = 'login'
        },

    }
</script>