<template>
    <li>
        <span v-if="has_custom_message">{{field.message(condition)}}</span>
        <span v-if="field && type"> Quando o campo {{field.name}} for {{type.name}} que {{condition.value}} {{condition.secondary}} </span>
    </li>
</template>

<script>

    import fields from '../fields'
    import types from '../types'
    import _ from 'lodash'
    export default {
        name: "ShowCondition",

        props: {
            condition: {},
            trigger: {}
        },

        computed: {
            has_custom_message() {
                if (! _.has(this.field, 'message')) {
                    return false
                }
                return typeof this.field.message === 'function'
            },
            field() {
                return fields[this.trigger.event_name].find(i => i.id === this.condition.field)
            },
            type() {
                return types.find(i => i.id === this.condition.type)
            }
        }
    }
</script>

<style scoped>

</style>