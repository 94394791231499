<template>
    <div class="form-group">
        <label :for="id">{{label}}</label>
        <textarea
                :id="id"
                :name="name"
                type="text"
                class="form-control"
                :value="value"
                :placeholder="placeholder"
                :required="required"
                :rows="rows"
                :disabled="disabled"
                @keyup="updateValue($event.target.value)">
        </textarea>
        <small class="form-text text-muted" v-if="hint">{{hint}}</small>
    </div>
</template>

<script>
    const uniq = require('uniqid')

    export default {
        name: "textarea-input",

        props: {
            name: {
                default: ""
            },
            value: {
                type: String,
                default:""
            },
            placeholder: {
                default: ""
            },
            required: {
                type:Boolean,
                default:false
            },
            rows: {
                default: 10
            },
            id: {
                default: () => uniq()
            },
            hint: {
                type: String,
                default: ""
            },
            label: {
                type: String,
                default: ""
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },

        methods: {
            updateValue(val) {
                this.$emit('input', val)
            }
        }
    }
</script>
