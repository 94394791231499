var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-12" },
      [
        _c("ui-select", {
          staticClass: "col-md-4",
          attrs: { options: _vm.fields, label: "Campo" },
          model: {
            value: _vm.condition.field,
            callback: function($$v) {
              _vm.$set(_vm.condition, "field", $$v)
            },
            expression: "condition.field"
          }
        }),
        _vm._v(" "),
        _vm.currentField
          ? _c("div", [
              _vm.currentField.component
                ? _c(
                    "div",
                    [
                      _c(_vm.currentField.component, {
                        tag: "component",
                        attrs: { field: _vm.currentField },
                        model: {
                          value: _vm.condition,
                          callback: function($$v) {
                            _vm.condition = $$v
                          },
                          expression: "condition"
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("ui-select", {
                        staticClass: "col-md-4",
                        attrs: {
                          options: _vm.types,
                          label: "Tipo da Condição"
                        },
                        model: {
                          value: _vm.condition.type,
                          callback: function($$v) {
                            _vm.$set(_vm.condition, "type", $$v)
                          },
                          expression: "condition.type"
                        }
                      }),
                      _vm._v(" "),
                      _vm.currentField && _vm.currentField.enum
                        ? _c(
                            "div",
                            [
                              _c("ui-select", {
                                staticClass: "col-md-2",
                                attrs: {
                                  options: _vm.currentField.enum,
                                  label: "Tipo da Condição"
                                },
                                model: {
                                  value: _vm.condition.value,
                                  callback: function($$v) {
                                    _vm.$set(_vm.condition, "value", $$v)
                                  },
                                  expression: "condition.value"
                                }
                              })
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c("ui-text", {
                                staticClass: "col-md-2",
                                attrs: { label: "Valor" },
                                model: {
                                  value: _vm.condition.value,
                                  callback: function($$v) {
                                    _vm.$set(_vm.condition, "value", $$v)
                                  },
                                  expression: "condition.value"
                                }
                              })
                            ],
                            1
                          ),
                      _vm._v(" "),
                      _vm.condition.type && _vm.condition.type.secondary
                        ? _c("ui-text", {
                            staticClass: "col-md-2",
                            attrs: { label: "Valor Secundário" },
                            model: {
                              value: _vm.condition.secondary,
                              callback: function($$v) {
                                _vm.$set(_vm.condition, "secondary", $$v)
                              },
                              expression: "condition.secondary"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-1 form-group" }, [
                        _c("span", { staticClass: "control-label" }, [
                          _vm._v(" ")
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger",
                            staticStyle: { "margin-top": "22px" },
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("remove")
                              }
                            }
                          },
                          [_c("i", { staticClass: "fa fa-trash" })]
                        )
                      ])
                    ],
                    1
                  )
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }