var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "portlet light" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { ref: "userForm", staticClass: "portlet-body" }, [
          _c("div", { staticClass: "tab-content" }, [
            _c(
              "div",
              { staticClass: "tab-pane active", attrs: { id: "tab_1_1" } },
              [
                _c("ui-loading", { attrs: { "is-loading": _vm.ui.loading } }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c("ui-text", {
                          attrs: { label: "Nome:", name: "name" },
                          model: {
                            value: _vm.user.name,
                            callback: function($$v) {
                              _vm.$set(_vm.user, "name", $$v)
                            },
                            expression: "user.name"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-2" },
                      [
                        _c("ui-text", {
                          attrs: { label: "E-mail", name: "email" },
                          model: {
                            value: _vm.user.email,
                            callback: function($$v) {
                              _vm.$set(_vm.user, "email", $$v)
                            },
                            expression: "user.email"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-2" },
                      [
                        _c("ui-select", {
                          attrs: {
                            label: "Departamento",
                            name: "department_id",
                            options: _vm.ui.options.departments
                          },
                          model: {
                            value: _vm.user.department_id,
                            callback: function($$v) {
                              _vm.$set(_vm.user, "department_id", $$v)
                            },
                            expression: "user.department_id"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-2" },
                      [
                        _c("ui-select", {
                          attrs: {
                            label: "Ocupação",
                            options: _vm.ui.options.occupations,
                            name: "occupation_id"
                          },
                          model: {
                            value: _vm.user.occupation_id,
                            callback: function($$v) {
                              _vm.$set(_vm.user, "occupation_id", $$v)
                            },
                            expression: "user.occupation_id"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-2" },
                      [
                        _c("ui-select", {
                          attrs: {
                            options: _vm.ui.options.status,
                            label: "Status",
                            name: "status"
                          },
                          model: {
                            value: _vm.user.status,
                            callback: function($$v) {
                              _vm.$set(_vm.user, "status", $$v)
                            },
                            expression: "user.status"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-lg-2" },
                      [
                        _c("ui-text", {
                          attrs: {
                            label: "Nova Senha:",
                            type: "password",
                            name: "password"
                          },
                          model: {
                            value: _vm.user.password,
                            callback: function($$v) {
                              _vm.$set(_vm.user, "password", $$v)
                            },
                            expression: "user.password"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-2" },
                      [
                        _c("ui-text", {
                          attrs: {
                            label: "Nova Senha:",
                            type: "password",
                            name: "password_confirmation"
                          },
                          model: {
                            value: _vm.user.password_confirmation,
                            callback: function($$v) {
                              _vm.$set(_vm.user, "password_confirmation", $$v)
                            },
                            expression: "user.password_confirmation"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "pull-right" }, [
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can",
                                value: "edit-user",
                                expression: "'edit-user'"
                              }
                            ],
                            staticClass: "btn blue",
                            on: { click: _vm.saveUser }
                          },
                          [_vm._v("Salvar")]
                        )
                      ])
                    ])
                  ])
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "tab-pane", attrs: { id: "tab_1_2" } }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c("ui-select", {
                      attrs: {
                        label: "Dasahboard",
                        options: _vm.ui.options.dashboard
                      },
                      model: {
                        value: _vm.user.dashboard_id,
                        callback: function($$v) {
                          _vm.$set(_vm.user, "dashboard_id", $$v)
                        },
                        expression: "user.dashboard_id"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "pull-right" }, [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "can",
                            rawName: "v-can",
                            value: "edit-user-permission",
                            expression: "'edit-user-permission'"
                          }
                        ],
                        staticClass: "btn blue",
                        on: { click: _vm.saveUser }
                      },
                      [_vm._v("Salvar")]
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tab-pane", attrs: { id: "tab_1_3" } }, [
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.ui.groups, function(group) {
                  return _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("h3", [_vm._v(_vm._s(group.name))]),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _vm._l(group.permissions, function(permission) {
                        return _c(
                          "div",
                          [
                            _c("ui-check", {
                              attrs: {
                                title: permission.description,
                                value: permission,
                                "index-key": "id",
                                label: permission.name,
                                "true-value": permission
                              },
                              model: {
                                value: _vm.user.permission,
                                callback: function($$v) {
                                  _vm.$set(_vm.user, "permission", $$v)
                                },
                                expression: "user.permission"
                              }
                            })
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "pull-right" }, [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "can",
                            rawName: "v-can",
                            value: "edit-user-permission",
                            expression: "'edit-user-permission'"
                          }
                        ],
                        staticClass: "btn blue",
                        on: { click: _vm.saveUser }
                      },
                      [_vm._v("Salvar")]
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "portlet-title tabbable-line" }, [
      _c("div", { staticClass: "caption caption-md" }, [
        _c("i", { staticClass: "icon-globe theme-font hide" }),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "caption-subject font-blue-madison bold uppercase" },
          [
            _c("i", { staticClass: "icon-users" }),
            _vm._v(" Inclusão e edição de usuário")
          ]
        )
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "nav nav-tabs" }, [
        _c("li", { staticClass: "active" }, [
          _c("a", { attrs: { href: "#tab_1_1", "data-toggle": "tab" } }, [
            _vm._v("Dados usuário")
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#tab_1_2", "data-toggle": "tab" } }, [
            _vm._v("Configuração")
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "hide-tab",
              attrs: { href: "#tab_1_3", "data-toggle": "tab" }
            },
            [_vm._v("Permissões")]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }