var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-12" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("ui-text", {
            attrs: { readonly: true, label: "Número de Licenças" },
            model: {
              value: _vm.configs.users,
              callback: function($$v) {
                _vm.$set(_vm.configs, "users", $$v)
              },
              expression: "configs.users"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("ui-text", {
            attrs: { readonly: true, label: "Limite de Imóveis" },
            model: {
              value: _vm.configs.property_limit,
              callback: function($$v) {
                _vm.$set(_vm.configs, "property_limit", $$v)
              },
              expression: "configs.property_limit"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }