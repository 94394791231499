import LoginRouter from './Login.vue';
import Login from './components/Login.vue';
import Recovery from './components/Recovery.vue';
export default {
    path: '/login',
    component: LoginRouter,

    children: [
        {
            path: '',
            component: Login,
            name: 'login',
            meta: {
                auth_required: false
            }
        },
        {
            path: 'recovery/:token',
            component: Recovery,
            name: 'login.recovery',
            meta: {
                auth_required: false
            }
        }
    ]
}