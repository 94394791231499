var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ul",
        _vm._l(_vm.fields, function(item) {
          return _c("li", [
            _vm._v(
              "\n            Para " +
                _vm._s(item.name) +
                " use " +
                _vm._s(item.alias) +
                "\n        "
            )
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("ui-text", {
        attrs: { label: "Assunto do E-mail" },
        on: { input: _vm.triggerUpdate },
        model: {
          value: _vm.subject,
          callback: function($$v) {
            _vm.subject = $$v
          },
          expression: "subject"
        }
      }),
      _vm._v(" "),
      _c("ui-textarea", {
        attrs: { label: "Mensagem a ser enviada" },
        on: { input: _vm.triggerUpdate },
        model: {
          value: _vm.message,
          callback: function($$v) {
            _vm.message = $$v
          },
          expression: "message"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }