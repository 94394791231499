var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _vm.label
        ? _c("label", { attrs: { for: _vm.id } }, [
            _vm._v(_vm._s(_vm.label) + "\n    ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("date-picker", {
        staticStyle: { width: "100%" },
        attrs: {
          id: _vm.id,
          name: _vm.name,
          lang: "pt-br",
          value: _vm.properValue,
          format: _vm.format,
          disabled: _vm.disabled,
          type: _vm.type,
          "time-picker-options": _vm.timeTicker,
          language: "pt-br"
        },
        on: { change: _vm.updateValue }
      }),
      _vm._v(" "),
      _vm.hint
        ? _c("small", { staticClass: "form-text text-muted" }, [
            _vm._v(_vm._s(_vm.hint))
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }