var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "portlet light " }, [
        _c(
          "div",
          { staticClass: "portlet-body" },
          [_c("dashboard" + _vm.USER.dashboard_id, { tag: "component" })],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }