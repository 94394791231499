var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.blockedDomain !== null
      ? _c("div", [
          _c("div", { staticClass: "col-md-2" }, [
            _c(
              "span",
              {
                staticClass: "label label-default label-as-badge",
                staticStyle: { "font-weight": "400" }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.blockedDomain.mx) +
                    "\n            "
                )
              ]
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.domain.mx_zone && _vm.blockedDomain === null
      ? _c("div", [
          _c("div", { staticClass: "col-md-6" }, [
            _c(
              "div",
              {},
              [
                _c("ui-select", {
                  attrs: { label: " ", options: _vm.options.entries },
                  model: {
                    value: _vm.current.mx_zone,
                    callback: function($$v) {
                      _vm.$set(_vm.current, "mx_zone", $$v)
                    },
                    expression: "current.mx_zone"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-2" }, [
            _c("div", {}, [
              _c("label", [_vm._v(" ")]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-default add-mx-zone",
                  attrs: { href: "/" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.setMxZone($event)
                    }
                  }
                },
                [_vm._v("Adicionar\n                    Zonas MX")]
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.domain.mx_zone && _vm.blockedDomain === null
      ? _c(
          "div",
          [
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "span",
                {
                  staticClass: "label label-primary label-as-badge",
                  staticStyle: { "font-weight": "400" }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.domain.mx_zone) +
                      "\n            "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "a",
                {
                  staticClass: "label label-danger label-as-badge remove-zone",
                  on: { click: _vm.removeMxZone }
                },
                [
                  _c("i", { staticClass: "fa fa-trash" }),
                  _vm._v("\n                Remover zona MX\n            ")
                ]
              )
            ]),
            _vm._v(" "),
            _vm.ui.showModal
              ? _c("ui-modal", { on: { close: _vm.closeModal } }, [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _vm._v(
                      "\n                Deseja remover as Zonas MX? Isso irá paralizar os e-mails para este dominio.\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                    _c("div", { ref: "loadingModal" }, [
                      _vm._v(
                        "\n                    As zonas mx serão removidas imediatamente, contudo até a propagação pode\n                    demorar algumas horas!\n                "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default",
                          attrs: { type: "button" },
                          on: { click: _vm.closeModal }
                        },
                        [_vm._v("Fechar\n                    ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: { type: "button", id: "client_user-enviar" },
                          on: { click: _vm.doRemoveMxZone }
                        },
                        [
                          _c("i", { staticClass: "fa fa-trash" }),
                          _vm._v("Remover\n                    ")
                        ]
                      )
                    ])
                  ])
                ])
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }