import Client from './Client.vue';
import List from './component/List.vue';
import Editor from './component/Editor.vue';
import Form from './component/_partials/Form.vue';
import History from './component/_partials/History.vue';
import Tickets from './component/_partials/Tickets.vue';
import Notes from './component/_partials/Notes.vue';
import Users from './component/_partials/Users.vue'
import Plans from './component/_partials/Plans.vue';
import Installments from './component/_partials/Installment.vue';

export default {
    path: '/clients',
    component: Client,
    children: [
        {
            path: '/',
            component: List,
            name: 'clients.index'
        },
        {
            path: 'create',
            component: Editor,
            children: [
                {
                    path: '/',
                    name: 'clients.create',
                    component: Form,
                    meta: {
                        name: 'index'
                    }
                }
            ]
        },
        {
            path: ':id/edit',
            component: Editor,
            meta: {
                name: 'index'
            },
            children: [
                {
                    path: '/',
                    name: 'clients.edit.index',
                    component: Form,
                    meta: {
                        name: 'index'
                    }
                },
                {
                    path: 'histories',
                    name: 'clients.view.histories',
                    component: History,
                    meta: {
                        name: 'histories'
                    }
                },
                {
                    path: 'tickets',
                    name: 'clients.view.tickets',
                    component: Tickets,
                    meta: {
                        name: 'tickets'
                    }
                },
                {
                    path: 'notes',
                    name: 'clients.edit.notes',
                    component: Notes,
                    meta: {
                        name: 'notes'
                    }
                },
                {
                    path: 'informations',
                    name: 'clients.edit.info',
                    component: Form,
                    meta: {
                        name: 'informations'
                    }
                },
                {
                    path: 'users',
                    name: 'clients.edit.users',
                    component: Users,
                    meta: {
                        name: 'users'
                    }
                },

                {
                    path: 'plans',
                    name: 'clients.edit.plans',
                    component: Plans,
                    meta: {
                        name: 'plans'
                    }
                },

                {
                    path: 'installments',
                    name: 'clients.edit.installments',
                    component: Installments,
                    meta: {
                        name: 'installments'
                    }
                }
            ]

        }
    ]
}