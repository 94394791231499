<template>
    <ui-modal v-if="show" :is-loading="ui.loading" @close="$emit('close')">
        <div slot="header">
            <h2>Mudança de Plano</h2>
        </div>
        <div slot="body">
            <div ref="blockElement">
                <ui-select
                        :options="ui.plansOptions"
                        label="Deseja mudar o plano atual para?"
                        v-model="plan_id"
                >
                </ui-select>
            </div>
        </div>
        <div slot="footer">
            <button type="button" class="btn btn-default" @click="$emit('close')">Fechar</button>
            <button type="button" id="client_user-enviar" class="btn btn-primary" @click="changePlan">Salvar</button>
        </div>
    </ui-modal>
</template>

<script>
    export default {
        name: "change-plan",

        props: {
            show: {
                default: false,
            },

            clientPlan: {
                default: () => {
                    return {
                        id: null
                    }
                }
            }
        },

        data() {
            return {
                ui: {
                    loading: true,
                    plansOptions: []
                },
                plan_id: null
            }
        },
        watch: {
            show(val) {

                this.request().get('Verificando Planos dinsponíveis para mudança', `/client-plans/${this.clientPlan.id}/change-possibilities`)
                    .then(response => {
                        this.ui.loading = false
                        this.ui.plansOptions = response.data
                    })

                return val
            },

        },

        methods: {
            changePlan() {
                this.request().block(this.$refs.blockElement).put('Alterando plano', `/client-plans/${this.clientPlan.id}/change`, {
                    plan_id: this.plan_id
                }).then(response => {
                    this.$emit('change', response.data)
                })
            }
        }
    }
</script>

<style scoped>

</style>