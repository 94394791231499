var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ui-modal",
        {
          on: {
            close: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Editar Zonas Dns")
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c(
              "div",
              {
                ref: "domainForm",
                attrs: { id: "hosted_zones" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.addDomain($event)
                  }
                }
              },
              [
                _c("form", { attrs: { id: "domain-form", method: "post" } }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-8" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "domain" }
                          },
                          [
                            _vm._v(
                              "\n                  Domínio\n                  "
                            ),
                            _c("small", [_vm._v("(Ex: http://dominio.com.br)")])
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.domain.url,
                              expression: "domain.url"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", name: "domain", id: "domain" },
                          domProps: { value: _vm.domain.url },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.domain, "url", $event.target.value)
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "" } }, [_vm._v(" ")]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-default",
                              attrs: { href: "/" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.addDomain($event)
                                }
                              }
                            },
                            [_vm._v("Adicionar")]
                          )
                        ])
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("h2", [_vm._v("Lista de Dominios")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "alert alert-warning" }, [
                      _c("p", [
                        _vm._v(
                          "\n                Os DNS's listados abaixo são os valores que devem ser informados ao gerenciador de\n                domínios. (Exemplo\n                "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: "http://registro.br"
                            }
                          },
                          [_vm._v("registro.br")]
                        ),
                        _vm._v(").\n              ")
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-5" },
                    [
                      _c("ui-text", {
                        attrs: { placeholder: "Filtrar" },
                        model: {
                          value: _vm.search.domain,
                          callback: function($$v) {
                            _vm.$set(_vm.search, "domain", $$v)
                          },
                          expression: "search.domain"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c(
                        "ui-loading",
                        { attrs: { "is-loading": _vm.ui.loading } },
                        [
                          _c("ui-pagination", {
                            attrs: { pagination: _vm.pagination },
                            on: { paginate: _vm.paginate }
                          }),
                          _vm._v(" "),
                          _c(
                            "table",
                            {
                              staticClass:
                                "table-hover table-bordered table table-responsive"
                            },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [_vm._v("Domínio")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Zona MX")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("DNS")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("I.P")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Principal")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Remover")])
                                ])
                              ]),
                              _vm._v(" "),
                              _vm.domains
                                ? _c(
                                    "tbody",
                                    { ref: "toBlock" },
                                    _vm._l(_vm.domains, function(domain, key) {
                                      return _c(
                                        "tr",
                                        {
                                          key: key,
                                          class: { success: domain.main }
                                        },
                                        [
                                          _c("td", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(domain.domain) +
                                                "\n                      "
                                            ),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: domain.domain,
                                                  target: "_blank"
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-external-link-square"
                                                })
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            [
                                              _c("set-mx-zone", {
                                                attrs: {
                                                  options:
                                                    _vm.options.productOptions,
                                                  domain: domain,
                                                  blocked: _vm.blocked,
                                                  "client-product-id":
                                                    _vm.clientProductId
                                                },
                                                on: { updated: _vm.doSearch }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c(
                                              "ul",
                                              _vm._l(
                                                _vm.getNs(domain),
                                                function(dns, index) {
                                                  return _c(
                                                    "li",
                                                    { key: index },
                                                    [_vm._v(_vm._s(dns))]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(domain.ip) +
                                                "\n                      "
                                            ),
                                            _c("i", {
                                              staticClass: "fa",
                                              class: {
                                                "fa-check text-success":
                                                  domain.with_us,
                                                "fa-times text-danger": !domain.with_us
                                              }
                                            })
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            domain.main
                                              ? _c("span", [
                                                  _vm._v("Domínio principal")
                                                ])
                                              : _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn btn-info",
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        return _vm.setMain(
                                                          domain
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Tornar\n                        Principal\n                      "
                                                    )
                                                  ]
                                                )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-danger",
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.openDomainremovalModal(
                                                      domain
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-trash"
                                                }),
                                                _vm._v(
                                                  "Remover\n                      "
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _c("tbody", [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-center",
                                          attrs: { colspan: "4" }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Nenhum dominio encontrado.")
                                          ])
                                        ]
                                      )
                                    ])
                                  ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("ui-pagination", {
                            attrs: { pagination: _vm.pagination },
                            on: { paginate: _vm.doSearch }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _vm.ui.showRemovalDomainModal
        ? _c("ui-modal", { on: { close: _vm.closeDomainremovalModal } }, [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _c("h4", [
                _vm._v("Deseja realmente remover " + _vm._s(_vm.current.domain))
              ])
            ]),
            _vm._v(" "),
            _c("div", { attrs: { slot: "body" }, slot: "body" }, [
              _c("p", [
                _vm._v(
                  "Ao remover o domínio os DNS's apontados serão perdidos e não poderão ser recuperados."
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.closeDomainremovalModal($event)
                    }
                  }
                },
                [_vm._v("Cancelar")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.doRemoveDomain($event)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-trash" }),
                  _vm._v(" Remover\n      ")
                ]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }