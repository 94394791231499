import components from './index'
export default {
    props: {
        clientProduct: {
            default: {
                defaultValues: {}
            }
        }
    },
    computed: {
        configs() {
            let val = {}
            this.clientProduct.default.forEach(i => {
                val[i.name] = i.value
            })

            return val
        },
    },

    components: {
        ...components
    }
}