<template>
    <label>
        <input type="radio" :checked="shouldBeChecked" :value="value" @change="updateInput">
        {{ label }}
    </label>
</template>
<script>
    export default {
        model: {
            prop: 'modelValue',
            event: 'change'
        },
        props: {
            value: {
                default: null
            },
            modelValue: {
                default: ""
            },
            label: {
                type: String,
            },
        },
        computed: {
            shouldBeChecked() {
                return this.modelValue == this.value
            }
        },
        methods: {
            updateInput() {
                this.$emit('change', this.value)
            }
        }
    }
</script>