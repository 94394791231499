import { date, boolean, money } from '@/lib/formatters'
import _ from 'lodash'

export default {
    'name': (data) => data.name,
    'code': (data) => data.code,
    'status': (data, options) => { return _.find(options.statusOptions, { 'id': data.status }).name },
    'billing_day': (data) => data.billing_day,
    'obs': (data) => data.obs,
    'created_at': (data) => date(data.created_at),
    'updated_at': (data) => date(data.updated_at),
    'deleted_at': (data) => date(data.deleted_at),
    'user_id': data => data.user_id,
    'customer_id': data => data.customer_id,
    'vendor_id': data => data.vendor_id,
    'sl_id': data => data.sl_id,
    'contact_type': data => data.contactTypeInfo.name,
    'freezed_at': (data) => date(data.freezed_at),
    'email': data => data.email,
    'phone1': data => data.phone1,
    'phone2': data => data.phone2,
    'billing_landmark': data => data.billing_landmark(),
    'disable_monthly': data => data.disable_monthly(),
    'rg': data => data.rg,
    'company_name': data => data.company_name(),
    'same_address': data => boolean(data.same_address),
    'billing_cep': data => data.billing_cep,
    'billing_number': data => data.billing_number,
    'billing_complement': data => data.billing_complement,
    'billing_neighborhood': data => data.billing_neighborhood,
    'billing_city': data => data.billing_city,
    'billing_uf': data => data.billing_uf,
    'state_registration': data => data.state_registration,
    'cpf_cnpj': data => data.cpf_cnpj,
    'cep': data => data.cep,
    'address': data => data.address,
    'number': data => data.number,
    'complement': data => data.complement,
    'neighborhood': data => data.neighborhood,
    'city': data => data.city,
    'uf': data => data.uf,
    'creci': data => data.creci,
    'creci_uf': data => data.creci_uf,
    'mysquite_id': data => data.mysuite_id,
    'billet_login': data => data.billet_login,
    'microsistec_mail': data => data.microsistec_mail,
    'boarding_info': data => data.boarding_info,
    'recurrency': data => money(data.recurrency),
    'cancel_date': data => date(data.cancel_date),
    'due_to': data => date(data.due_to),
    'suspension_date': data => date(data.suspension_date),
    'second_invoice_link': data => data.second_invoice_link,
    'contact_emails': data => data.contact_emails,
    'ignore_block': data => boolean(data.ignore_block),
    'migrated': data => boolean(data.migated),
    'freshdesk_id': data => data.freshdesk_id
}