<template>
    <div class="row">
        <div class="col-md-12">

            <ui-select
                    class="col-md-4"
                    :options="fields"
                    v-model="condition.field"
                    label="Campo"
            ></ui-select>

            <div v-if="currentField">
                <div v-if="currentField.component">
                    <component :is="currentField.component" :field="currentField" v-model="condition"></component>
                </div>
                <div v-else>
                    <ui-select
                            class="col-md-4"
                            :options="types"
                            v-model="condition.type"
                            label="Tipo da Condição"
                    ></ui-select>

                    <div v-if="currentField && currentField.enum">
                        <ui-select
                                class="col-md-2"
                                :options="currentField.enum"
                                v-model="condition.value"
                                label="Tipo da Condição"
                        ></ui-select>
                    </div>
                    <div v-else>
                        <ui-text label="Valor" v-model="condition.value" class="col-md-2"></ui-text>
                    </div>


                    <ui-text class="col-md-2" v-model="condition.secondary"
                             v-if="condition.type && condition.type.secondary"
                             label="Valor Secundário"></ui-text>

                    <div class="col-md-1 form-group">
                        <span class="control-label">&nbsp;</span>
                        <button class="btn btn-danger" type="button" @click="$emit('remove')" style="margin-top: 22px">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import type from '../types'
    import fields from '../fields'

    export default {
        name: "Condition",

        props: {
            fields: {
                default: () => []
            },
            conditions: {}

        },
        components: {
            ...fields.components
        },

        data() {
            return {
                condition: this.conditions,
            }
        },

        watch: {
            condition: {
                deep: true,
                handler(value) {
                    this.$emit('change', value)
                }
            }
        },

        computed: {

            currentField() {
                return this.fields.find(i => i.id == this.condition.field)
            },
            types() {

                if (this.currentField === undefined) {
                    return type
                }

                if (this.currentField.types === undefined) {
                    return type
                }

                return type.filter(i => {
                    return this.currentField.types.indexOf(i.id) >= 0
                })
            }
        }
    }
</script>