var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c(
          "form",
          {
            ref: "form",
            attrs: {
              role: "form",
              action: "#",
              id: "save-client",
              "data-toggle": "validator"
            }
          },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("ui-select", {
                    attrs: {
                      required: true,
                      label: "Status",
                      options: _vm.options.statusOptions,
                      name: "status"
                    },
                    model: {
                      value: _vm.current.status,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "status", $$v)
                      },
                      expression: "current.status"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("ui-text", {
                    attrs: { name: "code", label: "Código", disabled: true },
                    model: {
                      value: _vm.current.code,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "code", $$v)
                      },
                      expression: "current.code"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-1" },
                [
                  _c("ui-text", {
                    attrs: { disabled: true, label: "SL ID" },
                    model: {
                      value: _vm.current.sl_id,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "sl_id", $$v)
                      },
                      expression: "current.sl_id"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-2" }, [
                _vm._v("\n          Data de Cadastro\n          "),
                _c("br"),
                _vm._v(" "),
                _c("strong", [
                  _vm._v(_vm._s(_vm._f("date")(_vm.current.created_at)))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-2" }, [
                _vm._v("\n          Data de ativação:\n          "),
                _c("br"),
                _vm._v(" "),
                _c("strong", [
                  _vm._v(_vm._s(_vm._f("date")(_vm.current.activated_at)))
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("ui-date", {
                    attrs: {
                      label: "Data de Cancelamento",
                      name: "cancel_date"
                    },
                    model: {
                      value: _vm.current.cancel_date,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "cancel_date", $$v)
                      },
                      expression: "current.cancel_date"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("ui-date", {
                    attrs: {
                      name: "suspension_date",
                      label: "Data de suspensão"
                    },
                    model: {
                      value: _vm.current.suspension_date,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "suspension_date", $$v)
                      },
                      expression: "current.suspension_date"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-2 vertical-checkbox" },
                [
                  _c("ui-check", {
                    attrs: {
                      label: "Desabilitar mensalidade",
                      "true-value": 1,
                      value: _vm.current.disable_monthly
                    },
                    model: {
                      value: _vm.current.disable_monthly,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "disable_monthly", $$v)
                      },
                      expression: "current.disable_monthly"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2 vertical-checkbox" },
                [
                  _c("ui-check", {
                    attrs: {
                      value: _vm.current.migrated,
                      label: "Cliente Migrado"
                    },
                    model: {
                      value: _vm.current.migrated,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "migrated", $$v)
                      },
                      expression: "current.migrated"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2 vertical-checkbox" },
                [
                  _c("ui-check", {
                    attrs: {
                      label: "Ignorar Bloqueio",
                      "true-value": 1,
                      value: _vm.current.ignore_block
                    },
                    model: {
                      value: _vm.current.ignore_block,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "ignore_block", $$v)
                      },
                      expression: "current.ignore_block"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("ui-text", {
                    attrs: {
                      label: "Nome / Nome Fantasia",
                      required: true,
                      name: "name"
                    },
                    model: {
                      value: _vm.current.name,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "name", $$v)
                      },
                      expression: "current.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("ui-text", {
                    attrs: {
                      required: true,
                      label: "Razão Social",
                      name: "company_name"
                    },
                    model: {
                      value: _vm.current.company_name,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "company_name", $$v)
                      },
                      expression: "current.company_name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("ui-date", {
                    attrs: { name: "due_to", label: "Próximo Vencimento" },
                    model: {
                      value: _vm.current.due_to,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "due_to", $$v)
                      },
                      expression: "current.due_to"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("ui-text", {
                    attrs: {
                      disabled: true,
                      name: "recurrency",
                      label: "Recorrencia"
                    },
                    model: {
                      value: _vm.current.recurrency,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "recurrency", $$v)
                      },
                      expression: "current.recurrency"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("ui-text", {
                    attrs: { label: "CRECI" },
                    model: {
                      value: _vm.current.creci,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "creci", $$v)
                      },
                      expression: "current.creci"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-1" },
                [
                  _c("ui-text", {
                    attrs: { label: "UF" },
                    model: {
                      value: _vm.current.creci_uf,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "creci_uf", $$v)
                      },
                      expression: "current.creci_uf"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _c("ui-text", {
                    attrs: {
                      required: true,
                      label: "CPF/CNPJ",
                      name: "cpf_cnpj",
                      mask: "cpf_cnpj"
                    },
                    model: {
                      value: _vm.current.cpf_cnpj,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "cpf_cnpj", $$v)
                      },
                      expression: "current.cpf_cnpj"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _c("ui-text", {
                    attrs: { label: "I.E.", name: "state_registration" },
                    model: {
                      value: _vm.current.state_registration,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "state_registration", $$v)
                      },
                      expression: "current.state_registration"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _c("ui-text", {
                    attrs: { label: "RG", name: "rg", mask: "rg" },
                    model: {
                      value: _vm.current.rg,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "rg", $$v)
                      },
                      expression: "current.rg"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _c("ui-text", {
                    attrs: { required: true, label: "E-mail", name: "email" },
                    model: {
                      value: _vm.current.email,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "email", $$v)
                      },
                      expression: "current.email"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _c("ui-text", {
                    attrs: {
                      required: true,
                      label: "E-mails de contato (separados por ';')",
                      name: "contact_emails"
                    },
                    model: {
                      value: _vm.current.contact_emails,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "contact_emails", $$v)
                      },
                      expression: "current.contact_emails"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _c("ui-text", {
                    attrs: {
                      required: true,
                      label: "Telefone 1",
                      name: "phone1",
                      mask: "phone"
                    },
                    model: {
                      value: _vm.current.phone1,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "phone1", $$v)
                      },
                      expression: "current.phone1"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _c("ui-text", {
                    attrs: { label: "Telefone 2", mask: "phone" },
                    model: {
                      value: _vm.current.phone2,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "phone2", $$v)
                      },
                      expression: "current.phone2"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("h4", [_vm._v("Endereço")]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { ref: "addressCep" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "ui-text",
                      {
                        attrs: {
                          "input-group": true,
                          required: true,
                          label: "CEP",
                          name: "cep",
                          mask: "cep"
                        },
                        model: {
                          value: _vm.current.cep,
                          callback: function($$v) {
                            _vm.$set(_vm.current, "cep", $$v)
                          },
                          expression: "current.cep"
                        }
                      },
                      [
                        _c("span", { staticClass: "input-group-btn" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn green",
                              attrs: { type: "button" },
                              on: { click: _vm.populateAddress }
                            },
                            [_c("i", { staticClass: "fa fa-search" })]
                          )
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-9" },
                  [
                    _c("ui-text", {
                      attrs: {
                        required: true,
                        label: "Endereço",
                        name: "address"
                      },
                      model: {
                        value: _vm.current.address,
                        callback: function($$v) {
                          _vm.$set(_vm.current, "address", $$v)
                        },
                        expression: "current.address"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-2" },
                  [
                    _c("ui-text", {
                      attrs: {
                        required: true,
                        label: "Número",
                        name: "number"
                      },
                      model: {
                        value: _vm.current.number,
                        callback: function($$v) {
                          _vm.$set(_vm.current, "number", $$v)
                        },
                        expression: "current.number"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-2" },
                  [
                    _c("ui-text", {
                      attrs: { label: "Complemento", name: "complement" },
                      model: {
                        value: _vm.current.complement,
                        callback: function($$v) {
                          _vm.$set(_vm.current, "complement", $$v)
                        },
                        expression: "current.complement"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c("ui-text", {
                      attrs: {
                        required: true,
                        label: "Bairro",
                        name: "neighborhood"
                      },
                      model: {
                        value: _vm.current.neighborhood,
                        callback: function($$v) {
                          _vm.$set(_vm.current, "neighborhood", $$v)
                        },
                        expression: "current.neighborhood"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c("ui-text", {
                      attrs: { required: true, label: "Cidade", name: "city" },
                      model: {
                        value: _vm.current.city,
                        callback: function($$v) {
                          _vm.$set(_vm.current, "city", $$v)
                        },
                        expression: "current.city"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-2" },
                  [
                    _c("ui-text", {
                      attrs: { required: true, label: "UF", name: "uf" },
                      model: {
                        value: _vm.current.uf,
                        callback: function($$v) {
                          _vm.$set(_vm.current, "uf", $$v)
                        },
                        expression: "current.uf"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("h4", [_vm._v("Outras Informações")]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("ui-select", {
                    attrs: {
                      label: "Dia de Cobrança",
                      options: _vm.options.paymentDays,
                      required: true,
                      name: "paymentDays"
                    },
                    model: {
                      value: _vm.current.billing_day,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "billing_day", $$v)
                      },
                      expression: "current.billing_day"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("ui-select", {
                    attrs: {
                      label: "Vendedor",
                      options: _vm.options.vendors,
                      "option-is-disabled": _vm.isDisabled,
                      requred: true,
                      name: "vendor_id"
                    },
                    model: {
                      value: _vm.current.vendor_id,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "vendor_id", $$v)
                      },
                      expression: "current.vendor_id"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _c("ui-select", {
                    attrs: {
                      label: "Customer Success",
                      options: _vm.options.customers,
                      required: true,
                      name: "customer_id",
                      "option-is-disabled": _vm.isDisabled
                    },
                    model: {
                      value: _vm.current.customer_id,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "customer_id", $$v)
                      },
                      expression: "current.customer_id"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("ui-textarea", {
                    attrs: { label: "Observações" },
                    model: {
                      value: _vm.current.obs,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "obs", $$v)
                      },
                      expression: "current.obs"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _vm.current.id
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: { click: _vm.resetValues }
                    },
                    [_vm._v("\n          Resetar\n          Valores\n        ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "can",
                      rawName: "v-can",
                      value: "edit-client",
                      expression: "'edit-client'"
                    }
                  ],
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.updateClient }
                },
                [_vm._v("Salvar")]
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _vm.ui.require_note
        ? _c(
            "ui-modal",
            {
              on: {
                close: function($event) {
                  _vm.ui.require_note = false
                }
              }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("h4", [_vm._v("Cadastro de nota de informação")])
              ]),
              _vm._v(" "),
              _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                _c(
                  "form",
                  {
                    ref: "noteForm",
                    attrs: { action: "" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.doSendNote($event)
                      }
                    }
                  },
                  [
                    _c("ui-text", {
                      attrs: { label: "Titulo", disabled: true },
                      model: {
                        value: _vm.note.title,
                        callback: function($$v) {
                          _vm.$set(_vm.note, "title", $$v)
                        },
                        expression: "note.title"
                      }
                    }),
                    _vm._v(" "),
                    _c("ui-select", {
                      class: { "has-error": _vm.ui.noteError },
                      attrs: {
                        options: _vm.notesTypes,
                        keyName: "id",
                        label: "motivo"
                      },
                      model: {
                        value: _vm.note.type_id,
                        callback: function($$v) {
                          _vm.$set(_vm.note, "type_id", $$v)
                        },
                        expression: "note.type_id"
                      }
                    }),
                    _vm._v(" "),
                    _vm.ui.noteError
                      ? _c("div", [
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              "O Motivo da alteração de status é obrigatório."
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("ui-textarea", {
                      attrs: { placeholder: "Motivo" },
                      model: {
                        value: _vm.note.description,
                        callback: function($$v) {
                          _vm.$set(_vm.note, "description", $$v)
                        },
                        expression: "note.description"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                _c("div", { staticClass: "pull-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "submit" },
                      on: {
                        click: function($event) {
                          _vm.ui.require_note = false
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "btn blue", on: { click: _vm.doSendNote } },
                    [_vm._v("Salvar")]
                  )
                ])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }