<template>
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-6">
                <ui-text :readonly="true" v-model="configs.users" label="Número de Licenças"></ui-text>
            </div>

            <div class="col-md-6">
                <ui-text :readonly="true" v-model="configs.property_limit" label="Limite de Imóveis"></ui-text>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from './mixin'
    export default {
        name: "sci-online-add",

        mixins: [
            mixin
        ]

    }
</script>

<style scoped>

</style>