var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("h1", [
    _vm._v(
      "O cliente será Suspenso, e todos seus produtos irão parar de funcionar."
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }