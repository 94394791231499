<template>
    <div>

        <div class="col-md-4">
            <ui-date  :disabled='condition.today' v-model="condition.cancel_date" label="Data de cancelamento"></ui-date>
        </div>

        <div class="col-md-2">
            <ui-check style="margin-top: 25px" :value="condition.today" v-model='condition.today' label="No dia."></ui-check>
        </div>


        <div class="col-md-1 form-group">
            <span class="control-label">&nbsp;</span>
            <button class="btn btn-danger" type="button" @click="$emit('remove')" style="margin-top: 22px">
                <i class="fa fa-trash"></i>
            </button>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    export default {
        name: "date_field",

        props: {
            value: {

            },
            field: {
                required: true
            }
        },
        data() {
            return {
                condition :  _.pick(this.value, ['today', 'cancel_date', 'field'])
            }
        },
        mounted() {
            this.condition.type = 'date'
            this.$watch('condition', () => {
                this.changed()
            }, {deep : true})
        },
        methods: {
            changed() {
                this.$emit('input',this.condition)
            }
        },
    }
</script>

<style scoped>

</style>