<template>
    <div>
        <button
                v-can="'add-user-to-client'"
                @click="addUser()"
                type="button"
                id="branch_user-add"
                class="btn btn-primary">
            Adicionar Usuário
        </button>
        <table class="table table-striped table-hover">
            <thead>
            <tr>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Telefone</th>
                <th>Tipo</th>
                <th>Ações</th>
            </tr>
            </thead>
            <tbody id="client_user-table">
            <tr id="client_user" v-for="item in currentClient.users">
                <td>{{item.name}}</td>
                <td>{{item.email}}</td>
                <td>{{item.phone}}</td>
                <td>{{item.contactInfo.name}}</td>
                <td width="12%">
                    <span v-can="'edit-user-client'" class="btn blue btn-sm branch_user-edit" @click="editUser(item)">
                        <i class="fa fa-edit"></i>
                    </span>
                    <a v-can="'delete-user-client'" class="btn red btn-sm" @click="openDeleteModal(item)">
                        <i class="fa fa-trash"></i>
                    </a>
                </td>
            </tr>
            </tbody>
        </table>

        <ui-modal v-if="ui.deleteModalIsVisible" @close="hideDeleteModal">
            <div slot="header">
                Deseja realmente deletar este usuário?
            </div>

            <div slot="body">
                <p ref="deleteModal">
                    Deseja realmente deletar {{ui.user.name}}?
                </p>
            </div>

            <div slot="footer">
                <button type="button" class="btn btn-default" @click="hideDeleteModal">Fechar</button>
                <button type="button" class="btn btn-danger" @click="deleteUser">Deletar</button>
            </div>
        </ui-modal>

        <ui-modal v-if="ui.showModalIsVisible" @close="closeModal">
            <div slot="header">
                <h4 class="modal-title">Cadastro de cliente</h4>
            </div>
            <div slot="body">
                <form action="#" role="form" id="client_user-form" ref="form">
                    <div class="row">
                        <div class="col-md-5">
                            <ui-text
                                    label="Nome"
                                    :required="true"
                                    name="name"
                                    v-model="ui.user.name"
                                    placeholder="Nome"
                            ></ui-text>
                        </div>

                        <div class="col-md-3">

                            <ui-select
                                    label="Tipo do Contato"
                                    :options="ui.options.contactTypes"
                                    v-model="ui.user.contact_type"
                                    name="contact_type"
                                    :required="true"
                            >
                            </ui-select>
                        </div>

                        <div class="col-md-2">
                            <ui-check
                                    style="margin-top: 30px"
                                    v-model="ui.user.owner"
                                    label="Propretário"
                            >

                            </ui-check>
                        </div>

                        <div class="col-md-2">
                            <ui-check
                                    style="margin-top: 30px"
                                    v-model="ui.user.active"
                                    label="Contato Ativo"
                            >

                            </ui-check>
                        </div>
                    </div>
                    <div class="row">


                        <div class="col-md-5">
                            <ui-text
                                    v-model="ui.user.phone"
                                    mask="phone"
                                    :required="true"
                                    label="Telefone"
                                    name="phone"
                                    icon="fa fa-phone"
                            ></ui-text>
                        </div>

                        <div class="col-md-3">
                            <ui-date
                                    v-model="ui.user.birthday_date"
                                    label="Aniversário"
                                    :required="true"
                            >

                            </ui-date>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-5">
                            <ui-text
                                    v-model="ui.user.email"
                                    label="Email"
                                    name="email"
                                    icon="fa fa-envelope"
                            >

                            </ui-text>
                        </div>

                        <div class="col-md-3">
                            <ui-text
                                    v-model="ui.user.password"
                                    label="Senha"
                                    type="password"
                                    name="password"

                                    :required="newUser"
                            >

                            </ui-text>
                        </div>

                        <div class="col-md-3">
                            <ui-text
                                    v-model="ui.user.password_confirmation"
                                    label="Repita a senha"
                                    name="password_confirmation"
                                    :required="newUser"
                            >

                            </ui-text>
                        </div>

                    </div>
                </form>
            </div>
            <div slot="footer">
                <button type="button" class="btn btn-default" @click="closeModal">Fechar</button>
                <button type="button" id="client_user-enviar" class="btn btn-primary" @click="save">Salvar</button>
            </div>
        </ui-modal>
    </div>
</template>

<script>
    const _ = require('lodash')
    export default {
        name: "client-users",
        props: {
            client: {
                default: {}
            },
            options: {
                default: []
            }
        },
        data() {
            return {
                currentClient: _.cloneDeep(this.client),
                ui: {
                    showModalIsVisible: false,
                    deleteModalIsVisible: false,
                    options: {
                        contactType: []
                    },
                    user: {}
                },

            }
        },
        computed: {
            newUser() {
                return this.ui.user.id === null || this.ui.user.id === undefined
            }
        },
        mounted() {
            this.ui.options = this.options
        },
        methods: {
            closeModal() {
                this.ui.showModalIsVisible = false
            },
            cleanUser() {
                return {
                    client_id: this.client.id
                }
            },
            addUser() {
                this.ui.user = this.cleanUser()
                this.showModal();
            },

            editUser(user) {
                this.ui.user = _.clone(user)
                this.ui.user.password = null
                this.showModal();
            },


            deleteUser() {
                this.request().block(this.$refs.deleteModal).del("Removendo usuário", `clients/${this.currentClient.id}/users/${this.ui.user.id}`)
                    .then(response => {
                        this.removeUser(response.data)
                        this.hideDeleteModal()
                    })
            },

            openDeleteModal(user) {
                this.ui.user = _.clone(user)
                this.showDeleteModal();
            },

            hideDeleteModal() {
                this.ui.deleteModalIsVisible = false
            },

            showDeleteModal() {
                this.ui.deleteModalIsVisible = true
            },

            showModal() {
                this.ui.showModalIsVisible = true
            },


            save() {
                let request = this.request().block(this.$refs.form)

                if (this.ui.user.id === undefined || this.ui.user.id === null) {
                    request = request.post('salvando usuário', `clients/${this.currentClient.id}/users`, this.ui.user)
                } else {
                    request = request.put('editando usuário', `clients/${this.currentClient.id}/users/${this.ui.user.id}`, this.ui.user)
                }

                request
                    .then(response => {

                        this.updateUserList(response.data)

                        if (this.ui.user.id === undefined) {
                            this.closeModal()
                        }
                    })
            },

            removeUser(user) {
                const index = this.getUserIndex(user)

                this.currentClient.users.splice(index, 1)

                this.sendUpdate()
            },

            updateUserList(user) {
                let index = this.getUserIndex(user)

                if (index >= 0) {
                    this.$set(this.currentClient.users, index, user)
                } else {
                    this.currentClient.users.push(user)
                }

                this.sendUpdate()

            },

            getUserIndex(user) {
                return this.currentClient.users.findIndex(i => i.id === user.id)
            },

            sendUpdate() {
                this.$emit('change', this.currentClient)
            }
        }
    }
</script>

<style scoped>

</style>