const moment = require('moment')

import trans from './trans'

export const date = (value, format = 'DD/MM/YYYY') => {

    if (!value) {
        return null;
    }

    if (value.date !== undefined) {
        return moment(value.date).format(format)
    }
    return moment(value).format(format);
}

export const boolean = function (value) {
    if (value) {
        return trans('common.yes')
    }

    return trans('common.no')
}

export const money = function (value) {
    const format = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
    });

    return format.format(value);
}
