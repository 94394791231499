var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pull-right" }, [
    _c("div", { staticClass: "hor-menu " }, [
      _c("ul", { staticClass: "nav navbar-nav" }, [
        _c("li", [
          _c(
            "a",
            {
              staticStyle: { position: "relative" },
              attrs: { href: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.ui.showEvents = !_vm.ui.showEvents
                }
              }
            },
            [
              _c("i", {
                staticClass: "fa fa-comments",
                staticStyle: { "font-size": "25px" }
              })
            ]
          ),
          _vm._v(" "),
          _vm.ui.showEvents
            ? _c("div", { staticClass: "teste" }, [
                _c("div", { staticClass: "portlet light" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "portlet-body" }, [
                    _c(
                      "ul",
                      {
                        staticClass: "list-group",
                        staticStyle: { height: "600px" }
                      },
                      _vm._l(_vm.EVENTS, function(event) {
                        return _c(
                          "li",
                          {
                            staticClass: "list-group-item",
                            class: {
                              "list-group-item-danger": event.type === "error",
                              "list-group-item-success":
                                event.type === "success"
                            },
                            staticStyle: { color: "#000" }
                          },
                          [
                            _c("div", [
                              _c("span", [_vm._v(_vm._s(event.title))]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(event.description) +
                                    "\n                                        "
                                )
                              ]),
                              _vm._v(" "),
                              _c("small", [
                                _c("i", { staticClass: "fa fa-clock-o" }),
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(_vm._f("datetime")(event.created)) +
                                    "\n                                        "
                                )
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("a", [_vm._v("Marcar como lida")])
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ])
              ])
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "portlet-title" }, [
      _c("h1", { staticStyle: { color: "#000" } }, [_vm._v("Últimos eventos")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }