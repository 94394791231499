<template>
    <router-view></router-view>
</template>

<script>
    export default {
        name: "client"
    }
</script>

<style>
    .past_due{
        font-weight:bold;
        color:#ffffff;
    }
</style>