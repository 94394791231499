<template>
    <ui-loading :is-loading="ui.loading">
        <div class="portlet light ">
            <div class="portlet-title">
                <div class="caption caption-md">
                    <i class="icon-bar-chart theme-font hide"></i>
                    <span class="caption-subject theme-font bold uppercase"><i
                            class="icon-directions"></i> Triggers</span>
                </div>
                <div class="actions btn-set">
                    <router-link
                            :to="{name: 'triggers.create'}"
                            class="btn btn-success">
                        <i class="fa fa-plus"></i> Cadastrar Novo
                    </router-link>
                </div>
            </div>
            <div class="portlet-body">
                <div class="row">
                    <div class="col-md-12">
                        <ui-pagination :pagination="ui.pagination" @paginate="toPage"></ui-pagination>
                        <table ref="searchTable" class="table">
                            <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Evento</th>
                                <th>Ação</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in ui.items">
                                <td>{{item.name}}</td>
                                <td>{{item.eventInfo.name}}</td>
                                <td>{{item.actionInfo.name}}</td>
                                <td>
                                    <router-link :to="{name: 'triggers.edit', params: {id: item.id}} "
                                                 class="btn btn-default">
                                        <i class="fa fa-pencil"></i>
                                    </router-link>
                                    <button type="button" class="btn btn-danger" @click="showDelete(item)">
                                        <i class="fa fa-trash-o"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <ui-pagination :pagination="ui.pagination" @paginate="toPage"></ui-pagination>
                    </div>
                </div>
            </div>
        </div>

        <ui-modal v-if="ui.deleteModal.show" @close="closeDelete()">
            <div slot="header">
                Deseja realmente remover esse Trigger?
            </div>
            <div slot="body">
                Ao remover um trigger, as ações são de efeito imediato, e qualquer evento que ocorreria após a exclusão
                será ignorada.
            </div>
            <div slot="footer">
                <button class="btn btn-default" @click="closeDelete()">
                    Cancelar
                </button>


                <button class="btn btn-danger" @click="doDelete()">
                    <i class="fa fa-trash"></i>
                </button>

            </div>
        </ui-modal>

    </ui-loading>
</template>

<script>
    export default {
        name: "List",

        data() {
            return {
                currentItem: {},
                ui: {
                    deleteModal: {
                        show: false
                    },
                    loading: false,
                    pagination: {
                        page: 0,
                        per_page: 50
                    },
                    items: []
                }
            }
        },

        mounted() {
            this.doSearch()
        },

        methods: {
            search() {
                const filters = {}

                this.request().block(this.$refs.searchTable).get('filtrando', `/triggers`, {
                    query: filters,
                    page: this.ui.pagination.page,
                    limit: this.ui.pagination.per_page
                }).then(response => {
                    this.ui.items = response.data
                    this.ui.pagination = response.meta.pagination
                })
            },


            showDelete(item) {
                this.currentItem = item
                this.ui.deleteModal.show = true
            },

            closeDelete() {
                this.currentItem = null
                this.ui.deleteModal.show = false
            },

            doDelete() {
                this.request().block(this.$refs.searchTable).del('deletando', `/triggers/${this.currentItem.id}`)
                    .then(this.closeDelete)
                    .then(this.doSearch)
            },

            doSearch() {
                this.ui.paginations = 0;
                this.search()
            },

            toPage(page) {
                this.ui.pagination.page = page
                this.search()
            }
        }
    }
</script>