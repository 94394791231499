var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "form", staticClass: "row" },
    [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "col-md-4" }, [
          _c("p", [
            _vm._v("\n        Status:\n        "),
            _c("strong", [_vm._v(_vm._s(_vm.statusText))])
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("\n        Instalado:\n        "),
            _c("strong", [
              _vm._v(
                "\n          " + _vm._s(_vm.installStatus) + "\n          "
              ),
              _c("i", { class: _vm.installClass })
            ])
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("\n        Data Inst.:\n        "),
            _vm.isInstalled && _vm.getConfig("installed").updated_at
              ? _c("strong", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("date")(_vm.getConfig("installed").updated_at)
                      ) +
                      "\n        "
                  )
                ])
              : _c("strong", [_vm._v("-")])
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("\n        Domínio:\n        "),
            _c("strong", [_vm._v(_vm._s(_vm.getConfig("domain").value))])
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("\n        Domínio principal:\n        "),
            _c("strong", [_vm._v(_vm._s(_vm.ui.mainDomain))])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-4" }, [
          _c("p", [
            _vm._v("\n        Modelo:\n        "),
            _c("strong", [_vm._v(_vm._s(_vm.currentSiteModel.label))])
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("\n        Valor Atual Online:\n        "),
            _vm.onlineCurrentSiteModel.label
              ? _c("strong", [_vm._v(_vm._s(_vm.onlineCurrentSiteModel.label))])
              : _c("strong", [_vm._v("-")])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "pull-right" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "form-actions" }, [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isInstalled,
                      expression: "!isInstalled"
                    }
                  ],
                  staticClass: "btn blue",
                  attrs: { type: "button" },
                  on: { click: _vm.install }
                },
                [_vm._v("Instalar")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isInstalled,
                      expression: "isInstalled"
                    }
                  ],
                  staticClass: "btn btn-danger",
                  attrs: { type: "button" },
                  on: { click: _vm.askUninstall }
                },
                [
                  _c("i", { staticClass: "fa fa-trash" }),
                  _vm._v("\n            Desintalar\n          ")
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "can",
                      rawName: "v-can",
                      value: "clients-dns",
                      expression: "'clients-dns'"
                    }
                  ],
                  staticClass: "btn btn-default",
                  attrs: { type: "button" },
                  on: { click: _vm.openDnsZone }
                },
                [_vm._v("Zonas DNS")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button" },
                  on: { click: _vm.showChangeModel }
                },
                [_vm._v("Trocar Modelo")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn",
                  class: _vm.statusButtonClass,
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.changeActiveStatus(_vm.$refs.form)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.statusButtonText))]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.ui.dnsZone
        ? _c("domain-modal", {
            attrs: {
              domains: _vm.domains,
              options: _vm.options,
              "client-product-id": parseInt(_vm.current.id),
              blocked: _vm.bloxkedMxZone
            },
            on: { close: _vm.closeDnsZone, change: _vm.setNewMainDomain }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.ui.changeModel
        ? _c("ui-modal", { on: { close: _vm.closeChangeModel } }, [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _vm._v("Mudar Modelo")
            ]),
            _vm._v(" "),
            _c("div", { attrs: { slot: "body" }, slot: "body" }, [
              _c(
                "div",
                { ref: "modelForm" },
                [
                  _c("ui-text", {
                    attrs: { value: _vm.currentSiteModel.label, disabled: true }
                  }),
                  _vm._v(" "),
                  _c("ui-select", {
                    attrs: {
                      options: _vm.options.productOptions.siteModels,
                      "label-name": "label"
                    },
                    model: {
                      value: _vm.siteModel,
                      callback: function($$v) {
                        _vm.siteModel = $$v
                      },
                      expression: "siteModel"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button" },
                  on: { click: _vm.closeChangeModel }
                },
                [_vm._v("Fechar")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button", id: "client_user-enviar" },
                  on: { click: _vm.changeModel }
                },
                [_vm._v("Salvar")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.ui.uninstallShow
        ? _c(
            "ui-modal",
            { attrs: { width: "500px" }, on: { close: _vm.closeAskUninstall } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Deletar Produto?")
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "text-center",
                  attrs: { slot: "body" },
                  slot: "body"
                },
                [
                  _c("h1", { staticClass: "text-danger" }, [
                    _c("strong", [_vm._v("A operação será definitiva")])
                  ]),
                  _vm._v(" "),
                  _c("h2", { staticClass: "text-danger" }, [
                    _vm._v("Não é feito backup!")
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Ao deletar este produto todas as informações referentes aos sites serão apagadas."
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: { click: _vm.closeAskUninstall }
                  },
                  [_vm._v("Fechar")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.performUninstall }
                  },
                  [_vm._v("Desinstalar")]
                )
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }