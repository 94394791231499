<template>
  <div class="col-md-12">
      <div class="row">
          <p class="col-md-12">
              Não há configurações para este produto!
          </p>
      </div>
 </div>
</template>

<script>
    export default {

    }
</script>
