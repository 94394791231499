import uniqid from 'uniqid'
import loadingImg from '@assets/imgs/loading.gif'
const qs = require('qs');
const axios = require('axios')
const _ = require("underscore")
const $ = require('jquery')
const toastr = require('toastr')

function showToaster(meta) {
    if (meta.kind !== 'global') {
        return
    }
    switch (meta.type) {
        case 'error':
            toastr.error(meta.message)
            return;
        case 'success':
            toastr.success(meta.message)
            return
        default:
            toastr.info(meta.message)
    }
}

class ParserResponse {


    constructor(response) {
        this.response = response
        this.parsed = false
        this.result = {}
        this.$element = null
    }

    parse() {
        this.response.data = this._doParse()

        return this.response;
    }

    _doParse() {
        if (this.parsed) {
            return this.result
        }

        if (_.isArray(this.response.data)) {
            return this.result = this.parseMultiples()
        }

        return this.result = this.parseItem(this.response.data)
    }

    parseMultiples() {
        let tmp = []

        for (let item in this.response.data) {
            tmp.push(this.parseItem(this.response.data[item]))
        }

        return tmp
    }

    parseItem(item) {
        if (!item.hasOwnProperty('attributes')) {
            return item
        }
        this._parseRelationship(item, item.attributes)

        return item.attributes
    }

    _parseRelationship(item, current = {}) {
        if (!item.hasOwnProperty("relationships")) {
            return current
        }

        for (let relation in item.relationships) {
            let value = item.relationships[relation]

            if (current.hasOwnProperty(value.type)) {

                if (!_.isArray(current[value.type])) {
                    let temp = []
                    temp.push(current[value.type])
                    current[value.type] = temp

                }

                current[value.type].push(value.attributes)

            } else {
                current[value.type] = value.attributes
            }
        }

        return current
    }
}

class ErrorShow {

    constructor(element, response) {
        this.element = element
        this.response = response
        this.formName = element.attr('data-name')
    }

    fieldName(name) {
        if (this.formName !== '' && this.formName !== undefined) {
            return `${this.formName}.${name}`
        }

        return name
    }

    findField(name) {
        return $(`[name=${this.fieldName(name)}]`).not('meta').first()
    }

    getFieldGroup(field) {
        return field.parents('.form-group')
    }

    hideAllErrors() {
        $('.has-error', this.element).removeClass('has-error').find('small.text-danger').remove()
    }

    displayError(field, messages) {
        let group = this.getFieldGroup(field)
        group.addClass('has-error')

        let small = $("<small></small>").addClass('text-danger').text(messages.join(' '))
        group.append(small)
    }

    show() {
        this.hideAllErrors()
        for (let item in this.response) {
            let field = this.findField(item)
            this.displayError(field, this.response[item])

        }
    }

}

class Request {

    constructor(store) {
        this.doBlock = false
        this.element = null
        this.store = store
    }

    block(element) {
        this.doBlock = true
        this.element = $(element)

        return this
    }

    _block() {
        const position = this.element.position()
        const item = `<div class="col-md-6 loading-added" style="
                           position: absolute;
                           z-index: 9;
                           left: 25%;
                           top: ${position.top}px;
                    "> 
                        <div class="portlet  text-center">
                            <h2>Carregando...</h2><br>
                            <img src="${loadingImg}" style="height: 89px;"/>
                        </div>
                    </div>`;

        this.element
            .addClass('requester-loading')

        this.element
            .parent()
            .append(item)
    }

    _clearBlock() {

        if (!this.doBlock) {
            return
        }
        this.element
            .removeClass('requester-loading')

        this.element.parent().find('.loading-added').remove()

    }

    showErrors(response) {
        try {
            let displayer = new ErrorShow(this.element, response.data)
            displayer.show()
        } catch (e) {
            console.log('Nao foi possivel exibir os erros!')
        }

    }


    execute(message, url, options) {
        let id = uniqid()
        if (this.doBlock) {
            this._block()
        }

        if (message && this.store) {
            this.store.commit('ADD_LOADING', { id: id, message: message })
        }

        return this.getRequester().request({
            ...options
        }).then(response => {

            return response
        })
            .catch(error => {

                this.showErrors(error)

                return Promise.reject(error)
            }).finally(() => {
                if (this.store) {
                    this.store.commit('REMOVE_LOADING', id)
                }

                this._clearBlock()
            })
    }

    put(message, url, params = {}) {
        return this.execute(message, url, {
            method: 'PUT',
            url: url,
            data: params
        })
    }

    post(message, url, params = {}) {
        return this.execute(message, url, {
            method: 'post',
            url: url,
            data: params
        })
    }

    del(message, url, params = {}) {
        return this.execute(message, url, {
            method: "DELETE",
            url: url,
            data: params
        })
    }

    get(message, url, params = {}) {

        return this.execute(message, url, {
            method: 'get',
            url: url,
            params: params,
            'paramsSerializer': function (params) {
                return qs.stringify(params)
            }
        })
    }

    getRequester() {

        let requester = null;
        const url = process.env.backendUrl;
        if (this.store) {
            requester = axios.create({
                baseURL: url,
                headers: {
                    accept: 'application/json',
                    authorization: `Bearer ${this.store.getters.TOKEN}`,
                    'x-authorization': `Bearer ${this.store.getters.TOKEN}`,
                },
                validateStatus: status => status > 0
            })
        } else {
            requester = axios.create({
                baseURL: url,
                headers: {
                    accept: 'application/json',
                },
                validateStatus: status => status > 0
            })
        }

        requester.interceptors.response.use((response) => {

            if (response.data.meta.warning !== undefined) {
                showToaster(response.data.meta.warning)
            }

            let parse = new ParserResponse(response.data)


            response.meta = response.data.meta
            response.data = parse.parse().data

            if (Math.floor(response.status / 100) > 2) {
                return Promise.reject(response)
            }

            return response
        })

        return requester
    }

}


export default Request