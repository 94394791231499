import Pusher from 'pusher-js'

const pusher = process.env.pusherKey ? new Pusher(process.env.pusherKey, {
    encrypted: true,
    cluster: 'us2'
}) : {};

export default {

    subscribe(channel) {
        return pusher.subscribe(channel)
    },

    getPusher() {
        return pusher
    }
}