<template>
    <div>
        <h4>O cliente será Ativado, e todos seus produtos irão voltar a funcionar.</h4>
    </div>

</template>

<script>
    export default {
        name: "ActiveClient"
    }
</script>

<style scoped>

</style>