var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ui-loading", { attrs: { "is-loading": _vm.ui.loading } }, [
    _c("div", [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "title" }, [
            _c("h3", [_vm._v("Dados gerais")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3" }, [
            _c("span", [
              _vm._v("Total Clients Ativos: "),
              _c("b", [_vm._v(_vm._s(_vm.ui.active_clients))])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3" }, [
            _c("span", [
              _vm._v("Ticket Médio: "),
              _c("b", [_vm._v(_vm._s(_vm._f("money")(_vm.ui.medium_ticket)))])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3" }, [
            _c("span", [
              _vm._v("Churning: "),
              _c("b", [_vm._v(_vm._s(_vm.ui.churning) + "%")])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3" }, [
            _c("span", [
              _vm._v("Inadimplência: "),
              _c("b", [_vm._v(_vm._s(_vm.ui.late_payers) + "%")])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "title" }, [
            _c("h3", [_vm._v("Dados do mês")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3" }, [
            _c("span", [
              _vm._v("Ativados:\n                        "),
              _c(
                "b",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "clients.index",
                          query: { status_classification: 1 }
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.ui.activated) +
                          "\n                            "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3" }, [
            _c("span", [
              _vm._v("Resgatados:\n                        "),
              _c(
                "b",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "clients.index",
                          query: { status_classification: 2 }
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.ui.rescued) +
                          "\n                            "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3" }, [
            _c("span", [
              _vm._v("Cancelados:\n                        "),
              _c(
                "b",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "clients.index",
                          query: { status_classification: 3 }
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.ui.canceled) +
                          "\n                            "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3" }, [
            _c("span", [
              _c("b", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://desciclopedia.org/wiki/42",
                      target: "_blank",
                      id: "id42"
                    }
                  },
                  [_vm._v("42")]
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "title" }, [
            _c("h3", [_vm._v("Últimos trials cadastrados")])
          ]),
          _vm._v(" "),
          _c(
            "table",
            {
              ref: "listTrials",
              staticClass: "table",
              staticStyle: { "border-top": "none" }
            },
            [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Código")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Nome")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Telefone")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("E-mail")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Data Entrada")]),
                  _vm._v(" "),
                  _c("th")
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.ui.trials, function(item) {
                  return _c("tr", [
                    _c("td", [
                      _vm._v(
                        "\n                            " +
                          _vm._s(item.code) +
                          "\n                            "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "http://" + item.code + ".imob.online",
                            target: "_blank"
                          }
                        },
                        [_c("i", { staticClass: "fa fa-external-link" })]
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(item.name))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(item.phone1))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(item.email))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm._f("date")(item.created_at)))]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "clients.edit.index",
                                params: { id: item.id }
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                                Mais detalhes\n                            "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                }),
                0
              )
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }