<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <button v-can="'add-plan-to-client'" class="btn blue" @click="showAddPlan">
                    Adicionar Plano
                </button>
            </div>
        </div>

        <div class="row" v-for="plan in client.plans">
            <product-details
                    :client-plan="plan"
                    :options="options">
            </product-details>
        </div>
        <ui-modal v-if="ui.plan.adding" @close="closeAddPlan" :loading='ui.plan.loading'>
            <div slot="header">
                <h4>Adicionando Plano</h4>
            </div>

            <div slot="body" ref="planBody">
                <ui-select
                        :options="ui.options.availablePlans"
                        label="Selecione o Plano"
                        v-model="current.plan_id"></ui-select>

                <ui-loading :is-loading='ui.plan.loadingSelected' v-if='ui.plan.showSelected'>
                    <add :plan="current.plan"></add>
                </ui-loading>
            </div>

            <div slot="footer" v-if="ui.plan.showSelected">
                <button type="button" class="btn btn-default" @click="closeAddPlan">Fechar</button>
                <button type="button" id="client_user-enviar" class="btn btn-primary" @click="addPlan">Salvar</button>
            </div>
        </ui-modal>

    </div>
</template>
<style scoped>


</style>
<script>
    import add from './products/Add.vue';
    import productDetails from './products/Details.vue';
    const uniq = require('uniqid')

    export default {
        name: "plans",

        props: {
            client: {
                default: {}
            },

            options: {
                default: {}
            }
        },

        data() {
            return {
                ui: {
                    plan: {
                        adding: false,
                        loading: true,
                        loadingSelected: false,
                        showSelected: false
                    },
                    options: {
                        availablePlans: []
                    }
                },

                current: {
                    //Duplicado p/ evitar requests infinitos.
                    plan_id: null,
                    plan: {
                        id: null,
                        products: []
                    }
                }
            }
        },

        computed: {
        },

        mounted() {
            this.$watch('current.plan_id', function (val) {
                this.ui.plan.loadingSelected = true

                if (val === null) {
                    return;
                }

                this.request().get('Carregando produtos do plano', `plans/${val}`)
                    .then((response) => {
                        this.ui.plan.loadingSelected = false
                        this.ui.plan.showSelected = true
                        this.current.plan = response.data
                    })
            }, {deep: true})

        },

        methods: {

            loadPlans() {
                this.ui.planLoading = true
                this.request().get('Carregando Planos Instalaveis', `clients/${this.client.id}/available-plans`)
                    .then(response => {
                        this.ui.plan.loading = false
                        this.ui.options.availablePlans = response.data
                    })
            },

            closeAddPlan() {
                this.current.plan_id = null
                this.ui.plan.adding = false
            },

            showAddPlan() {
                this.loadPlans()
                this.ui.plan.showSelected = false
                this.ui.plan.adding = true
            },

            addPlan() {
                this.request().block(this.$refs.planBody).post("Salvando", `client-plans`, {
                    client_id: this.client.id,
                    plan_id: this.current.plan_id
                }).then(response => {
                    let client = _.cloneDeep(this.client)
                    client.plans.push(response.data)
                    this.$emit('change', client)
                    this.closeAddPlan()
                });
            }
        },

        components: {
            add,
            productDetails,
        }

    }
</script>

<style scoped>

</style>