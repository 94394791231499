<template>
  <div class="row" ref="form">
    <div class="col-md-12">
      <div class="col-md-4">
        <p>
          Status:
          <strong>{{statusText}}</strong>
        </p>
        <p>
          Instalado:
          <strong>
            {{installStatus}}
            <i :class="installClass"></i>
          </strong>
        </p>
        <p>
          Data Inst.:
          <strong v-if="isInstalled && getConfig('installed').updated_at">
            {{getConfig('installed').updated_at
            | date}}
          </strong>
          <strong v-else>-</strong>
        </p>
        <p>
          Domínio:
          <strong>{{getConfig('domain').value}}</strong>
        </p>
        <p>
          Domínio principal:
          <strong>{{ui.mainDomain}}</strong>
        </p>
      </div>

      <div class="col-md-4">
        <p>
          Modelo:
          <strong>{{currentSiteModel.label}}</strong>
        </p>

        <p>
          Valor Atual Online:
          <strong
            v-if="onlineCurrentSiteModel.label"
          >{{onlineCurrentSiteModel.label}}</strong>
          <strong v-else>-</strong>
        </p>
      </div>
    </div>

    <div class="col-md-12">
      <div class="pull-right">
        <div class="col-md-12">
          <div class="form-actions">
            <button @click="install" class="btn blue" type="button" v-show="!isInstalled">Instalar</button>
            <button type="button" class="btn btn-danger" @click="askUninstall" v-show="isInstalled">
              <i class="fa fa-trash"></i>
              Desintalar
            </button>
            <button
              v-can="'clients-dns'"
              type="button"
              class="btn btn-default"
              @click="openDnsZone"
            >Zonas DNS</button>
            <button type="button" class="btn btn-default" @click="showChangeModel">Trocar Modelo</button>

            <button
              @click="changeActiveStatus($refs.form)"
              type="button"
              class="btn"
              :class="statusButtonClass"
            >{{statusButtonText}}</button>
          </div>
        </div>
      </div>
    </div>

    <domain-modal
      v-if="ui.dnsZone"
      @close="closeDnsZone"
      :domains="domains"
      :options="options"
      :client-product-id="parseInt(current.id)"
      :blocked="bloxkedMxZone"
      @change="setNewMainDomain"
    ></domain-modal>

    <ui-modal v-if="ui.changeModel" @close="closeChangeModel">
      <div slot="header">Mudar Modelo</div>

      <div slot="body">
        <div ref="modelForm">
          <ui-text :value="currentSiteModel.label" :disabled="true"></ui-text>
          <ui-select
            :options="options.productOptions.siteModels"
            v-model="siteModel"
            label-name="label"
          ></ui-select>
        </div>
      </div>

      <div slot="footer">
        <button type="button" class="btn btn-default" @click="closeChangeModel">Fechar</button>
        <button
          type="button"
          id="client_user-enviar"
          @click="changeModel"
          class="btn btn-primary"
        >Salvar</button>
      </div>
    </ui-modal>

    <ui-modal v-if="ui.uninstallShow" @close="closeAskUninstall" width="500px">
      <div slot="header">Deletar Produto?</div>

      <div slot="body" class="text-center">
        <h1 class="text-danger">
          <strong>A operação será definitiva</strong>
        </h1>
        <h2 class="text-danger">Não é feito backup!</h2>
        <p>Ao deletar este produto todas as informações referentes aos sites serão apagadas.</p>
      </div>

      <div slot="footer">
        <button type="button" class="btn btn-default" @click="closeAskUninstall">Fechar</button>
        <button type="button" @click="performUninstall" class="btn btn-primary">Desinstalar</button>
      </div>
    </ui-modal>
  </div>
</template>

<script>
import _ from "lodash";
import mixin from "./mixin";
import DomainModal from "./_partials/DomainModal.vue";
export default {
  name: "core-sci-online",

  data() {
    return {
      ui: {
        mainDomain: "",
        changeModel: false,
        dnsZone: false,
        dnsLoading: false,
        uninstallShow: false,
        domains: [],
        domain: {
          pagination: {},
          search: ""
        }
      },
      siteModel: this.currentSiteModel,
      domain: {
        url: null
      }
    };
  },

  components: {
    domainModal: DomainModal
  },
  computed: {
    domains() {
      return JSON.parse(this.getConfig("domains_urls", { value: "{}" }).value);
    },

    bloxkedMxZone() {
      const tmp = _.get(this.configs, "blocked_mx_zone.value", "");

      if (!tmp) {
        return null;
      }

      let json = JSON.parse(tmp);
      let data = {};

      _.forEach(json, (value, key) => {
        data[key] = { id: key, mx: value };
      });

      return data;
    },

    currentSiteModel() {
      let key = this.getConfig("model", { value: "-" }).value;

      if (this.options.productOptions.siteModels[key] !== undefined) {
        return this.options.productOptions.siteModels[key];
      }

      return { label: "Não Selecionado", id: null };
    },

    onlineCurrentSiteModel() {
      if (!_.has(this.current, "managerConfigs.online.model")) {
        return { label: "Sem Informações" };
      }

      let currentModelOnline = _.get(
        this.current,
        "managerConfigs.online.model"
      );

      return _.find(this.options.productOptions.siteModels, {
        id: currentModelOnline.value
      });
    }
  },
  mounted() {
    this.ui.mainDomain = this.current.managerConfigs.main_domain;
  },
  methods: {
    askUninstall() {
      this.ui.uninstallShow = true;
    },
    closeAskUninstall() {
      this.ui.uninstallShow = false;
    },
    setNewMainDomain(domain) {
      this.ui.mainDomain = domain;
    },
    showChangeModel() {
      this.ui.changeModel = true;
    },

    closeChangeModel() {
      this.ui.changeModel = false;
    },

    changeModel() {
      this.saveConfig(this.$refs.modelForm, "model", this.siteModel);
    },

    closeDnsZone() {
      this.ui.dnsZone = false;
    },

    openDnsZone() {
      this.ui.dnsZone = true;
    },
    performUninstall() {
      this.uninstall().then(() => this.closeAskUninstall());
    }
  },

  mixins: [mixin]
};
</script>

<style scoped>
</style>
