var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "button",
            {
              directives: [
                {
                  name: "can",
                  rawName: "v-can",
                  value: "add-plan-to-client",
                  expression: "'add-plan-to-client'"
                }
              ],
              staticClass: "btn blue",
              on: { click: _vm.showAddPlan }
            },
            [_vm._v("\n                Adicionar Plano\n            ")]
          )
        ])
      ]),
      _vm._v(" "),
      _vm._l(_vm.client.plans, function(plan) {
        return _c(
          "div",
          { staticClass: "row" },
          [
            _c("product-details", {
              attrs: { "client-plan": plan, options: _vm.options }
            })
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.ui.plan.adding
        ? _c(
            "ui-modal",
            {
              attrs: { loading: _vm.ui.plan.loading },
              on: { close: _vm.closeAddPlan }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("h4", [_vm._v("Adicionando Plano")])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { ref: "planBody", attrs: { slot: "body" }, slot: "body" },
                [
                  _c("ui-select", {
                    attrs: {
                      options: _vm.ui.options.availablePlans,
                      label: "Selecione o Plano"
                    },
                    model: {
                      value: _vm.current.plan_id,
                      callback: function($$v) {
                        _vm.$set(_vm.current, "plan_id", $$v)
                      },
                      expression: "current.plan_id"
                    }
                  }),
                  _vm._v(" "),
                  _vm.ui.plan.showSelected
                    ? _c(
                        "ui-loading",
                        {
                          attrs: { "is-loading": _vm.ui.plan.loadingSelected }
                        },
                        [_c("add", { attrs: { plan: _vm.current.plan } })],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.ui.plan.showSelected
                ? _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: { click: _vm.closeAddPlan }
                      },
                      [_vm._v("Fechar")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button", id: "client_user-enviar" },
                        on: { click: _vm.addPlan }
                      },
                      [_vm._v("Salvar")]
                    )
                  ])
                : _vm._e()
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }