const _ = require('lodash')

export default {
    props: {
        clientProduct: {
            default: {}
        },
        options: {
            default: () => {
            }
        }
    },

    data() {
        return {
            current: this.clientProduct,
        }
    },
    computed: {
        configs() {
            let val = {}

            this.current.configs.forEach(i => {
                val[i.name] = i
            })

            return val
        },

        status() {
            const val = this.getConfig('active', { value: false })

            return isNaN(parseInt(val.value)) ? val.value : parseInt(val.value)
        },

        statusText() {
            return this.status ? "Ativo" : "Inativo"
        },

        installed() {
            const val = this.getConfig('installed', false)

            return val !== false && val.value ? "Sim" : "Não"
        },

        isInstalled() {
            const val = this.getConfig('installed', false)

            return isNaN(parseInt(val.value)) ? val.value : parseInt(val.value) === 1
        },

        installClass() {

            let current = this.getConfig('installed', { value: null }).value

            if (current === undefined) {
                return {}
            }

            current = parseInt(current)

            return {
                'fa fa-warning text-danger': current === 2,
                'fa fa-play text-success': current === 3,
                'fa fa-check text-success': current === 1,
            }
        },

        installStatus() {
            const current = _.find(this.options.clientPlanOptions.installStatus,
                i => i.id == this.getConfig('installed', { value: null }).value)

            if (current === undefined) {
                return '-'
            }

            return current.name
        },

        statusButtonText() {
            return this.status ? "Suspender" : "Ativar"
        },

        statusButtonClass() {
            return {
                'btn-danger': this.status,
                'blue': !this.status
            }
        },

        statusButtonIcon() {
            return {
                'fa fa-arrow-circle-up': !this.status,
                'fa fa-arrow-circle-down': this.status
            }
        }

    },

    methods: {

        install() {
            this
                .request()
                .block(this.$el)
                .post(`Instalando ${this.clientProduct.name}`, `/client-products/${this.clientProduct.id}/install`)
                .then(response => {
                    this.$emit('update', this.$emit('update', response.data))
                })

        },

        uninstall() {
            return this
                .request()
                .block(this.$el)
                .post(`Instalando ${this.clientProduct.name}`, `/client-products/${this.clientProduct.id}/uninstall`)
                .then(response => {
                    this.$emit('update', this.$emit('update', response.data))
                })
        },

        getConfig(configName, defaultValue = false) {
            let index = _.findIndex(this.clientProduct.configs, i => i.name === configName)

            if (index >= 0) {
                return this.clientProduct.configs[index];
            }

            return defaultValue;

        },

        changeActiveStatus(ref) {
            this.saveConfig(ref, 'active', !this.status)
        },

        saveConfig(ref, configName, value) {

            this.request().block(ref).post('Alterando a Configuração', `client-products/${this.current.id}/save-config`, {
                name: configName,
                value: value
            })
                .then((response) => {
                    this.updateConfig(configName, response.data)
                    return

                    let key = this.current.configs.findIndex(i => i.name === configName)

                    if (key < 0) {
                        this.current.configs.push(response.data.data)
                        return
                    }
                    this.current.configs[key].value = value
                })
                .then(() => {
                    this.$emit("update", this.current)
                })
        },


        updateConfig(configName, data) {
            let key = this.current.configs.findIndex(i => i.name === configName)

            if (key < 0) {
                this.current.configs.push(data)
                return
            }
            this.$set(this.current.configs, key, data)
        }
    }
}