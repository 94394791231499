var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c(
        "form",
        {
          staticClass: "login-form",
          attrs: { method: "POST" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.doLogin($event)
            }
          }
        },
        [
          _vm._m(1),
          _vm._v(" "),
          _vm.ui.message.text
            ? _c(
                "div",
                {
                  staticClass: "alert display-hide",
                  class: {
                    "alert-danger": _vm.ui.message.type == "error",
                    "alert-success": _vm.ui.message.type == "success"
                  },
                  staticStyle: { display: "block" }
                },
                [
                  _c("button", {
                    staticClass: "close",
                    attrs: { "data-close": "alert" }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.ui.message.text))])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "alert alert-danger display-hide" }, [
            _c("button", {
              staticClass: "close",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.ui.message.text = null
                }
              }
            }),
            _vm._v(" "),
            _c("span", [_vm._v("Insira seu usuário e senha. ")])
          ]),
          _vm._v(" "),
          _c("ui-text", {
            attrs: { placeholder: "E-mail" },
            on: { submit: _vm.doLogin },
            model: {
              value: _vm.login.email,
              callback: function($$v) {
                _vm.$set(_vm.login, "email", $$v)
              },
              expression: "login.email"
            }
          }),
          _vm._v(" "),
          _c("ui-text", {
            attrs: { type: "password", placeholder: "Senha" },
            on: { submit: _vm.doLogin },
            model: {
              value: _vm.login.password,
              callback: function($$v) {
                _vm.$set(_vm.login, "password", $$v)
              },
              expression: "login.password"
            }
          }),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              { staticClass: "pull-left" },
              [
                _c("ui-check", {
                  attrs: { label: "Lembrar-me" },
                  model: {
                    value: _vm.login.remember_me,
                    callback: function($$v) {
                      _vm.$set(_vm.login, "remember_me", $$v)
                    },
                    expression: "login.remember_me"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "pull-right forget-password-block" }, [
              _c(
                "a",
                {
                  staticClass: "forget-password",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.recovery($event)
                    }
                  }
                },
                [_vm._v("Esqueceu sua senha?")]
              )
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("a", { attrs: { href: "index.html" } }, [
        _c("img", {
          attrs: { src: require("@assets/imgs/login-logo.png"), alt: "" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-title" }, [
      _c("span", { staticClass: "form-title" }, [_vm._v("Bem vindo.")]),
      _vm._v(" "),
      _c("span", { staticClass: "form-subtitle" }, [
        _vm._v("Por favor faça seu login.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-actions" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary btn-block uppercase",
          attrs: { type: "submit" }
        },
        [_vm._v("Acessar")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }