var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _vm.label.length
      ? _c("label", { staticClass: "control-label", attrs: { for: _vm.id } }, [
          _vm._v(_vm._s(_vm.label) + "\n        "),
          _vm.required
            ? _c("i", {
                staticClass: "glyphicon glyphicon-asterisk required-icon"
              })
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "select",
      {
        staticClass: "form-control",
        attrs: { required: _vm.required, id: _vm.id, name: _vm.name },
        on: {
          change: function($event) {
            return _vm.itemSelected($event.target.value)
          }
        }
      },
      [
        _c("option", { domProps: { value: null } }, [
          _vm._v(_vm._s(_vm.placeholder))
        ]),
        _vm._v(" "),
        _vm._l(_vm.options, function(item, key) {
          return _c(
            "option",
            {
              attrs: { disabled: _vm.canSelect(item) },
              domProps: {
                value: _vm.getKey(item, key),
                selected: _vm.isSelected(_vm.value, item, key)
              }
            },
            [_vm._v(" " + _vm._s(item[_vm.labelName]) + "\n        ")]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }