var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _vm.label !== false
      ? _c("label", { staticClass: "control-label", attrs: { for: _vm.id } }, [
          _vm._v(_vm._s(_vm.label))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.required
      ? _c("i", { staticClass: "glyphicon glyphicon-asterisk required-icon" })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        class: {
          "input-group": _vm.inputGroup || _vm.icon
        }
      },
      [
        _vm.icon
          ? _c("span", { staticClass: "input-group-addon" }, [
              _c("i", { class: _vm.iconClass })
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "currency",
              rawName: "v-currency",
              value: {
                currency: "BRL",
                autoDecimalMode: true
              },
              expression:
                "{\n        currency: 'BRL',\n        autoDecimalMode:true\n      }"
            }
          ],
          ref: "element",
          staticClass: "form-control",
          attrs: {
            id: _vm.id,
            name: _vm.name,
            placeholder: _vm.placeholder,
            required: _vm.required,
            disabled: _vm.disabled,
            readonly: _vm.readonly
          },
          domProps: { value: _vm.value },
          on: {
            keyup: [
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.$emit("submit")
              },
              function($event) {
                return _vm.updateValue($event.target.value)
              }
            ]
          }
        }),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    ),
    _vm._v(" "),
    _vm.hint
      ? _c("small", { staticClass: "form-text text-muted" }, [
          _vm._v(_vm._s(_vm.hint))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }