<template>
    <h1>Detalhes do produto {{product.name}}</h1>
</template>

<script>

    export default {
        name: "placeholder",
    }
</script>

<style scoped>

</style>