import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=2ec23162&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ec23162",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/buddy/central-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2ec23162')) {
      api.createRecord('2ec23162', component.options)
    } else {
      api.reload('2ec23162', component.options)
    }
    module.hot.accept("./Header.vue?vue&type=template&id=2ec23162&scoped=true&", function () {
      api.rerender('2ec23162', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/routes/clients/component/_partials/products/Details/Header.vue"
export default component.exports