<template>
  <div class="row">
    <div class="col-md-6">
      <div class="col-md-12">
        <ui-select
          label="Periodicidade"
          :options="ui.periodicity"
          placeholder="Periodicidade"
          name="status"
          v-model="current.periodicity"
        ></ui-select>
        <ui-money label="Valor total" v-model="current.value" />
        <ui-text v-model="current.times" type="number" label="Número de parcelas"></ui-text>
      </div>
    </div>
    <div class="col-md-6">
      <div class="col-md-12">
        <label for>
          Recorrencia:
          <span v-if="this.current.periodicity > 0">{{this.recurrency | money}}</span>
          <span v-else>Não é uma recorrência</span>
        </label>
      </div>
      <div class="col-md-12">
        <label for>
          ID:
          <span>{{this.current.id}}</span>
        </label>
      </div>
    </div>
    <div class="col-md-12">
      <div class="pull-right">
        <div class="col-md-6">
          <button class="btn btn-info" @click="update">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const _ = require("lodash");
export default {
  name: "PlanDetails",
  props: {
    clientPlan: {
      default: {}
    }
  },

  data() {
    let current = _.cloneDeep(this.clientPlan);
    return {
      current,
      ui: {
        periodicity: [
          {
            id: 0,
            name: "Não é uma recorrência"
          },
          {
            id: 1,
            name: "mensal (1 mês)"
          },
          {
            id: 2,
            name: "bimestral (2 meses)"
          },
          {
            id: 3,
            name: "Trimestral (3 meses)"
          },
          {
            id: 6,
            name: "Semestral (6 meses)"
          },
          {
            id: 12,
            name: "Anual (12 meses)"
          }
        ]
      }
    };
  },

  computed: {
    recurrency() {
      if (this.current.periodicity === 0) {
        return 0;
      }
      return this.current.value / this.current.periodicity;
    }
  },

  methods: {
    update() {
      this.request().put(
        "Atualizando plano",
        `/client-plans/${this.current.id}`,
        {
          ...this.current
        }
      );
    }
  }
};
</script>