import vuex from 'vuex'
import uniqid from 'uniqid'


export let state = {
    loadings: [
    ],
}

export const getters = {
    LOADING_MESSAGES: (state) => state.loadings
}

export const mutations = {
    ADD_LOADING(state, loading) {
        if (!loading.hasOwnProperty('id')) {
            loading.id = uniqid()
        }

        state.loadings.push(loading)
    },

    REMOVE_LOADING(state, id) {
        const toRemove = state.loadings.filter(i => i.id === id)
        if (toRemove.length > 0) {
            state.loadings.splice(state.loadings.indexOf(toRemove[0]), 1)
        }
    }
}

export const actions = {
    ADD_LOADING(context, loading) {
        context.commit('ADD_LOADING', loading)
    },

    REMOVE_LOADING(context, loadId) {
        context.commit("REMOVE_LOADING", loadId)
    }
}
