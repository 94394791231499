import _ from 'lodash'
import moment from 'moment'
const clientsFields = [
    {
        id: 'days_to_due',
        name: 'Dias até o vencimento',
        alias: '{{days_to_due}}'
    },
    {
        id: 'days_past_due',
        name: 'Dias Após Vencimento',
        alias: '{{days_past_due}}'
    },
    {
        id: 'second_invoice_link',

        name: "Link Para boleto de pagamento",

        alias: '{{second_invoice_link}}',

        types: ['exists'],

        enum: [
            {
                name: 'Existir',
                id: 1
            },
            {
                name: 'Não Existir',
                id: 0
            }
        ]
    },
    {
        id: 'ignore_block',
        name: 'Ignorar Bloqueio automático',
        alias: '{{ignore_block}}',
        types: ['equals'],
        enum: [
            {
                name: 'Sim',
                id: 1
            },
            {
                name: 'Não',
                id: 0
            }
        ]
    },
    {
        id: 'status',
        name: "Status",
        alias: '{{status}}',
        types: ['equals'],
        enum: [
            {
                name: 'Ativo',
                id: 1,
            },
            {
                name: 'Suspenso',
                id: 2,
            },
            {
                name: 'Cancelado',
                id: 3,
            },
            {
                name: 'Pré cliente',
                id: 4
            }
        ]
    },
    {
        id: 'cancel_date',
        name: 'Data de cancelamento',
        alias: '-',
        component: 'date',

        message: (component) => {
            if (component.today) {
                return 'Será cancelado no dia de cancelamento.'
            }

            return 'Será cancelado na data.' + moment(component.cancel_date).format('DD/MM/YYYY')
        }
    }
]

export default {
    'events.client.updated': clientsFields,
    'events.installment-check': clientsFields,
    components: {
        date: require('./_partials/fields/Date.vue')
    }
}