<template>
    <div>
        <button  type="button" class="btn btn-primary blue" style="margin-bottom: 15px" @click="addCondition">
            <i class="fa fa-plus-circle"></i>
            Adicionar Condição
        </button>

        <div class="list-group">
            <div class="list-group-item" v-for="(item, index) in conditions">

                <condition :conditions="item"
                           :fields="fields"
                           @change="value => updateValues(value, index)"
                           @remove="removeCondition(index)">
                </condition>

            </div>
        </div>

    </div>
</template>

<script>
    import Condition from './Condition.vue';
    export default {
        name: "Conditions",
        
        props: {
            conditions: {
                required: true,
            },
            fields: {
                required: true
            }
        },

        components: {
            condition: Condition
        },

        methods: {
            updateValues(value, index) {

                this.$set(this.conditions, index, value)
                this.$emit('change', this.conditions)
            },
            addCondition() {
                this.conditions.push({})
            },

            removeCondition(index) {
                this.conditions.splice(index, 1)
            }
        },
    }
</script>

<style scoped>

</style>