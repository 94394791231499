var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ui-loading", { attrs: { "is-loading": _vm.ui.loading } }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _vm.client.id
          ? _c(
              "div",
              {
                staticClass: "profile-sidebar",
                staticStyle: { width: "250px" }
              },
              [
                _c(
                  "div",
                  { staticClass: "portlet light profile-sidebar-portlet" },
                  [
                    _c("div", { staticClass: "profile-userpic" }, [
                      _c("img", {
                        staticClass: "img-responsive",
                        attrs: {
                          src: require("@assets/imgs/realestate-profile.png"),
                          alt: ""
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "profile-usertitle" }, [
                      _c("div", { staticClass: "profile-usertitle-name" }, [
                        _vm._v(_vm._s(_vm.client.name))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "profile-userbuttons" }, [
                      _c(
                        "span",
                        {
                          staticClass: "label",
                          class: {
                            "label-success": _vm.client.status === 1,
                            "label-warning": _vm.client.status === 2,
                            "label-danger": _vm.client.status === 3,
                            "label-info": _vm.client.status === 4
                          }
                        },
                        [_vm._v(_vm._s(_vm.client.statusInfo.name))]
                      ),
                      _vm._v(" "),
                      _vm.client.id
                        ? _c("span", { staticClass: "label label-primary" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.client.code) +
                                "\n\n                        "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.client.id
                        ? _c("span", { staticClass: "label label-primary" }, [
                            _vm._v(
                              "\n                            Não Definido\n                        "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.client.in_debt
                        ? _c(
                            "span",
                            {
                              staticClass: "label  label-primary label-success"
                            },
                            [_vm._v("Pagamento em Dia!")]
                          )
                        : _c(
                            "span",
                            { staticClass: "label label-danger past_due" },
                            [_vm._v("Em Débito!")]
                          ),
                      _vm._v(" "),
                      _vm.client.days_past_due
                        ? _c(
                            "span",
                            { staticClass: "label label-danger past_due" },
                            [_vm._v(_vm._s(_vm.client.days_past_due) + "d")]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "portlet light" },
                      [
                        _c(
                          "div",
                          { staticClass: "margin-top-10 profile-desc-link" },
                          [
                            _c("span", [
                              _vm._v(
                                "Recorrência: R$" +
                                  _vm._s(_vm.client.recurrency)
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _vm.client.non_payment_rate
                          ? _c(
                              "div",
                              {
                                staticClass: "margin-top-10 profile-desc-link"
                              },
                              [
                                _vm.client.non_payment_rate &&
                                _vm.client.non_payment_rate > 0
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "label",
                                        class: {
                                          "label-danger":
                                            _vm.client.non_payment_rate > 50,
                                          "label-warning":
                                            _vm.client.non_payment_rate > 30 &&
                                            _vm.client.non_payment_rate <= 50,
                                          "label-success":
                                            _vm.client.non_payment_rate <= 30
                                        },
                                        attrs: { title: "Atrasos" }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-thumbs-down",
                                          staticStyle: { color: "inherit" }
                                        }),
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.client.non_payment_rate
                                            ) +
                                            "%\n                                "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "margin-top-10 profile-desc-link" },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.client.domain,
                                  target: "_blank"
                                }
                              },
                              [_vm._v(_vm._s(_vm.client.domain))]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.client.users, function(user) {
                          return _c("div", [
                            user.phone
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "margin-top-10 profile-desc-link",
                                    attrs: { title: user.phone }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-phone" }),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(user.phone) +
                                        "\n                            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        }),
                        _vm._v(" "),
                        _vm.client.sci !== null &&
                        _vm.client.sci.users_usage > 0
                          ? _c(
                              "div",
                              {
                                staticClass: "margin-top-10 profice-desc-link"
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "Usuários: " +
                                      _vm._s(_vm.client.sci.current_users) +
                                      " / " +
                                      _vm._s(_vm.client.sci.total_users) +
                                      " (" +
                                      _vm._s(
                                        Math.floor(_vm.client.sci.users_usage)
                                      ) +
                                      "%)"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "progress" }, [
                                  _c("div", {
                                    staticClass: "progress-bar",
                                    style: {
                                      width: _vm.client.sci.users_usage + "%"
                                    }
                                  })
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.client.sci !== null &&
                        _vm.client.sci.properties_usage > 0
                          ? _c(
                              "div",
                              {
                                staticClass: "margin-top-10 profice-desc-link"
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "Imóveis: " +
                                      _vm._s(
                                        _vm.client.sci.current_properties
                                      ) +
                                      " / " +
                                      _vm._s(_vm.client.sci.total_properties) +
                                      " (" +
                                      _vm._s(
                                        Math.floor(
                                          _vm.client.sci.properties_usage
                                        )
                                      ) +
                                      "%)"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "progress" }, [
                                  _c("div", {
                                    staticClass: "progress-bar",
                                    style: {
                                      width:
                                        _vm.client.sci.properties_usage + "%"
                                    }
                                  })
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "margin-top-10 profice-desc-link" },
                          [
                            _c("a", { attrs: { href: _vm.portalUrl } }, [
                              _vm._v("Link Epungo "),
                              _c("i", { staticClass: "fa fa-external-link" })
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "margin-top-10 profice-desc-link" },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "http://" +
                                    _vm.client.code +
                                    ".imob.online",
                                  target: "_blank"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.client.code) +
                                    ".imob.online "
                                ),
                                _c("i", { staticClass: "fa fa-external-link" })
                              ]
                            )
                          ]
                        )
                      ],
                      2
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "profile-content" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "portlet light" }, [
                _c("div", { staticClass: "portlet-title tabbable-line" }, [
                  _c("div", { staticClass: "caption caption-md" }, [
                    _c("i", { staticClass: "icon-globe theme-font hide" }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "caption-subject font-blue-madison bold uppercase"
                      },
                      [
                        _c("i", { staticClass: "icon-users" }),
                        _vm._v(" Informações da Imobiliária")
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "nav nav-tabs" }, [
                    _c(
                      "li",
                      {
                        class: {
                          active: _vm.currentLabel === "index"
                        }
                      },
                      [
                        _vm.client.id
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "clients.edit.index",
                                    params: { id: _vm.client.id }
                                  },
                                  tag: "a"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                            Informações\n                                        "
                                )
                              ]
                            )
                          : _c("a", { attrs: { href: "" } }, [
                              _vm._v("Informações")
                            ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.client.id
                      ? _c(
                          "li",
                          {
                            class: {
                              active: _vm.currentLabel === "tickets"
                            }
                          },
                          [
                            _vm.client.id
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "clients.view.tickets",
                                        params: { id: _vm.client.id }
                                      },
                                      tag: "a"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                            Chamados\n                                        "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.client.id
                      ? _c(
                          "li",
                          {
                            class: {
                              active: _vm.currentLabel === "histories"
                            }
                          },
                          [
                            _vm.client.id
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "clients.view.histories",
                                        params: { id: _vm.client.id }
                                      },
                                      tag: "a"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                            Histórico\n                                        "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.client.id
                      ? _c(
                          "li",
                          {
                            class: {
                              active: _vm.currentLabel === "notes"
                            }
                          },
                          [
                            _vm.client.id
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "clients.edit.notes",
                                        params: { id: _vm.client.id }
                                      },
                                      tag: "a"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                            Atividades\n                                        "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.client.id
                      ? _c(
                          "li",
                          {
                            class: {
                              active: _vm.currentLabel === "users"
                            }
                          },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "clients.edit.users",
                                    params: { id: _vm.client.id }
                                  },
                                  tag: "a"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                            Contatos e Usuários\n                                        "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.client.id
                      ? _c(
                          "li",
                          {
                            class: {
                              active: _vm.currentLabel === "plans"
                            }
                          },
                          [
                            _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "can",
                                    rawName: "v-can",
                                    value: "view-clients-plan",
                                    expression: "'view-clients-plan'"
                                  }
                                ],
                                attrs: {
                                  to: {
                                    name: "clients.edit.plans",
                                    params: { id: _vm.client.id }
                                  },
                                  tag: "a"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                            Planos e Produtos\n                                        "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.client.id
                      ? _c(
                          "li",
                          {
                            class: {
                              active: _vm.currentLabel === "installments"
                            }
                          },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "clients.edit.installments",
                                    params: { id: _vm.client.id }
                                  },
                                  tag: "a"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                            Cobranças\n                                        "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "portlet-body" }, [
                  _c("div", { staticClass: "tab-content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane active",
                        attrs: { id: "tab_1_1" }
                      },
                      [
                        _c(
                          "transition",
                          { attrs: { name: "fade" } },
                          [
                            _c("router-view", {
                              attrs: {
                                client: _vm.client,
                                options: _vm.ui.options
                              },
                              on: {
                                change: function(i) {
                                  return (_vm.client = i)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }