var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c(
      "div",
      {
        staticClass: "modal fade in",
        staticStyle: { display: "block" },
        attrs: {
          id: "emptyModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "emptyModalTitle"
        },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.backDropClick($event)
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-sm",
            style: { width: _vm.width },
            attrs: { role: "document" },
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c("div", {
              staticClass: "fade",
              attrs: { id: "emptyModalEventHandler" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                { staticClass: "modal-header" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: {
                        type: "button",
                        "data-dismiss": "modal",
                        "aria-label": "Close"
                      },
                      on: { click: _vm.close }
                    },
                    [
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _vm._v("×")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm._t("header")
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _c(
                    "ui-loading",
                    { attrs: { "is-loading": _vm.loading } },
                    [_vm._t("body")],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [_vm._t("footer")], 2)
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }