<template>
  <div class="row">
    <div class="col-md-12">
      <h2>{{plan.name}}</h2>
      <hr />
      <div class="row" v-for="(item, key) in plan.configurations" :key="key">
        <div class="col-md-12">
          <h4>Configurações: {{item.product_name}}</h4>
        </div>
        <hr />
        <component :is="requireComponent(item)" :client-product="item"></component>
      </div>
    </div>
  </div>
</template>

<script>
const adds = require("./Add/index");

export default {
  name: "add-product",

  components: {
    ...require("./Add/index")
  },

  props: {
    plan: {
      default: {
        name: ""
      }
    }
  },

  methods: {
    requireComponent(item) {
      let slug = item.product_slug;

      if (adds.hasOwnProperty(slug)) {
        return slug;
      }

      return "placeholder";
    }
  }
};
</script>