var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "portlet light " }, [
        _c("div", { staticClass: "portlet-title" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "actions btn-set" },
            [
              _c(
                "router-link",
                {
                  directives: [
                    {
                      name: "can",
                      rawName: "v-can",
                      value: "create-user",
                      expression: "'create-user'"
                    }
                  ],
                  staticClass: "btn btn-success",
                  attrs: { to: { name: "users.create" } }
                },
                [
                  _c("i", { staticClass: "fa fa-plus" }),
                  _vm._v(" Cadastrar Novo\n                    ")
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "portlet-body" },
          [
            _c("div", { staticClass: "form" }, [
              _c(
                "form",
                {
                  attrs: { action: "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.loadUsers($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("ui-text", {
                          attrs: { label: "Nome" },
                          model: {
                            value: _vm.search.name,
                            callback: function($$v) {
                              _vm.$set(_vm.search, "name", $$v)
                            },
                            expression: "search.name"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("ui-select", {
                          attrs: {
                            options: _vm.ui.options.status,
                            label: "Status"
                          },
                          model: {
                            value: _vm.search.status,
                            callback: function($$v) {
                              _vm.$set(_vm.search, "status", $$v)
                            },
                            expression: "search.status"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("ui-select", {
                          attrs: {
                            options: _vm.ui.options.departments,
                            label: "Departamento"
                          },
                          model: {
                            value: _vm.search.department_id,
                            callback: function($$v) {
                              _vm.$set(_vm.search, "department_id", $$v)
                            },
                            expression: "search.department_id"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          staticStyle: { "margin-top": "25px" },
                          attrs: { type: "button" },
                          on: { click: _vm.loadUsers }
                        },
                        [_vm._v("Buscar\n                                ")]
                      )
                    ])
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("ui-pagination", {
              attrs: { pagination: _vm.ui.pagination },
              on: { paginate: _vm.toPage }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "table",
                    {
                      staticClass: "table table-striped table-hover",
                      attrs: { id: "client-index" }
                    },
                    [
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.users, function(user) {
                          return _c("tr", [
                            _c("td", [_vm._v(_vm._s(user.id))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(user.name))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(user.statusInfo.name))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(user.email))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(user.department.name))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(user.occupation.name))]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "btn btn-default",
                                    attrs: {
                                      to: {
                                        name: "users.edit",
                                        params: { id: user.id }
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-pencil" })]
                                )
                              ],
                              1
                            )
                          ])
                        }),
                        0
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("ui-pagination", {
                  attrs: { pagination: _vm.ui.pagination },
                  on: { paginate: _vm.toPage }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "caption caption-md" }, [
      _c("i", { staticClass: "icon-bar-chart theme-font hide" }),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "caption-subject font-blue-madison bold uppercase" },
        [_c("i", { staticClass: "icon-user" }), _vm._v(" Usuários Ativos")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Nome")]),
        _vm._v(" "),
        _c("th", [_vm._v("Ativo")]),
        _vm._v(" "),
        _c("th", [_vm._v("Email")]),
        _vm._v(" "),
        _c("th", [_vm._v("Departamento")]),
        _vm._v(" "),
        _c("th", [_vm._v("Ocupação")]),
        _vm._v(" "),
        _c("th", [_vm._v("Ações")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }