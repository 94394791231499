var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "ui-modal",
        {
          attrs: { "is-loading": _vm.ui.loading },
          on: {
            close: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("h2", [_vm._v("Mudança de Plano")])
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c(
              "div",
              { ref: "blockElement" },
              [
                _c("ui-select", {
                  attrs: {
                    options: _vm.ui.plansOptions,
                    label: "Deseja mudar o plano atual para?"
                  },
                  model: {
                    value: _vm.plan_id,
                    callback: function($$v) {
                      _vm.plan_id = $$v
                    },
                    expression: "plan_id"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_vm._v("Fechar")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", id: "client_user-enviar" },
                on: { click: _vm.changePlan }
              },
              [_vm._v("Salvar")]
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }