<template>
    <div>
        <ul>
            <li v-for="item in fields">
                Para {{item.name}} use {{item.alias}}
            </li>
        </ul>
        <ui-text label="Assunto do E-mail" @input="triggerUpdate" v-model="subject"></ui-text>
        <ui-textarea
                label="Mensagem a ser enviada"
                v-model="message"
                @input="triggerUpdate"
        >

        </ui-textarea>
    </div>
</template>

<script>
    import field from '../fields'

    export default {

        name: "SendEmail",
        props: {
            trigger: {
                required: true,
            }
        },
        computed: {
            fields() {

                return field[this.trigger.origin.id]
            }
        },
        data(){
            return {
                subject: this.trigger.parameters.subject ? this.trigger.parameters.subject: null,
                message: this.trigger.parameters.message ? this.trigger.parameters.message: null
            }
        },
        methods: {
            triggerUpdate() {
                this.$emit('input', {
                    subject: this.subject,
                    message: this.message
                })
            }
        }
    }
</script>

<style scoped>

</style>