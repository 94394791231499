var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-md-12" },
    [
      _c("div", { staticClass: "title row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _vm._v("\n      " + _vm._s(_vm.name) + "\n      "),
          _vm.current.plan.can_move_from
            ? _c(
                "button",
                {
                  directives: [
                    {
                      name: "can",
                      rawName: "v-can",
                      value: "edit-client-plan",
                      expression: "'edit-client-plan'"
                    }
                  ],
                  staticClass: "btn blue",
                  on: { click: _vm.showMoveModal }
                },
                [_vm._v("Alterar Plano")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAdditionalProducts
            ? _c(
                "button",
                {
                  directives: [
                    {
                      name: "can",
                      rawName: "v-can",
                      value: "edit-client-plan",
                      expression: "'edit-client-plan'"
                    }
                  ],
                  staticClass: "btn blue",
                  on: { click: _vm.displayAdditionalModal }
                },
                [_vm._v("Adicionar Produto")]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "pull-right" }, [
          _c("small", [
            _vm._v(
              "Registrado em:" + _vm._s(_vm._f("date")(_vm.current.created_at))
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row", staticStyle: { "margin-top": "15px" } }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "ul",
            { staticClass: "nav nav-tabs" },
            _vm._l(_vm.current.clientProducts, function(item) {
              return _c("product-header", {
                key: item.id,
                attrs: {
                  "client-product": item,
                  active: _vm.ui.active === item.id
                },
                on: {
                  click: function($event) {
                    _vm.ui.active = item.id
                  }
                }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tab-content" },
            _vm._l(_vm.current.clientProducts, function(item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "tab-pane",
                  class: {
                    fade: _vm.ui.active !== item.id,
                    active: item.id === _vm.ui.active
                  }
                },
                [
                  _c(_vm.appropriateDetails(item), {
                    tag: "component",
                    attrs: {
                      options: _vm.options,
                      "client-plan": _vm.current,
                      "client-product": item
                    },
                    on: { update: _vm.updateItem }
                  })
                ],
                1
              )
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _vm._l(_vm.additionalGroups, function(item, index) {
        return _c("div", { key: index }, [
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.name))]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "list-group" },
            _vm._l(item.items, function(product, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(_vm.appropriateDetails(product), {
                    tag: "component",
                    attrs: { options: _vm.options, "client-product": product },
                    on: { remove: _vm.removeProduct }
                  })
                ],
                1
              )
            }),
            0
          )
        ])
      }),
      _vm._v(" "),
      _vm.ui.showAdditionalProductsModal
        ? _c(
            "div",
            [
              _c("ui-modal", [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _vm._v("Adicionar Produto Adicional")
                ]),
                _vm._v(" "),
                _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                  _c(
                    "div",
                    { ref: "additionalProdcutRef" },
                    [
                      _c("ui-select", {
                        attrs: {
                          options: _vm.current.plan.productTemplates,
                          label: "Produto Adicional"
                        },
                        model: {
                          value: _vm.additional,
                          callback: function($$v) {
                            _vm.additional = $$v
                          },
                          expression: "additional"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: { click: _vm.hideAdditionalModal }
                    },
                    [_vm._v("Fechar")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", id: "client_user-enviar" },
                      on: { click: _vm.addProduct }
                    },
                    [_vm._v("Salvar")]
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ui.showRemoveProductModal
        ? _c("ui-modal", [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _vm._v("Realmente deseja remover este produto?")
            ]),
            _vm._v(" "),
            _c("div", { attrs: { slot: "body" }, slot: "body" }, [
              _c("div", { ref: "removeProductRef" }, [
                _vm._v("Atenção!!! A exclusão do produto é permanente!")
              ])
            ]),
            _vm._v(" "),
            _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button" },
                  on: { click: _vm.hideRemoveProduct }
                },
                [_vm._v("Fechar")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.doRemoveProduct }
                },
                [_vm._v("Salvar")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("change-plan", {
        attrs: { "client-plan": _vm.current, show: _vm.ui.showMoveModal },
        on: {
          change: _vm.changed,
          close: function($event) {
            _vm.ui.showMoveModal = false
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }