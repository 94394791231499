import "./assets";
import "./components";
import "./directives";
import "./filters";
import "./mixins";

import Vue from "vue";

import Router from "./routes";
import store from "./store";
Vue.config.devtools = process.env.env !== "production";
new Vue({
  el: "#app",
  store,
  router: Router,
  template: `<router-view></router-view>`
});
