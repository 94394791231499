import Vue from 'vue'
const moment = require('moment')

Vue.filter('money', function (value) {
    const format = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
    });

    return format.format(value);
});