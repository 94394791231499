import store from '@/store';

export default {
  name: "can",
  inserted(el, binding) {
    if (
      store.getters.USER.permission.filter(i => i.slug === binding.value)
        .length === 0
    ) {
      el.remove();
    }
  }
};
