var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ui-loading", { attrs: { "is-loading": _vm.ui.loading } }, [
    _c("div", { staticClass: "portlet light" }, [
      _c("div", { staticClass: "portlet-title" }, [
        _c("div", { staticClass: "caption caption-md" }, [
          _c("i", { staticClass: "icon-bar-chart theme-font hide" }),
          _vm._v(" "),
          _vm.trigger.id
            ? _c(
                "span",
                { staticClass: "caption-subject theme-font bold uppercase" },
                [
                  _c("i", { staticClass: "icon-directions" }),
                  _vm._v("Atualizando o Trigger")
                ]
              )
            : _c(
                "span",
                { staticClass: "caption-subject theme-font bold uppercase" },
                [
                  _c("i", { staticClass: "icon-directions" }),
                  _vm._v("Cadastrando o Trigger")
                ]
              )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "portlet-body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "col-md-8" }, [
              _c(
                "form",
                { ref: "form" },
                [
                  _c("ui-text", {
                    attrs: { label: "Nome do Trigger", name: "name" },
                    model: {
                      value: _vm.trigger.name,
                      callback: function($$v) {
                        _vm.$set(_vm.trigger, "name", $$v)
                      },
                      expression: "trigger.name"
                    }
                  }),
                  _vm._v(" "),
                  _c("ui-select", {
                    attrs: {
                      options: _vm.events,
                      label: "Origem",
                      "full-value": true
                    },
                    model: {
                      value: _vm.trigger.origin,
                      callback: function($$v) {
                        _vm.$set(_vm.trigger, "origin", $$v)
                      },
                      expression: "trigger.origin"
                    }
                  }),
                  _vm._v(" "),
                  _c("conditions", {
                    attrs: {
                      conditions: _vm.trigger.conditions,
                      fields: _vm.fields
                    },
                    on: { change: _vm.updateConditions }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("ui-select", {
                        attrs: {
                          label: "Ação",
                          options: _vm.actions,
                          disabled: _vm.actions.length
                        },
                        model: {
                          value: _vm.trigger.action,
                          callback: function($$v) {
                            _vm.$set(_vm.trigger, "action", $$v)
                          },
                          expression: "trigger.action"
                        }
                      }),
                      _vm._v(" "),
                      _vm.trigger.action
                        ? _c(
                            "div",
                            [
                              _c(_vm.trigger.action, {
                                tag: "component",
                                attrs: { trigger: _vm.trigger },
                                on: {
                                  input: function(i) {
                                    return (_vm.trigger.parameters = i)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "pull-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.save }
                      },
                      [_vm._v("Salvar")]
                    )
                  ])
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _vm.trigger.origin
                  ? _c("div", [
                      _c("h1", [_vm._v(_vm._s(_vm.trigger.origin.name))]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.trigger.origin.description) +
                            "\n                            "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.trigger.conditions.length
                  ? _c("h4", [_vm._v("Condições")])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.trigger.conditions, function(condition) {
                  return _c(
                    "ul",
                    [
                      _c("show-condition", {
                        attrs: { trigger: _vm.trigger, condition: condition }
                      })
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _vm.trigger.action
                  ? _c("h4", [_vm._v("Irá " + _vm._s(_vm.displayAction))])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.trigger.parameters, function(item, key) {
                  return _c("ul", [
                    _c("li", [
                      _c("strong", [_vm._v(_vm._s(key))]),
                      _vm._v(" " + _vm._s(item))
                    ])
                  ])
                })
              ],
              2
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }