<template>
    <li :class="{'active' : active}">
        <a @click="$emit('click')">
          {{clientProduct.name}}
            <span class="label label-default " v-if="clientProduct.slug !== 'config-plan'"> 
                {{statusText}}
            </span>
        </a>

    </li>
</template>

<script>
    import mixin from './mixin';

    export default {
        name: "product-header",

        props: {
            active: {
                default: false
            }
        },

        mixins: [
            mixin
        ],

        computed: {

            statusButtonClass() {
                const val = this.getConfig('active', false)

                return {
                    'btn-danger': !val.value,
                    'blue': val.value
                }
            }
        }

    }
</script>

<style scoped>

</style>