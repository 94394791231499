<template>
    <div>
        <div class="portlet light" v-if="isLoading">
            <div class="portlet-body">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h1>Carregando...</h1>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <slot></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: "loading",
        props: {
            isLoading: {
                default: true,
                type: Boolean,
                twoWay: true
            }
        }
    }
</script>
