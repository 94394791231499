<template>
    <div>

        <div v-if="blockedDomain !== null">
            <div class="col-md-2">
                <span class="label label-default label-as-badge" style="font-weight:400;">
                    {{blockedDomain.mx}}
                </span>
            </div>
        </div>

        <div v-if="! domain.mx_zone && blockedDomain === null">
            <div class="col-md-6">
                <div class="">
                    <ui-select :label="' '" v-model="current.mx_zone" :options="options.entries"></ui-select>
                </div>
            </div>

            <div class="col-md-2">
                <div class="">
                    <label>&nbsp;</label>
                    <a href="/" class="btn btn-default add-mx-zone" @click.prevent="setMxZone">Adicionar
                        Zonas MX</a>
                </div>
            </div>
        </div>

        <div v-if="domain.mx_zone && blockedDomain === null">
            <div class="col-md-2">
                <span class="label label-primary label-as-badge" style="font-weight:400;">
                    {{domain.mx_zone}}
                </span>
            </div>
            <div class="col-md-2">
                <a class="label label-danger label-as-badge remove-zone" @click="removeMxZone">
                    <i class="fa fa-trash"></i>
                    Remover zona MX
                </a>
            </div>

            <ui-modal v-if="ui.showModal" @close="closeModal">
                <div slot="header">
                    Deseja remover as Zonas MX? Isso irá paralizar os e-mails para este dominio.
                </div>
                <div slot="body">
                    <div ref="loadingModal">
                        As zonas mx serão removidas imediatamente, contudo até a propagação pode
                        demorar algumas horas!
                    </div>

                </div>
                <div slot="footer">
                    <div>
                        <button type="button" class="btn btn-default"
                                @click="closeModal">Fechar
                        </button>
                        <button type="button" id="client_user-enviar" class="btn btn-danger"
                                @click="doRemoveMxZone"> <i class="fa fa-trash"></i>Remover
                        </button>
                    </div>
                </div>
            </ui-modal>
        </div>


    </div>
</template>

<script>
    import _ from 'lodash'

    export default {
        name: "SetMxZone",

        props: {
            options: {},
            clientProductId: {},
            domain: {},
            blocked: {}
        },
        data() {
            return {
                current: _.clone(this.domain),
                ui: {
                    showModal: false,
                    loading: false
                }
            }

        },
        computed: {
            blockedDomain() {
                return _.get(this.blocked, this.current.id, null);
            }
        },
        methods: {
            setMxZone() {

                this.request().post('Criando entradas mx', `client-products/${this.clientProductId}/domains/mx-entries`, {
                    domain_id: this.current.id,
                    mx_zone: this.current.mx_zone
                }).then(() => {
                    this.$emit('updated', this.current)
                })


            },
            removeMxZone() {
                this.ui.showModal = true
            },

            doRemoveMxZone() {
                this.request().del('Deletando entradas mx', `client-products/${this.clientProductId}/domains/mx-entries/${this.current.id}`)
                    .then(() => {
                        this.closeModal()
                        this.$emit('updated', this.current)
                    })
            },

            closeModal() {
                this.ui.showModal = false
            }
        },
        mounted() {
            this.current = _.clone(this.domain)
        }
    }
</script>

<style scoped>

</style>