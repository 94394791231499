var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-group-item col-md-12" }, [
    _c("div", { staticClass: "col-md-5" }, [
      _c("span", [_vm._v(_vm._s(_vm.name))])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-2" }, [
      _c("strong", [_vm._v("Status:")]),
      _vm._v(" " + _vm._s(_vm.installStatus) + "\n    ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-2" }, [
      _vm.clientProduct.created_at
        ? _c("span", [
            _vm._v(_vm._s(_vm._f("date")(_vm.clientProduct.created_at)))
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pull-right" }, [
      !_vm.isInstalled
        ? _c(
            "span",
            {
              staticClass: "btn blue",
              on: {
                click: function($event) {
                  return _vm.install(_vm.clientProduct)
                }
              }
            },
            [
              _c("i", {
                staticClass: "fa fa-cogs",
                attrs: { "data-v-575ec4e4": "" }
              }),
              _vm._v("\n            Instalar\n        ")
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn blue",
          attrs: {
            disabled: _vm.installValue > 0,
            target: "_blank",
            href: _vm.logLink
          }
        },
        [_c("i", { staticClass: "fa fa-download" }), _vm._v(" Baixar Log")]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: " btn btn-danger",
          on: {
            click: function($event) {
              return _vm.$emit("remove", _vm.clientProduct)
            }
          }
        },
        [
          _c("i", { staticClass: "fa fa-trash" }),
          _vm._v("\n            Remover\n        ")
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }