<template>
    <div class="row">
        <div class="col-md-12">
            <div class="portlet light">
                <div class="portlet-title tabbable-line">
                    <div class="caption caption-md">
                        <i class="icon-globe theme-font hide"></i>
                        <span class="caption-subject font-blue-madison bold uppercase"><i
                                class="icon-users"></i> Inclusão e edição de usuário</span>
                    </div>
                    <ul class="nav nav-tabs">
                        <li class="active">
                            <a href="#tab_1_1" data-toggle="tab">Dados usuário</a>
                        </li>
                        <li>
                            <a href="#tab_1_2" data-toggle="tab">Configuração</a>
                        </li>
                        <li>
                            <a href="#tab_1_3" data-toggle="tab" class="hide-tab">Permissões</a>
                        </li>
                    </ul>
                </div>


                <div class="portlet-body" ref="userForm">
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab_1_1">
                            <ui-loading :is-loading="ui.loading">

                                <div class="row">
                                    <div class="col-md-4">
                                        <ui-text
                                                label="Nome:"
                                                name="name"
                                                v-model="user.name"
                                        >

                                        </ui-text>
                                    </div>

                                    <div class="col-lg-2">
                                        <ui-text
                                                label="E-mail"
                                                name="email"
                                                v-model="user.email"
                                        >
                                        </ui-text>
                                    </div>
                                    <div class="col-lg-2">
                                        <ui-select
                                                label="Departamento"
                                                v-model="user.department_id"
                                                name="department_id"
                                                :options="ui.options.departments"
                                        >

                                        </ui-select>
                                    </div>
                                    <div class="col-lg-2">
                                        <ui-select
                                                label="Ocupação"
                                                :options="ui.options.occupations"
                                                v-model="user.occupation_id"
                                                name="occupation_id"
                                        >
                                        </ui-select>
                                    </div>
                                    <div class="col-lg-2">
                                        <ui-select
                                                :options="ui.options.status"
                                                v-model="user.status"
                                                label="Status"
                                                name="status"
                                        >
                                        </ui-select>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-2">
                                        <ui-text label="Nova Senha:"
                                                 type="password"
                                                 name="password"
                                                 v-model="user.password"></ui-text>
                                    </div>
                                    <div class="col-lg-2">
                                        <ui-text label="Nova Senha:" type="password"
                                                 name="password_confirmation"
                                                 v-model="user.password_confirmation"></ui-text>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="pull-right">
                                            <button class="btn blue"  v-can="'edit-user'" @click="saveUser">Salvar</button>
                                        </div>
                                    </div>
                                </div>

                            </ui-loading>
                        </div>
                        <div class="tab-pane" id="tab_1_2">

                            <div class="row">
                                <div class="col-md-6">
                                    <ui-select label="Dasahboard" :options="ui.options.dashboard" v-model="user.dashboard_id"></ui-select>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <div class="pull-right">
                                        <button class="btn blue" v-can="'edit-user-permission'" @click="saveUser">Salvar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab_1_3">
                            <div class="row">
                                <div class="col-md-6" v-for="group in ui.groups">
                                    <h3>{{group.name}}</h3>
                                    <hr/>

                                    <div v-for="permission in group.permissions">
                                        <ui-check :title="permission.description" :value="permission" index-key="id" :label="permission.name" :true-value="permission" v-model="user.permission"></ui-check>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="pull-right">
                                        <button class="btn blue" v-can="'edit-user-permission'" @click="saveUser">Salvar</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>


        </div>
    </div>
</template>

<script>
    export default {
        name: "Editor",

        data() {
            return {
                user: this.newUser(),
                ui: {
                    options: {
                        status: [],
                        occupations: []
                    },
                    groups: [],
                    loading: true
                }
            }
        },

        mounted() {
            this.loadOptions()
                .then(this.loadUser)
                .then(this.loadGroups)
                .then(this.ui.loading = false)
        },

        methods: {
            newUser() {
                return {
                    name: null,
                    permission: [],
                    department_id: null,
                    dashboard: null
                }
            },

            saveUser() {

                let request = this.request().block(this.$refs.userForm)

                if (this.user.id) {
                    request = request.put(`Atualizando o usuário`, `users/${this.user.id}`, this.user)
                } else {
                    request = request.post(`Salvando o usuário`, `users`, this.user)
                }

                request.then(response = this.user = response.data)
            },

            loadUser() {
                if (! this.$route.params.id) {
                    return this.newUser()
                }

                return this.request()
                    .get('Carregando usuário',`users/${this.$route.params.id}`)
                    .then(response => this.user = response.data)
            },

            loadGroups() {
                return this.request()
                    .get('Carregando grupos de permissao', `permissions/grouped`)
                    .then(response => this.ui.groups = response.data)
            },

            loadOptions() {
                return this.request().get('Carregando Opções', `users/options`)
                    .then(response => this.ui.options = response.data)
            }
        }
    }
</script>