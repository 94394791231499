const Vue = require('vue');
const Vuex = require('vuex');

Vue.use(Vuex)


export default new Vuex.Store({
    modules: {
        loading: require('./modules/loading'),
        events: require('./modules/events'),
        user: require('./modules/user')
    },

    strict: true,

})