<template>
    <div style="min-height: 400px">
        <div class="row">
            <div class="col-md-12">
                <div class="pull-right">
                    <button
                            @click="showCreateNotes()"
                            type="button"
                            class="btn btn-secondary">
                        Adicionar Nota
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <hr style="margin-bottom: 15px">
            </div>
        </div>

        <form class="form" action="">
            <p>FILTRAR POR:</p>
            <div class="row">
                <div class="col-md-2">
                    <ui-select placeholder="Departamento" v-model="ui.search.department_id"
                               :options="ui.options.departments"></ui-select>
                </div>
                <div class="col-md-2">
                    <ui-select placeholder="Usuário" v-model="ui.search.user_id"
                               :options="ui.options.users"></ui-select>
                </div>
                <div class="col-md-2">
                    <ui-select placeholder="Atividade" v-model="ui.search.activity_id"
                               :options="ui.options.activities"></ui-select>
                </div>
                <div class="col-md-2">
                    <ui-date placeholder="Data Inicial" v-model="ui.search.created_at_gt">
                        <option>Data Inicial</option>
                    </ui-date>
                </div>
                <div class="col-md-2">
                    <ui-date placeholder="Data Final" v-model="ui.search.created_at_lt">
                        <option>Data Final</option>
                    </ui-date>
                </div>
                <div class="col-md-1">
                    <button type="button" @click="loadNotes" class="btn btn-secondary">
                        Buscar
                    </button>
                </div>


            </div>
        </form>

        <div ref="notesList">
            <div v-if="notes.length > 0">
                <div class="col-md-12 well" v-for="item in notes" style="float: none">
                    <div class="pull-right">
                        <button v-if="item.can_edit" class="btn btn-default" @click.prevent="showEditNote(item)">
                            <i class="fa fa-pencil"></i>
                        </button>

                        <button v-if="item.can_delete" class="btn btn-danger" @click.prevent="showDeleteNote(item)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                    <div class="" href="#">
                        <h4 style="margin-bottom: 0px">
                            <strong>[ {{item.activity.name}} ] - {{item.title}} </strong>
                        </h4>

                        <small class="text">
                            <a @click.prevent="" href="void;"> <b>{{item.department.name}} </b> -
                                {{item.user.name}}</a>
                            -
                            <strong>Salvo:</strong> {{item.created_at | date}} -
                            <strong>Data de atividade:</strong> {{item.due_to | date}}
                            <span v-if="item.type"><strong>Tipo:</strong> {{item.type.name}}</span>
                        </small>
                        <br/>

                        <p style="margin-top: 20px" v-html="$options.filters.nl2br(item.description)"></p>
                    </div>
                </div>
            </div>

            <div class="row text-center" ref="" v-else>
                <h1>Nenhuma Atividade encontrada.</h1>
            </div>
        </div>
        <ui-modal v-if="ui.modelNoteShow" @close="hideNote">

            <div slot="header">
                <h1 v-if="isNew"> Editando a nota </h1>
                <h1 v-else> Adicionando nota </h1>
            </div>

            <div slot="body">
                <form action="" ref="notesForm">

                    <div class="row">

                        <div class="col-md-12">
                            <div class="form-group">
                                <div name="activity_id">
                                    <button
                                            type="button"
                                            class="btn"
                                            v-for="item in ui.options.activities"
                                            v-bind:class='{
                                                "btn-default": current.activity_id != item.id,
                                                "btn-primary": current.activity_id == item.id
                                            }'
                                            @click="current.activity_id = item.id"
                                    >
                                        {{item.name}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-md-4">
                            <ui-text label="Titulo" v-model="current.title" name="title"></ui-text>
                        </div>

                        <div class="col-md-3">
                            <ui-date type="datetime" format="dd/MM/yyyy HH:mm:ss" label="Data de Execução" name="due_to" v-model="current.due_to"></ui-date>
                        </div>

                        <div class="col-md-3" v-if="currentTypes">
                            <ui-select label=Tipo placeholder="Tipo da atividade" v-model="current.type_id"
                                       :options="currentTypes"></ui-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <ui-textarea label="Descrição" name="description"
                                         v-model="current.description"></ui-textarea>
                        </div>
                    </div>
                </form>
            </div>

            <div slot="footer">
                <button
                        @click="hideNote()"
                        type="button"
                        class="btn default">
                    Fechar
                </button>

                <button
                        @click="save()"
                        type="button"
                        class="btn btn-primary">
                    Salvar
                </button>

            </div>
        </ui-modal>
        <ui-modal  width="20%" v-if="ui.modalDeleteNoteShow" @close="hideDeleteNote">
            <div slot="body">
                Deseja realmente deletar esta nota?
            </div>
            <div slot="footer">
                <button class="btn btn-default" @click="hideDeleteNote">
                    Fechar
                </button>
                <button class="btn btn-danger" @click="deleteNote">
                    Delete
                </button>
            </div>
        </ui-modal>
    </div>
</template>

<script>
    import _ from 'lodash'

    export default {
        name: "Notes",

        props: {
            client: {}
        },

        data() {
            return {
                ui: {
                    modelNoteShow: false,
                    modalDeleteNoteShow: false,
                    options: {
                        activities: [],
                        departments: [],
                        users: []
                    },
                    search: {
                        department_id: null,
                        activity_id: null,
                        created_at_gt: null,
                        created_at_lt: null
                    }
                },
                notes: [],
                current: this.newNote()
            }
        },

        computed: {
            isNew() {
                return this.current.id !== null
            },
            currentTypes() {
                let activity = _.find(this.ui.options.activities, {'id' : this.current.activity_id});
                if (! activity) {
                    return null;
                }
                return activity.types
            }
        },

        mounted() {
            this.loadOptions()
                .then(this.loadNotes);
        },

        watch: {
            'current.type_id': function(newValue,oldValue) {
                if (oldValue === undefined) {
                    return;
                }

                if (newValue === oldValue) {
                    return
                }
                this.current.type = _.find(this.currentTypes, {id: parseInt(newValue)} )
            }
        },

        methods: {

            showDeleteNote(item) {
                this.current = item
                this.ui.modalDeleteNoteShow = true
            },

            hideDeleteNote() {
                this.current = null
                this.ui.modalDeleteNoteShow = false
            },

            deleteNote(item) {
                return this.request().del('Deletando nota', `clients/${this.client.id}/notes/${this.current.id}`)
                    .then(response => this.hideDeleteNote())
                    .then(this.loadNotes)
            },

            loadOptions() {
                return this.request().get('carregando opcoes', `clients/${this.client.id}/notes/options`).then(response => {
                    this.ui.options = response.data
                })
            },

            newNote() {
                return {
                    id: null,
                    title: '',
                    description: '',
                    due_to: Date.now(),
                    activity_id: null
                }
            },

            showCreateNotes() {
                this.current = this.newNote()
                this.ui.modelNoteShow = true;
            },


            hideNote() {
                this.current = null
                this.ui.modelNoteShow = false;
            },

            showEditNote(item) {
                this.current = _.cloneDeep(item);
                this.ui.modelNoteShow = true

            },

            loadNotes() {
                let query = {}

                _.forEach(this.ui.search, (value, key) => {
                    if (value ) {
                        query[key] = value
                    }
                })
                this.request().block(this.$refs.notesList)
                    .get('Carregando Notas', `clients/${this.client.id}/notes`, {
                        query: query,
                        order: 'created_at',
                        direction: 'desc'
                    })
                    .then(response => {
                        this.notes = response.data
                    })
            },


            save() {
                let request = this.request().block(this.$refs.notesForm)

                if (this.current.id) {
                    request = request.put('Atualizando nota', `clients/${this.client.id}/notes/${this.current.id}`, this.current)
                        .then(() => {
                            let noteId = _.findIndex(this.notes, {id: this.current.id});
                            this.$set(this.notes, noteId, this.current);
                        })
                        .then(response => this.hideNote())
                } else {
                    request = request.post('Salvando nota', `clients/${this.client.id}/notes`, this.current)
                        .then(response => {
                            this.notes.unshift(response.data)
                            this.hideNote()
                        })
                }
            }

        }
    }
</script>