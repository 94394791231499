<template>
  <div class="col-md-12">
    <div class="title row">
      <div class="col-md-6">
        {{name}}
        <button
          v-can="'edit-client-plan'"
          class="btn blue"
          v-if="current.plan.can_move_from"
          @click="showMoveModal"
        >Alterar Plano</button>
        <button
          v-can="'edit-client-plan'"
          class="btn blue"
          v-if="hasAdditionalProducts"
          @click="displayAdditionalModal"
        >Adicionar Produto</button>
      </div>

      <div class="pull-right">
        <small>Registrado em:{{current.created_at | date}}</small>
      </div>
    </div>

    <div class="row" style="margin-top: 15px">
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <product-header
            v-for="item in current.clientProducts"
            :key="item.id"
            :client-product="item"
            :active="ui.active === item.id"
            @click="ui.active = item.id"
          ></product-header>
        </ul>
        <div class="tab-content">
          <div
            v-for="item in current.clientProducts"
            :key="item.id"
            class="tab-pane"
            :class="{'fade': ui.active !== item.id, 'active' : item.id === ui.active}"
          >
            <component
              @update="updateItem"
              :options="options"
              :client-plan="current"
              :is="appropriateDetails(item)"
              :client-product="item"
            ></component>
          </div>
        </div>
      </div>
    </div>

    <div v-for="(item, index) in additionalGroups" :key="index">
      <div class="title">{{item.name}}</div>

      <div class="list-group">
        <div v-for="(product, index) in item.items" :key="index">
          <component
            @remove="removeProduct"
            :is="appropriateDetails(product)"
            :options="options"
            :client-product="product"
          ></component>
        </div>
      </div>
    </div>

    <div v-if="ui.showAdditionalProductsModal">
      <ui-modal>
        <div slot="header">Adicionar Produto Adicional</div>
        <div slot="body">
          <div ref="additionalProdcutRef">
            <ui-select
              :options="current.plan.productTemplates"
              v-model="additional"
              label="Produto Adicional"
            ></ui-select>
          </div>
        </div>
        <div slot="footer">
          <button type="button" class="btn btn-default" @click="hideAdditionalModal">Fechar</button>
          <button
            type="button"
            id="client_user-enviar"
            class="btn btn-primary"
            @click="addProduct"
          >Salvar</button>
        </div>
      </ui-modal>
    </div>

    <ui-modal v-if="ui.showRemoveProductModal">
      <div slot="header">Realmente deseja remover este produto?</div>
      <div slot="body">
        <div ref="removeProductRef">Atenção!!! A exclusão do produto é permanente!</div>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-default" @click="hideRemoveProduct">Fechar</button>
        <button type="button" class="btn btn-primary" @click="doRemoveProduct">Salvar</button>
      </div>
    </ui-modal>

    <change-plan
      @change="changed"
      @close="ui.showMoveModal = false"
      :client-plan="current"
      :show="ui.showMoveModal"
    ></change-plan>
  </div>
</template>

<style scoped>
.title {
  background-color: #cccccc59;
  line-height: 60px;
  margin-top: 20px;
  padding: 0 15px;
  font-size: 18px;
}

.title small {
  font-size: 12px;
}

.nav-tabs {
  margin-bottom: 0;
}

.nav-pills > li {
  background-color: #fff;
}

.tab-content {
  border: 1px solid #ccc;
  border-top: 0px solid;
}

.tab-content > .active {
  padding-bottom: 15px;
  padding-top: 15px;
}
</style>

<script>
import ChangePlan from "./Details/ChangePlan.vue";
import details from "./Details/index";
import Header from "./Details/Header.vue";
const _ = require("lodash");

export default {
  name: "product-details",

  props: {
    clientPlan: {
      default: {}
    },

    options: {
      default: {}
    }
  },

  data() {
    let clientPlan = _.cloneDeep(this.clientPlan);
    clientPlan.clientProducts.push({
      name: "Configurações do Plano",
      active: true,
      id: null,
      slug: "config-plan"
    });
    return {
      current: clientPlan,
      name: clientPlan.plan.name,
      ui: {
        active: clientPlan.clientProducts[0].id,
        showMoveModal: false,
        showAdditionalProductsModal: false,
        showRemoveProductModal: false
      },

      temp: {
        clientProduct: null
      },
      additional: null
    };
  },

  computed: {
    hasAdditionalProducts() {
      return this.current.plan.productTemplates.length > 0;
    },

    additionalGroups() {
      const items = {};

      _.map(this.current.additionals, item => {
        if (!items.hasOwnProperty(item.slug)) {
          items[item.slug] = {
            name: item.name,
            items: []
          };
        }

        items[item.slug].items.push(item);
      });
      return items;
    }
  },

  methods: {
    doRemoveProduct() {
      this.request()
        .block(this.$refs.removeProductRef)
        .del(
          "Removendo Produto adicional",
          `client-products/${this.temp.clientProduct.id}`
        )
        .then(() => {
          this.current.additionals = this.current.additionals.filter(
            i => i.id !== this.temp.clientProduct.id
          );
          this.hideRemoveProduct();
        });
    },

    removeProduct(clientProduct) {
      this.temp.clientProduct = clientProduct;
      this.showRemoveProduct();
    },

    showRemoveProduct() {
      this.ui.showRemoveProductModal = true;
    },
    hideRemoveProduct() {
      this.ui.showRemoveProductModal = false;
    },

    displayAdditionalModal() {
      this.ui.showAdditionalProductsModal = true;
    },

    hideAdditionalModal() {
      this.ui.showAdditionalProductsModal = false;
    },

    addProduct() {
      this.request()
        .block(this.$refs.additionalProdcutRef)
        .post(
          "Adicionando Produto",
          `/client-plans/${this.current.id}/additional`,
          {
            template_id: this.additional
          }
        )
        .then(response => {
          this.sendUpdate(response.data);
          this.hideAdditionalModal();
        });
    },

    showMoveModal() {
      this.ui.showMoveModal = true;
    },

    updateItem(item) {
      let key = this.current.clientProducts.findIndex(i => i.id === item.id);

      if (key < 0) {
        return;
      }

      this.$set(this.current.clientProducts, key, item);
      this.sendUpdate(this.current);
    },

    sendUpdate(value) {
      this.current = value;
      this.$emit("update", this.current);
    },

    changed(clientPlan) {
      this.current = clientPlan;
      this.name = clientPlan.plan.name;
      this.ui.showMoveModal = false;
    },

    appropriateDetails(product) {
      if (details.hasOwnProperty(product.slug)) {
        return product.slug;
      }
      return "placeholder";
    }
  },

  components: {
    ChangePlan,
    productHeader: Header,
    ...details
  }
};
</script>

<style scoped>
</style>