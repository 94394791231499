<template>
    <div class="row" ref="form">
        <div class="col-md-12" style="min-height: 140px">
            <div class="col-md-4">
                <p>
                    Status: <strong> {{statusText}} </strong>
                </p>
                <p>
                    Instalado: <strong>{{installStatus}} <i :class="installClass"></i></strong>
                </p>
                <p>
                    Data Ins: <strong v-if="isInstalled && getConfig('installed').updated_at ">{{getConfig('installed').updated_at | date}}</strong> <strong
                        v-else>-</strong>
                </p>

            </div>
            <div class="col-md-4">
                <p>
                    Limite de e-mails: <strong>{{clientProduct.managerConfigs['total_users'] + 1}}</strong>
                </p>
                <p>
                    Limite de usuários: <strong>{{clientProduct.managerConfigs['total_users']}}</strong>
                </p>
                <p>
                    Usuários em uso: <strong>{{clientProduct.managerConfigs['current_users']}}</strong>
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    Limite de imóveis: <strong>{{clientProduct.managerConfigs['total_properties']}}</strong>
                </p>
                <p>
                    Imóveis em uso: <strong>{{clientProduct.managerConfigs['current_properties']}}</strong>
                </p>
            </div>
        </div>
        <div class="col-md-12">
            <div class="pull-right">
                <div class="col-md-12">
                    <div class="form-actions">
                        <button @click="install"
                                class="btn blue"
                                type="button"
                                v-show="!isInstalled"
                        >
                            <i class="fa fa-cogs"></i>
                            Instalar
                        </button>
                        <a
                                target="_blank"
                                :href="clientProduct.log_url"
                                class="btn blue"
                        >
                            <i class="fa fa-download"></i>
                            Baixar Log
                        </a>
                        <button
                                @click="changeActiveStatus($refs.form)"
                                type="button"
                                class="btn"
                                :class="statusButtonClass"
                        >
                            <i :class="statusButtonIcon"></i>
                            {{statusButtonText}}</button>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import mixin from '../Details/mixin';
    
    export default {
        name: "sci-online",

        mixins: [
            mixin
        ],

    }
</script>

<style scoped>

</style>