var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ui-loading", { attrs: { "is-loading": _vm.ui.loading } }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "portlet light " }, [
          _c("div", { staticClass: "portlet-title" }, [
            _c("div", { staticClass: "caption caption-md" }, [
              _c("i", { staticClass: "icon-bar-chart theme-font hide" }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "caption-subject theme-font bold uppercase" },
                [_c("i", { staticClass: "icon-users" }), _vm._v(" Clientes")]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "actions btn-set" },
              [
                _c(
                  "router-link",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can",
                        value: "create-client",
                        expression: "'create-client'"
                      }
                    ],
                    staticClass: "btn btn-success",
                    attrs: { to: { name: "clients.create" } }
                  },
                  [
                    _c("i", { staticClass: "fa fa-plus" }),
                    _vm._v(" Cadastrar Novo\n                        ")
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "portlet-body" }, [
            _c(
              "form",
              {
                attrs: { action: "" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.doSearch($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-lg-6" },
                    [
                      _c("ui-select", {
                        attrs: {
                          options: _vm.ui.options.customers,
                          label: "Customer"
                        },
                        model: {
                          value: _vm.ui.search.customer_id,
                          callback: function($$v) {
                            _vm.$set(_vm.ui.search, "customer_id", $$v)
                          },
                          expression: "ui.search.customer_id"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-lg-3" },
                    [
                      _c("ui-select", {
                        attrs: {
                          options: _vm.ui.options.vendors,
                          label: "Vendedor"
                        },
                        model: {
                          value: _vm.ui.search.vendor_id,
                          callback: function($$v) {
                            _vm.$set(_vm.ui.search, "vendor_id", $$v)
                          },
                          expression: "ui.search.vendor_id"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c("ui-text", {
                        attrs: {
                          label: "Filtrar E-mail Login",
                          placeholder: "Filtre pelos e-mails usados no login"
                        },
                        model: {
                          value: _vm.ui.search.email_login,
                          callback: function($$v) {
                            _vm.$set(_vm.ui.search, "email_login", $$v)
                          },
                          expression: "ui.search.email_login"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-2" },
                    [
                      _c("ui-text", {
                        attrs: {
                          label: "Filtrar",
                          placeholder:
                            "Filtre por: Nome, Código, E-mail, Dominio."
                        },
                        model: {
                          value: _vm.ui.search.text,
                          callback: function($$v) {
                            _vm.$set(_vm.ui.search, "text", $$v)
                          },
                          expression: "ui.search.text"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-lg-2" },
                    [
                      _c("ui-select", {
                        attrs: {
                          options: _vm.ui.options.statusClassification,
                          label: "Clas. Status"
                        },
                        model: {
                          value: _vm.ui.search.status_classification,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.ui.search,
                              "status_classification",
                              $$v
                            )
                          },
                          expression: "ui.search.status_classification"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-2" },
                    [
                      _c("ui-text", {
                        attrs: { label: "Estado", placeholder: "Estado" },
                        model: {
                          value: _vm.ui.search.state,
                          callback: function($$v) {
                            _vm.$set(_vm.ui.search, "state", $$v)
                          },
                          expression: "ui.search.state"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-2" },
                    [
                      _c("ui-text", {
                        attrs: { label: "Cidade", placeholder: "Cidade" },
                        model: {
                          value: _vm.ui.search.city,
                          callback: function($$v) {
                            _vm.$set(_vm.ui.search, "city", $$v)
                          },
                          expression: "ui.search.city"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-2" },
                    [
                      _c("ui-text", {
                        attrs: { label: "Bairro", placeholder: "Bairro" },
                        model: {
                          value: _vm.ui.search.neighborhood,
                          callback: function($$v) {
                            _vm.$set(_vm.ui.search, "neighborhood", $$v)
                          },
                          expression: "ui.search.neighborhood"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-2" },
                    [
                      _c("ui-select", {
                        attrs: {
                          options: _vm.ui.options.arrears_states,
                          label: "Clientes Em"
                        },
                        model: {
                          value: _vm.ui.search.in_arrears,
                          callback: function($$v) {
                            _vm.$set(_vm.ui.search, "in_arrears", $$v)
                          },
                          expression: "ui.search.in_arrears"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-6" }, [
                    _c(
                      "div",
                      { staticClass: "btn-group" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default status_select",
                            class: { active: _vm.ui.search.status === null },
                            attrs: { type: "button" },
                            on: { click: _vm.clearStatus }
                          },
                          [
                            _vm._v(
                              "Todos\n                                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.ui.options.statusOptions, function(
                          item,
                          index
                        ) {
                          return _c(
                            "button",
                            {
                              key: index,
                              staticClass: "btn btn-default status_select",
                              class: {
                                active:
                                  _vm.ui.search.status[item.id] !== undefined
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.addStatus(item.id)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(item.name) +
                                  "\n                                    "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-md-12",
                      staticStyle: { "margin-top": "15px" }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "submit" }
                        },
                        [_vm._v("Buscar")]
                      )
                    ]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.print($event)
                  }
                }
              },
              [_c("i", { staticClass: "fa fa-file-excel-o" })]
            ),
            _vm._v(" "),
            _c(
              "div",
              { ref: "searchTable", staticClass: "row" },
              [
                _c("ui-pagination", {
                  attrs: { pagination: _vm.ui.pagination },
                  on: { paginate: _vm.toPage }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "table",
                      {
                        staticClass: "table table-striped table-hover",
                        attrs: { id: "client-index" }
                      },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v("Cliente")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Contatos")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Status")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Clas. Status")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Plano")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("CS")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Vendedor")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Cidade")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Dominio")])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.ui.items, function(item, index) {
                            return _c("tr", { key: index }, [
                              _c("td", [
                                _c(
                                  "div",
                                  { staticClass: "photo-person-list" },
                                  [
                                    _c("img", {
                                      staticClass: "img-circle",
                                      attrs: {
                                        alt: "",
                                        src: require("@assets/imgs/avatar-realestate.jpg")
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "colaborador-nome" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "clients.edit.index",
                                              params: { id: item.id }
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                                    " +
                                              _vm._s(item.name) +
                                              "\n                                                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "http://" +
                                              item.code +
                                              ".imob.online",
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-external-link"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "10px" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "label label-info" },
                                        [
                                          _vm._v(
                                            "\n                                                    " +
                                              _vm._s(item.code) +
                                              "\n                                                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      !item.in_debt
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "label label-success"
                                            },
                                            [_vm._v("Pagamento em Dia!")]
                                          )
                                        : _c("span", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "label label-danger past_due"
                                              },
                                              [_vm._v("Em Débito!")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "label label-danger past_due"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.days_past_due) +
                                                    "d"
                                                )
                                              ]
                                            )
                                          ]),
                                      _vm._v(" "),
                                      item.non_payment_rate &&
                                      item.non_payment_rate > 0
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "label",
                                              class: {
                                                "label-danger":
                                                  item.non_payment_rate > 50,
                                                "label-warning":
                                                  item.non_payment_rate > 30 &&
                                                  item.non_payment_rate <= 50,
                                                "label-success":
                                                  item.non_payment_rate <= 30
                                              },
                                              attrs: { title: "Atrasos" }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-thumbs-down",
                                                staticStyle: {
                                                  color: "inherit"
                                                }
                                              }),
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    item.non_payment_rate
                                                  ) +
                                                  "%\n                                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        "\n                                                 \n                                                "
                                      ),
                                      item.sci !== null &&
                                      item.sci.total_usage > 0
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "label label-info",
                                              attrs: { title: "Uso do plano" }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: " fa fa-retweet"
                                              }),
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    Math.floor(
                                                      item.sci.total_usage
                                                    )
                                                  ) +
                                                  "%\n                                                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                item.email
                                  ? _c("span", [
                                      _c("i", {
                                        staticClass: "fa fa-envelope-o"
                                      }),
                                      _vm._v(
                                        _vm._s(item.email) +
                                          "\n                                        "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                item.phone1
                                  ? _c("span", [
                                      _c("i", { staticClass: "fa fa-phone" }),
                                      _vm._v(
                                        "  " +
                                          _vm._s(item.phone1) +
                                          "\n                                            "
                                      ),
                                      _c("br")
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                item.phone2
                                  ? _c("span", [
                                      _c("i", { staticClass: "fa fa-phone" }),
                                      _vm._v(
                                        _vm._s(item.phone2) +
                                          "\n                                            "
                                      ),
                                      _c("br")
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(item.statusInfo.name) +
                                    "\n                                    "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                item.statusClassificationInfo
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          item.statusClassificationInfo.name
                                        )
                                      )
                                    ])
                                  : _c("span")
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                item.plan !== null
                                  ? _c("span", [_vm._v(_vm._s(item.plan.name))])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                !item.customer
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                                            Sem CS\n                                        "
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(item.customer.name) +
                                          "\n                                        "
                                      )
                                    ])
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                !item.customer
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                                            Sem Vendedor\n                                        "
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(item.vendor.name) +
                                          "\n                                        "
                                      )
                                    ])
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(item.uf) +
                                    " - " +
                                    _vm._s(item.city) +
                                    " "
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(item.neighborhood) +
                                    "\n                                    "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                item.domain
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          target: "_blank",
                                          href: item.domain
                                        }
                                      },
                                      [_vm._v(_vm._s(item.domain))]
                                    )
                                  : _c("span", [_vm._v(" Sem Dominio!")])
                              ])
                            ])
                          }),
                          0
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("ui-pagination", {
                      attrs: { pagination: _vm.ui.pagination },
                      on: { paginate: _vm.toPage }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }