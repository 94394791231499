import Editor from './component/Editor.vue';
import List from './component/List.vue';
import Trigger from './Trigger.vue';

export default {
  path: "/triggers",
  component: Trigger,

  children: [
    {
      path: "/",
      component: List,
      name: "triggers.index"
    },

    {
      path: "create",
      component: Editor,
      name: "triggers.create"
    },
    {
      path: ":id/edit",
      component: Editor,
      name: "triggers.edit"
    }
  ]
};
