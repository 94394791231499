<template>
    <div>
        <table ref="table" class="table table-striped table-hover">
            <thead>
            <tr>
                <th>Id</th>
                <th>Solicitante</th>
                <th>Analista</th>
                <th>Tipo</th>
                <th>Status</th>
                <th>Prioridade</th>
                <th>Título</th>
                <th>Data de criação</th>
                <th>SLA - 1º Resposta</th>
                <th>SLA - Resolução</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="ticket in tickets">
                <td>{{ticket.id}}</td>
                <td>{{ticket.requester.name}}</td>
                <td>
                    {{ticket.agent.name}}
                </td>
                <td>
                    <span v-if="ticket.type">
                        {{ticket.type}}
                    </span>

                    <span v-else>
                        Tipo não definido
                    </span>
                </td>
                <td>{{ticket.statusInfo.name}}</td>
                <td>
                    {{ticket.priorityInfo.name}}
                </td>
                <td>{{ticket.subject}}</td>
                <td>
                    {{ticket.created_at | date}}
                </td>
                <td>

                    {{ticket.first_response_due | date}}
                    <i class="fa" :class="classesStatus(ticket)" :title="responseStatus(ticket)"></i>
                </td>
                <td>{{ticket.due_by | date}}</td>
                <th><a target="_blank" :href="ticket.freshdesk_details"><i class="fa fa-external-link"></i> Detalhes
                </a></th>
            </tr>
            </tbody>

        </table>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        name: "Tickers",

        props: {
            client: {
                required: true
            }
        },

        data() {
            return {
                tickets: []
            }
        },

        mounted() {
            this.request().block(this.$refs.table).get('Carregando Tickets', `clients/${this.client.id}/tickets`)
                .then(response => {
                    this.tickets = response.data
                })
        },

        methods: {
            responseStatus(ticket) {
                if (ticket.first_responded_at) {
                    return 'Respondido'
                }

                let diff = this.timeFirstResponseTodue(ticket)

                switch (true) {
                    case diff > 24 * 60:
                        return {}
                    case diff < 30 && diff > 0:
                        return 'Proximo do Vencimento'
                    case diff < 0:
                        return 'Venceu'
                }
            },

            classesStatus(ticket) {
                if (ticket.first_responded_at) {
                    return {'fa-check text-success': true}
                }

                let diff = this.timeFirstResponseTodue(ticket)

                switch (true) {
                    case diff > 24 * 60:
                        return {}
                    case diff < 30 && diff > 0:
                        return {'fa-warning text-warning': true}
                    case diff < 0:
                        return {'fa-warning text-danger': true}
                }
            },

            timeFirstResponseTodue(ticket) {
                let date = moment(ticket.first_response_due.date)
                let now = moment()

                return date.diff(now, 'minutes')
            }
        }
    }
</script>

<style scoped>

</style>