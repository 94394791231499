var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c(
        "form",
        {
          ref: "recovery_form",
          staticClass: "login-form",
          attrs: { method: "POST" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.recovery($event)
            }
          }
        },
        [
          _vm._m(1),
          _vm._v(" "),
          _vm.ui.message.text
            ? _c(
                "div",
                {
                  staticClass: "alert display-hide",
                  class: {
                    "alert-danger": _vm.ui.message.type == "fail",
                    "alert-success": _vm.ui.message.type == "success"
                  },
                  staticStyle: { display: "block" }
                },
                [
                  _c("button", {
                    staticClass: "close",
                    attrs: { "data-close": "alert" }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.ui.message.text))])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("ui-text", {
            attrs: { type: "password", name: "password", placeholder: "Senha" },
            model: {
              value: _vm.login.password,
              callback: function($$v) {
                _vm.$set(_vm.login, "password", $$v)
              },
              expression: "login.password"
            }
          }),
          _vm._v(" "),
          _c("ui-text", {
            attrs: {
              type: "password",
              name: "password_confirmation",
              placeholder: "Senha"
            },
            model: {
              value: _vm.login.password_confirmation,
              callback: function($$v) {
                _vm.$set(_vm.login, "password_confirmation", $$v)
              },
              expression: "login.password_confirmation"
            }
          }),
          _vm._v(" "),
          _vm._m(2)
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("a", { attrs: { href: "index.html" } }, [
        _c("img", { attrs: { src: "/assets/imgs/login-logo.png", alt: "" } })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-title" }, [
      _c("span", { staticClass: "form-title" }, [_vm._v("Alterando a senha.")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-actions" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary btn-block uppercase",
          attrs: { type: "submit" }
        },
        [_vm._v("Alterar Senha")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }