var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _c("label", { attrs: { for: _vm.id } }, [_vm._v(_vm._s(_vm.label))]),
    _vm._v(" "),
    _c("textarea", {
      staticClass: "form-control",
      attrs: {
        id: _vm.id,
        name: _vm.name,
        type: "text",
        placeholder: _vm.placeholder,
        required: _vm.required,
        rows: _vm.rows,
        disabled: _vm.disabled
      },
      domProps: { value: _vm.value },
      on: {
        keyup: function($event) {
          return _vm.updateValue($event.target.value)
        }
      }
    }),
    _vm._v(" "),
    _vm.hint
      ? _c("small", { staticClass: "form-text text-muted" }, [
          _vm._v(_vm._s(_vm.hint))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }