<template>
    <div>
        <ul>
            <li v-for="item in fields">
                Para {{item.name}} use {{item.alias}}
            </li>
        </ul>

        <ui-select
                :options="typesWarnings"
                v-model="trigger.parameters.type"
                label="Tipo do da Mensagem"
        >

        </ui-select>
        <ui-check label="Alerta deverá ser global?" v-model="trigger.parameters.alert_global" :value="trigger.parameters.alert_global"></ui-check>
        <ui-textarea
                label="Mensagem a ser enviada"
                v-model="trigger.parameters.message"
                @input="v => $emit('input', {message : v}) "
        >

        </ui-textarea>


    </div>
</template>

<script>

    import field from '../fields'

    export default {

        name: "WarnSci",
        props: {
            trigger: {
                required: true,
                parameters: {
                    alert_global: false,
                    type: "",
                    message: "",
                }
            }
        },
        data: () => {
            return {
                typesWarnings:[
                    {
                        id: 'critical',
                        name: 'Critico'
                    },
                    {
                        id: 'warning',
                        name: 'Alerta'
                    },
                    {
                        id: 'info',
                        name: 'Informação'
                    }
                ]
            }
        },
        computed: {
            fields() {

                return field[this.trigger.origin.id]
            }
        }
    }
</script>

<style scoped>

</style>