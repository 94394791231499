<template>
    <div>
        <h4>O cliente será Suspenso, e todos seus produtos irão parar de funcionar.</h4>
        <h1>!!!As configurações de dominio apontamentos de DNS serão excluidos!!!</h1>
    </div>

</template>

<script>
    export default {
        name: "CancelClient"
    }
</script>

<style scoped>

</style>