var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "col-md-4" },
      [
        _c("ui-date", {
          attrs: {
            disabled: _vm.condition.today,
            label: "Data de cancelamento"
          },
          model: {
            value: _vm.condition.cancel_date,
            callback: function($$v) {
              _vm.$set(_vm.condition, "cancel_date", $$v)
            },
            expression: "condition.cancel_date"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-md-2" },
      [
        _c("ui-check", {
          staticStyle: { "margin-top": "25px" },
          attrs: { value: _vm.condition.today, label: "No dia." },
          model: {
            value: _vm.condition.today,
            callback: function($$v) {
              _vm.$set(_vm.condition, "today", $$v)
            },
            expression: "condition.today"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-1 form-group" }, [
      _c("span", { staticClass: "control-label" }, [_vm._v(" ")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-danger",
          staticStyle: { "margin-top": "22px" },
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("remove")
            }
          }
        },
        [_c("i", { staticClass: "fa fa-trash" })]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }