import Vue from 'vue'
import Loading from './Loading.vue';
import Modal from './Modal.vue';
import Pagination from './Pagination.vue';
import UiText from './fields/Text.vue';
import UiTextArea from './fields/TextArea.vue';
import UiRadio from './fields/Radio.vue';
import UiSelect from './fields/Select.vue';
import UiDate from './fields/Date.vue';
import UiMoney from './fields/Money.vue';
import UiCheck from './fields/Check.vue';
import Events from './Events.vue';

Vue.component('ui-loading', Loading)
Vue.component('ui-modal', Modal)
Vue.component('ui-pagination', Pagination)

Vue.component('ui-text', UiText)
Vue.component('ui-textarea', UiTextArea)
Vue.component('ui-radio', UiRadio)
Vue.component('ui-select', UiSelect)
Vue.component('ui-date', UiDate)
Vue.component('ui-money', UiMoney)
Vue.component('ui-check', UiCheck)


Vue.component('event', Events)
