var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { class: { active: _vm.active } }, [
    _c(
      "a",
      {
        on: {
          click: function($event) {
            return _vm.$emit("click")
          }
        }
      },
      [
        _vm._v("\n      " + _vm._s(_vm.clientProduct.name) + "\n        "),
        _vm.clientProduct.slug !== "config-plan"
          ? _c("span", { staticClass: "label label-default " }, [
              _vm._v(" \n            " + _vm._s(_vm.statusText) + "\n        ")
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }