<template>
    <div>

        <div class="col-md-12">
            <div class="pull-left">
                <span style="line-height: 46px">
                    Exibindo {{start}} Até {{end}} (Total: {{pagination.total}})
                </span>
            </div>
            <div class="pull-right">

                <ul class="pagination">
                    <li class="page-item" v-if="pagination.current_page > 1">
                        <a class="page-link"
                           @click.prevent='goTo(pagination.current_page - 1)'
                           href="#">Anterior</a>
                    </li>
                    <li v-for="(i, k) in displayedPages" :key="k" :class="{
                            'active': pagination.current_page == i
                          }">
                        <a class="page-link" href="#" @click.prevent='goTo(i)'>
                            {{i}}
                        </a>
                    </li>
                    <li class="page-item" v-if="pagination.current_page < pagination.total_pages">
                        <a
                                class="page-link" @click.prevent='goTo(pagination.current_page + 1)'
                                href="#">Proxima</a>
                    </li>
                </ul>

            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "pagination",
        props: {
            pagination: {
                object: () => {
                    return {
                        total: 0,
                        per_page: 0,
                        current_page: 0,
                        total_pages: 0
                    }
                }
            }
        },

        computed: {
            start() {
                let current =  this.pagination.per_page * ( this.pagination.current_page - 1 )

                if (current === 0) {
                    return 1
                }

                return current
            },

            end() {
                let value = this.start + parseInt(this.pagination.per_page) - 1

                if (value > this.pagination.total) {
                    return this.pagination.total
                }

                return value
            },
            displayedPages() {
                let index = this.pagination.current_page - 3,
                    items = []


                while (index <= this.pagination.current_page + 3) {

                    if (index >= 1 && index <= this.pagination.total_pages) {
                        items.push(index)
                    }

                    index += 1
                }

                return items
            }
        },

        methods: {
            goTo(page) {
                this.$emit('paginate', page)
            }
        }


    }
</script>
