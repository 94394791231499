<script>
    const md5 = require('blueimp-md5');
    import form from './_partials/Form.vue';
    import pusher from '@/lib/pusher'
    
    export default {
        name: "client-edit",

        data() {
            return {
                client: {
                    id: null
                },
                ui: {
                    options: {
                        statusOptions: [],
                        microsistecMail: [],
                        customers: [],
                        vendors: [],
                        paymentDays: [],
                        productOptions: {},
                        clientPlanOptions: {},
                        plans: {}
                    },
                    mounted:false,
                    require_note: false,
                    loading: true
                }
            }
        },

        components: {
            formClient: form
        },

        mounted() {
            const channel = pusher.subscribe(process.env.pusherChannel)
            channel.bind('installation_status', (event) => {
                let clientProduct = event.data
                if (this.client.plans === undefined) {
                    return
                }

                this.request().get('Obtendo informações Atualizadas', `/client-plans/`)

                this.client.plans.forEach((element, index) => {
                    let found = _.findIndex(element.clientProducts, i => i.id === clientProduct.id)

                    if (found >= 0) {
                        this.$set(this.client.plans[index].clientProducts, found, clientProduct);
                    }

                    found = _.findIndex(element.additionals, i => i.id === clientProduct.id)

                    if (found >= 0) {
                        this.$set(this.client.plans[index].additionals, found, clientProduct)
                    }
                })
            })

            this.getOptions()
                .then(this.getProductOptions)
                .then(this.getPlansOptions)
                .then(this.getClientPlanOptions)
                .then(this.getCustomers)
                .then(this.getVendors)
                .then(() => this.ui.mounted = true)
                .then(this.fetchClient)
        },
        beforeRouteUpdate(to, from, next){
            this.ui.loading = true;
            if (this.ui.mounted) {
                this.$route.params.id = to.params.id;
                this.fetchClient();
            }

            return next();

        },
        computed: {

            currentLabel() {
                return this.$route.meta.name
            },
            portalUrl() {
                return 'https://s3-us-west-2.amazonaws.com/publicador-xml/'+md5(this.client.code)+'/epungo.xml'
            }
        },

        methods: {
            fetchClient() {
                if (this.$route.params.hasOwnProperty('id') && this.$route.params.id) {
                    return this.getClient(this.$route.params.id).then(() => {
                        this.stopLoading()
                    })
                } else {
                    return this.stopLoading()
                }
            },
            getClientPlanOptions() {
                return this.request()
                    .get('Carregando informações dos Planos dos Clients', `/client-plans/options`)
                    .then(response => {
                        this.ui.options.clientPlanOptions = response.data
                    })
            },

            getPlansOptions() {
                return this.request()
                    .get('Carregando Informações dos Planos', `/plans/options`)
                    .then(response => {
                        this.ui.options.plans = response.data
                    })
            },

            getProductOptions() {
                return this.request()
                    .get('Carregando Informações dos Productos', `/client-products/options`)
                    .then(response => {
                        this.ui.options.productOptions = response.data
                    })
            },

            getOptions() {
                return this
                    .request()
                    .get("Carregando Opções", `/clients/options`)
                    .then(response => {
                        this.ui.options = response.data
                    })
            },

            getCustomers() {
                return this.request().get('Carregando Customers', `/users`, {
                    query: {
                        customers: true,
                    },
                    order: 'name'
                })
                    .then(response => this.ui.options.customers = response.data)
            },

            getVendors() {
                return this.request().get('Carregando Customers', `/users`, {
                    query: {
                        vendors: true,
                    },
                    order: 'name'
                })
                    .then(response => this.ui.options.vendors = response.data)
            },

            getClient(id) {
                return this.request().get('Carregando Cliente', `/clients/${id}`).then(response => this.client = response.data)
            },

            stopLoading() {
                this.ui.loading = false

            }
        }
    }
</script>

<template>
    <ui-loading :is-loading='ui.loading'>
        <div class="row">
            <div class='col-md-12'>
                <div v-if='client.id' class="profile-sidebar" style="width: 250px;">
                    <div class="portlet light profile-sidebar-portlet">
                        <div class="profile-userpic">
                            <img src="@assets/imgs/realestate-profile.png" class="img-responsive" alt="">
                        </div>
                        <div class="profile-usertitle">
                            <div class="profile-usertitle-name">{{ client.name}}</div>
                        </div>
                        <div class="profile-userbuttons">
                                <span class="label" :class="{
                                    'label-success' : client.status === 1,
                                    'label-warning' : client.status === 2,
                                    'label-danger'  : client.status === 3,
                                    'label-info'    : client.status === 4
                                }">{{ client.statusInfo.name }}</span>

                            <span class="label label-primary" v-if="client.id">
                                {{client.code}}

                            </span>

                            <span class="label label-primary" v-if="!client.id">
                                Não Definido
                            </span>

                            <span class="label  label-primary label-success"
                                  v-if="!client.in_debt">Pagamento em Dia!</span>
                            <span class="label label-danger past_due" v-else>Em Débito!</span>
                            <span v-if="client.days_past_due" class="label label-danger past_due">{{client.days_past_due}}d</span>
                        </div>
                        <div class="portlet light">
                            <div class="margin-top-10 profile-desc-link">
                                <span>Recorrência: R${{client.recurrency }}</span>

                            </div>
                            <div v-if="client.non_payment_rate" class="margin-top-10 profile-desc-link">
                                   <span title="Atrasos" v-if="client.non_payment_rate && client.non_payment_rate > 0" :class="{
                                    'label-danger': client.non_payment_rate > 50,
                                    'label-warning': client.non_payment_rate > 30 && client.non_payment_rate <= 50,
                                    'label-success': client.non_payment_rate <= 30
                                    }" class="label">
                                        <i class="fa fa-thumbs-down"  style="color: inherit"></i>
                                        {{client.non_payment_rate}}%
                                    </span>
                            </div>
                            <div class="margin-top-10 profile-desc-link">
                                <a :href="client.domain" target="_blank">{{client.domain}}</a>
                            </div>
                            <div v-for="user in client.users">
                                <div class="margin-top-10 profile-desc-link" v-if='user.phone' :title="user.phone">
                                    <i class="fa fa-phone"></i>
                                    {{user.phone }}
                                </div>
                            </div>
                            <div class="margin-top-10 profice-desc-link" v-if="client.sci !== null && client.sci.users_usage > 0">
                                <span>Usuários: {{client.sci.current_users}} / {{client.sci.total_users}} ({{Math.floor(client.sci.users_usage)}}%)</span>
                                <div class="progress">
                                    <div class="progress-bar" :style="{width: client.sci.users_usage + '%'}"></div>
                                </div>
                            </div>
                            <div class="margin-top-10 profice-desc-link" v-if="client.sci !== null && client.sci.properties_usage > 0">
                                <span>Imóveis: {{client.sci.current_properties}} / {{client.sci.total_properties}} ({{Math.floor(client.sci.properties_usage)}}%)</span>
                                <div class="progress">
                                    <div class="progress-bar" :style="{width: client.sci.properties_usage + '%'}"></div>
                                </div>
                            </div>
                            <div class="margin-top-10 profice-desc-link">
                                <a :href="portalUrl">Link Epungo <i class="fa fa-external-link"></i> </a>
                            </div>
                            <div class="margin-top-10 profice-desc-link">
                                <a :href="`http://${client.code}.imob.online`" target="_blank">
                                    {{client.code}}.imob.online <i class="fa fa-external-link"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="profile-content">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="portlet light">
                                <div class="portlet-title tabbable-line">
                                    <div class="caption caption-md">
                                        <i class="icon-globe theme-font hide"></i>
                                        <span class="caption-subject font-blue-madison bold uppercase"><i
                                                class="icon-users"></i> Informações da Imobiliária</span>
                                    </div>
                                    <ul class="nav nav-tabs">
                                        <li :class="{
                                                'active': currentLabel === 'index'
                                            }">
                                            <router-link
                                                    v-if="client.id"
                                                    :to="{ name: 'clients.edit.index' , params: {id : client.id } }"
                                                    tag="a"
                                            >
                                                Informações
                                            </router-link>

                                            <a href="" v-else>Informações</a>
                                        </li>

                                        <li v-if="client.id" :class="{
                                                'active': currentLabel === 'tickets'
                                            }">
                                            <router-link
                                                    v-if="client.id"
                                                    :to="{ name: 'clients.view.tickets' , params: {id : client.id } }"
                                                    tag="a"
                                            >
                                                Chamados
                                            </router-link>
                                        </li>

                                        <li v-if="client.id" :class="{
                                                'active': currentLabel === 'histories'
                                            }">
                                            <router-link
                                                    v-if="client.id"
                                                    :to="{ name: 'clients.view.histories' , params: {id : client.id } }"
                                                    tag="a"
                                            >
                                                Histórico
                                            </router-link>
                                        </li>

                                        <li v-if="client.id" :class="{
                                                'active': currentLabel === 'notes'
                                            }">
                                            <router-link
                                                    v-if="client.id"
                                                    :to="{ name: 'clients.edit.notes' , params: {id : client.id } }"
                                                    tag="a"
                                            >
                                                Atividades
                                            </router-link>
                                        </li>

                                        <li v-if="client.id" :class="{
                                                'active': currentLabel === 'users'
                                            }">
                                            <router-link
                                                    :to="{ name: 'clients.edit.users', params: {id :client.id} }"
                                                    tag="a"
                                            >
                                                Contatos e Usuários
                                            </router-link>
                                        </li>

                                        <li v-if="client.id" :class="{
                                                'active': currentLabel === 'plans'
                                            }">
                                            <router-link
                                                    v-can="'view-clients-plan'"
                                                    :to="{ name: 'clients.edit.plans', params: { id: client.id } }"
                                                    tag="a"
                                            >
                                                Planos e Produtos
                                            </router-link>
                                        </li>
                                        <li v-if="client.id" :class="{
                                                'active': currentLabel === 'installments'
                                            }">
                                            <router-link
                                                    :to="{ name: 'clients.edit.installments', params: { id: client.id } }"
                                                    tag="a"
                                            >
                                                Cobranças
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                                <div class="portlet-body">
                                    <div class="tab-content">
                                        <div class="tab-pane active" id="tab_1_1">
                                            <transition name="fade">
                                                <router-view :client="client" :options="ui.options"
                                                             @change="i => client = i"></router-view>
                                            </transition>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ui-loading>
</template>