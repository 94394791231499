var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ui-loading", { attrs: { "is-loading": _vm.ui.isLoading } }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.loadHistories($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "col-md-2" },
            [
              _c("ui-select", {
                attrs: { options: _vm.ui.users, label: "Usuário" },
                model: {
                  value: _vm.search.user_id,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "user_id", $$v)
                  },
                  expression: "search.user_id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-2" },
            [
              _c("ui-select", {
                attrs: { options: _vm.ui.options.types, label: "Tipo de ação" },
                model: {
                  value: _vm.search.type,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "type", $$v)
                  },
                  expression: "search.type"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-2" },
            [
              _c("ui-select", {
                attrs: { options: _vm.fields, label: "Campo Alt." },
                model: {
                  value: _vm.search.field,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "field", $$v)
                  },
                  expression: "search.field"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-2" },
            [
              _c("ui-date", {
                attrs: { label: "De" },
                model: {
                  value: _vm.search.created_at_gt,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "created_at_gt", $$v)
                  },
                  expression: "search.created_at_gt"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-2" },
            [
              _c("ui-date", {
                attrs: { label: "Até" },
                model: {
                  value: _vm.search.created_at_lt,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "created_at_lt", $$v)
                  },
                  expression: "search.created_at_lt"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-1" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-info",
                staticStyle: { "margin-top": "25px" },
                attrs: { type: "submit" }
              },
              [_vm._v("Pesquisar")]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12" }, [
        _c("table", { ref: "list", staticClass: "table table-striped" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v("Data da alteração")]),
              _vm._v(" "),
              _c("th", [_vm._v("Alterado Por")]),
              _vm._v(" "),
              _c("th", [_vm._v("Ação")]),
              _vm._v(" "),
              _c("th", [_vm._v("Alteração")])
            ])
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.histories, function(history) {
              return _c("tr", [
                _c("td", [
                  _vm._v(_vm._s(_vm._f("datetime")(history.created_at)))
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(history.user.name))]),
                _vm._v(" "),
                _c("td", [_c("p", [_vm._v(_vm._s(history.typeInfo.name))])]),
                _vm._v(" "),
                _c("td", [
                  _vm.countKeys(history) > 0
                    ? _c(
                        "ul",
                        {
                          staticClass: "list-group",
                          staticStyle: { "list-style": "none" }
                        },
                        _vm._l(_vm.filtered(history.delta), function(
                          item,
                          index
                        ) {
                          return _c("li", {}, [
                            _vm._v(
                              "Antigo valor de " +
                                _vm._s(_vm._f("trans")(index, "clients")) +
                                "\n                                era " +
                                _vm._s(_vm.format(index, history.delta)) +
                                "\n                            "
                            )
                          ])
                        }),
                        0
                      )
                    : _c("p", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(history.message) +
                            "\n                        "
                        )
                      ])
                ])
              ])
            }),
            0
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }