var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "can",
              rawName: "v-can",
              value: "add-user-to-client",
              expression: "'add-user-to-client'"
            }
          ],
          staticClass: "btn btn-primary",
          attrs: { type: "button", id: "branch_user-add" },
          on: {
            click: function($event) {
              return _vm.addUser()
            }
          }
        },
        [_vm._v("\n        Adicionar Usuário\n    ")]
      ),
      _vm._v(" "),
      _c("table", { staticClass: "table table-striped table-hover" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          { attrs: { id: "client_user-table" } },
          _vm._l(_vm.currentClient.users, function(item) {
            return _c("tr", { attrs: { id: "client_user" } }, [
              _c("td", [_vm._v(_vm._s(item.name))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(item.email))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(item.phone))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(item.contactInfo.name))]),
              _vm._v(" "),
              _c("td", { attrs: { width: "12%" } }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can",
                        value: "edit-user-client",
                        expression: "'edit-user-client'"
                      }
                    ],
                    staticClass: "btn blue btn-sm branch_user-edit",
                    on: {
                      click: function($event) {
                        return _vm.editUser(item)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-edit" })]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can",
                        value: "delete-user-client",
                        expression: "'delete-user-client'"
                      }
                    ],
                    staticClass: "btn red btn-sm",
                    on: {
                      click: function($event) {
                        return _vm.openDeleteModal(item)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-trash" })]
                )
              ])
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _vm.ui.deleteModalIsVisible
        ? _c("ui-modal", { on: { close: _vm.hideDeleteModal } }, [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _vm._v(
                "\n            Deseja realmente deletar este usuário?\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", { attrs: { slot: "body" }, slot: "body" }, [
              _c("p", { ref: "deleteModal" }, [
                _vm._v(
                  "\n                Deseja realmente deletar " +
                    _vm._s(_vm.ui.user.name) +
                    "?\n            "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button" },
                  on: { click: _vm.hideDeleteModal }
                },
                [_vm._v("Fechar")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  attrs: { type: "button" },
                  on: { click: _vm.deleteUser }
                },
                [_vm._v("Deletar")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.ui.showModalIsVisible
        ? _c("ui-modal", { on: { close: _vm.closeModal } }, [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _c("h4", { staticClass: "modal-title" }, [
                _vm._v("Cadastro de cliente")
              ])
            ]),
            _vm._v(" "),
            _c("div", { attrs: { slot: "body" }, slot: "body" }, [
              _c(
                "form",
                {
                  ref: "form",
                  attrs: { action: "#", role: "form", id: "client_user-form" }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-5" },
                      [
                        _c("ui-text", {
                          attrs: {
                            label: "Nome",
                            required: true,
                            name: "name",
                            placeholder: "Nome"
                          },
                          model: {
                            value: _vm.ui.user.name,
                            callback: function($$v) {
                              _vm.$set(_vm.ui.user, "name", $$v)
                            },
                            expression: "ui.user.name"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("ui-select", {
                          attrs: {
                            label: "Tipo do Contato",
                            options: _vm.ui.options.contactTypes,
                            name: "contact_type",
                            required: true
                          },
                          model: {
                            value: _vm.ui.user.contact_type,
                            callback: function($$v) {
                              _vm.$set(_vm.ui.user, "contact_type", $$v)
                            },
                            expression: "ui.user.contact_type"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-2" },
                      [
                        _c("ui-check", {
                          staticStyle: { "margin-top": "30px" },
                          attrs: { label: "Propretário" },
                          model: {
                            value: _vm.ui.user.owner,
                            callback: function($$v) {
                              _vm.$set(_vm.ui.user, "owner", $$v)
                            },
                            expression: "ui.user.owner"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-2" },
                      [
                        _c("ui-check", {
                          staticStyle: { "margin-top": "30px" },
                          attrs: { label: "Contato Ativo" },
                          model: {
                            value: _vm.ui.user.active,
                            callback: function($$v) {
                              _vm.$set(_vm.ui.user, "active", $$v)
                            },
                            expression: "ui.user.active"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-5" },
                      [
                        _c("ui-text", {
                          attrs: {
                            mask: "phone",
                            required: true,
                            label: "Telefone",
                            name: "phone",
                            icon: "fa fa-phone"
                          },
                          model: {
                            value: _vm.ui.user.phone,
                            callback: function($$v) {
                              _vm.$set(_vm.ui.user, "phone", $$v)
                            },
                            expression: "ui.user.phone"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("ui-date", {
                          attrs: { label: "Aniversário", required: true },
                          model: {
                            value: _vm.ui.user.birthday_date,
                            callback: function($$v) {
                              _vm.$set(_vm.ui.user, "birthday_date", $$v)
                            },
                            expression: "ui.user.birthday_date"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-5" },
                      [
                        _c("ui-text", {
                          attrs: {
                            label: "Email",
                            name: "email",
                            icon: "fa fa-envelope"
                          },
                          model: {
                            value: _vm.ui.user.email,
                            callback: function($$v) {
                              _vm.$set(_vm.ui.user, "email", $$v)
                            },
                            expression: "ui.user.email"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("ui-text", {
                          attrs: {
                            label: "Senha",
                            type: "password",
                            name: "password",
                            required: _vm.newUser
                          },
                          model: {
                            value: _vm.ui.user.password,
                            callback: function($$v) {
                              _vm.$set(_vm.ui.user, "password", $$v)
                            },
                            expression: "ui.user.password"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("ui-text", {
                          attrs: {
                            label: "Repita a senha",
                            name: "password_confirmation",
                            required: _vm.newUser
                          },
                          model: {
                            value: _vm.ui.user.password_confirmation,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.ui.user,
                                "password_confirmation",
                                $$v
                              )
                            },
                            expression: "ui.user.password_confirmation"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button" },
                  on: { click: _vm.closeModal }
                },
                [_vm._v("Fechar")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button", id: "client_user-enviar" },
                  on: { click: _vm.save }
                },
                [_vm._v("Salvar")]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Nome")]),
        _vm._v(" "),
        _c("th", [_vm._v("E-mail")]),
        _vm._v(" "),
        _c("th", [_vm._v("Telefone")]),
        _vm._v(" "),
        _c("th", [_vm._v("Tipo")]),
        _vm._v(" "),
        _c("th", [_vm._v("Ações")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }