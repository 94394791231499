<template>
    <h1>O cliente será Suspenso, e todos seus produtos irão parar de funcionar.</h1>
</template>

<script>
    export default {
        name: "SuspendClient"
    }
</script>

<style scoped>

</style>