var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "form", staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-12", staticStyle: { "min-height": "140px" } },
      [
        _c("div", { staticClass: "col-md-4" }, [
          _c("p", [
            _vm._v("\n                Status: "),
            _c("strong", [_vm._v(" " + _vm._s(_vm.statusText) + " ")])
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("\n                Instalado: "),
            _c("strong", [
              _vm._v(_vm._s(_vm.installStatus) + " "),
              _c("i", { class: _vm.installClass })
            ])
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("\n                Data Ins: "),
            _vm.isInstalled && _vm.getConfig("installed").updated_at
              ? _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm._f("date")(_vm.getConfig("installed").updated_at)
                    )
                  )
                ])
              : _c("strong", [_vm._v("-")])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-4" }, [
          _c("p", [
            _vm._v("\n                Limite de e-mails: "),
            _c("strong", [
              _vm._v(
                _vm._s(_vm.clientProduct.managerConfigs["total_users"] + 1)
              )
            ])
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("\n                Limite de usuários: "),
            _c("strong", [
              _vm._v(_vm._s(_vm.clientProduct.managerConfigs["total_users"]))
            ])
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("\n                Usuários em uso: "),
            _c("strong", [
              _vm._v(_vm._s(_vm.clientProduct.managerConfigs["current_users"]))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-4" }, [
          _c("p", [
            _vm._v("\n                Limite de imóveis: "),
            _c("strong", [
              _vm._v(
                _vm._s(_vm.clientProduct.managerConfigs["total_properties"])
              )
            ])
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("\n                Imóveis em uso: "),
            _c("strong", [
              _vm._v(
                _vm._s(_vm.clientProduct.managerConfigs["current_properties"])
              )
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "pull-right" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "form-actions" }, [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isInstalled,
                    expression: "!isInstalled"
                  }
                ],
                staticClass: "btn blue",
                attrs: { type: "button" },
                on: { click: _vm.install }
              },
              [
                _c("i", { staticClass: "fa fa-cogs" }),
                _vm._v(
                  "\n                        Instalar\n                    "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn blue",
                attrs: { target: "_blank", href: _vm.clientProduct.log_url }
              },
              [
                _c("i", { staticClass: "fa fa-download" }),
                _vm._v(
                  "\n                        Baixar Log\n                    "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn",
                class: _vm.statusButtonClass,
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.changeActiveStatus(_vm.$refs.form)
                  }
                }
              },
              [
                _c("i", { class: _vm.statusButtonIcon }),
                _vm._v(
                  "\n                        " + _vm._s(_vm.statusButtonText)
                )
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }