<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="title">
                    <h3>Dados Rapidos</h3>
                </div>
            </div>
            <div class="col-md-4">
                <p>
                    Quantidade de Clientes Ativos: {{customer.current.active_count}}
                    <i class="fa"
                       :class="{
                        'fa-arrow-circle-o-up text-success': customer.current.active_count > customer.previous.active_count,
                        'fa-arrow-circle-o-down text-danger': customer.current.active_count < customer.previous.active_count,
                        'fa-minus-circle text-info': customer.current.active_count === customer.previous.active_count
                       }" :title="'Mes passado '+ customer.previous.active_count "
                    ></i>
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    Quantidade de Clientes Suspensos: {{customer.current.suspended_count}}
                    <i class="fa" :class="{
                        'fa-arrow-circle-o-up text-danger': customer.current.suspended_count > customer.previous.suspended_count,
                        'fa-arrow-circle-o-down text-success': customer.current.suspended_count < customer.previous.suspended_count,
                        'fa-minus-circle text-info': customer.current.suspended_count === customer.previous.suspended_count
                       }" :title="'Mes passado '+ customer.previous.suspended_count "></i>
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    Quantidade de Clientes Cancelados: {{customer.current.canceled_count}}
                    <i class="fa" :class="{
                        'fa-arrow-circle-o-up text-danger': customer.current.canceled_count > customer.previous.canceled_count,
                        'fa-arrow-circle-o-down text-success': customer.current.canceled_count < customer.previous.canceled_count,
                        'fa-minus-circle text-info': customer.current.canceled_count === customer.previous.canceled_count
                       }" :title="'Mes passado '+ customer.previous.canceled_count "></i>
                </p>
            </div>

            <div class="col-md-4">
                <p>Valor Ativo {{customer.current.total_active_amount | money }}

                    <i class="fa" :class="{
                        'fa-arrow-circle-o-up text-success': customer.current.total_active_amount > customer.previous.total_active_amount,
                        'fa-arrow-circle-o-down text-danger': customer.current.total_active_amount < customer.previous.total_active_amount,
                        'fa-minus-circle text-info': customer.current.total_active_amount === customer.previous.total_active_amount
                       }" :title="'Mes passado '+ customer.previous.total_active_amount "></i>

                </p>
            </div>

            <div class="col-md-4">
                Valor Suspenso {{customer.current.total_suspended_amount | money }}

                <i class="fa" :class="{
                        'fa-arrow-circle-o-up text-danger': customer.current.total_suspended_amount > customer.previous.total_suspended_amount,
                        'fa-arrow-circle-o-down text-success': customer.current.total_suspended_amount < customer.previous.total_suspended_amount,
                        'fa-minus-circle text-info': customer.current.total_suspended_amount === customer.previous.total_suspended_amount
                       }" :title="'Mes passado '+ customer.previous.total_suspended_amount "></i>

            </div>

            <div class="col-md-4">
                Valor Cancelado {{customer.current.total_canceled_amount | money }}

                <i class="fa" :class="{
                        'fa-arrow-circle-o-up text-danger': customer.current.total_canceled_amount > customer.previous.total_canceled_amount,
                        'fa-arrow-circle-o-down text-success': customer.current.total_canceled_amount < customer.previous.total_canceled_amount,
                        'fa-minus-circle text-info': customer.current.total_canceled_amount === customer.previous.total_canceled_amount
                       }" :title="'Mes passado '+ customer.previous.total_canceled_amount "></i>

            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="title">
                    <h3>Proximos a Serem suspensos</h3>
                </div>
                <div>
                    <table class="table" ref="listTrials" style="border-top: none">
                        <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nome</th>
                            <th>Suspende em</th>
                            <th>Data</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, key) in clients.toDue" v-bind:key="key">
                            <td>{{item.code}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.days_to_due}}</td>
                            <td>{{item.due_to | date}}</td>
                            <td>
                                <router-link :to="{name: 'clients.edit.index', params: {id: item.id}}">
                                    Mais detalhes
                                </router-link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div> <div class="col-md-6">
                <div class="title">
                    <h3>Ultimos suspensos</h3>
                </div>
                <div>
                    <table class="table" ref="listTrials" style="border-top: none">
                        <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nome</th>
                            <th>Suspendido a</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, key) in clients.pastDue" v-bind:key="key">
                            <td>{{item.code}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.days_past_due}} dias</td>
                            <td>{{item.due_to | date}}</td>
                            <td>
                                <router-link :to="{name: 'clients.edit.index', params: {id: item.id}}">
                                    Mais detalhes
                                </router-link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>

</template>
<style scoped>
    .title {
        margin-top: 5px;
        border-bottom: 1px solid #365F42;
        margin-bottom: 10px
    }
</style>
<script>
    import {mapGetters} from 'vuex'
    import moment from 'moment'

    export default {
        name: "Customers",

        data() {
            return {
                clients: {
                    toDue: [],
                    pastDue: []
                },
                customer: {
                    previous: {
                        active_count: 0,
                        suspended_count: 0,
                        canceled_count: 0,
                    },
                    current: {
                        active_count: 0,
                        suspended_count: 0,
                        canceled_count: 0,
                    }

                }
            }
        },
        mounted() {
            this.toDue()
            this.pastDue()
            this.getCustomer()
        },

        computed: {
            ...mapGetters(['USER'])
        },

        methods : {
            getCustomer() {
                return this.request().get('Carregando logs do Customer', `/customers/${this.USER.id}`).then(response => {
                    this.customer = response.data
                })
            },
            toDue() {
                return this.request().get('Carregando Proximos Pagamentos', `/clients`, {
                    query: {
                        due_to_gt: moment().format('YYYY-MM-DD'),
                        customer_id: this.USER.id
                    },
                    order: 'due_to'
                }).then(response => {
                    this.clients.toDue = response.data
                })
            },
            pastDue() {
                return this.request().get('Carregando Proximos Pagamentos', `/clients`, {
                    query: {
                        due_to_lt: moment().format('YYYY-MM-DD'),
                        customer_id: this.USER.id
                    },
                    order: 'due_to',
                    direction: 'desc'
                }).then(response => {
                    this.clients.pastDue = response.data
                })
            }
        }
    }
</script>

<style scoped>

</style>